import * as React from 'react';
import { useState,useRef,useEffect } from 'react';

import { Col, Row, Timeline, Input, InputNumber,ConfigProvider } from 'antd';
import { Link } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';
import { useMediaQuery } from '@mui/material';
import { index } from 'd3';

const ACTIVATION_LEFT_PANEL = (props) => {
  
    const matches = useMediaQuery('(min-width:1024px)');
    const [isCompleted,setIsCompleted]=useState(props.statusData?.details && props.statusData?.kyc_completed && props.statusData?.bank && props.statusData?.address)
    const itemsSeller=[
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.details ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>1</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3 ||(props.statusData?.status==1 && props.statusData?.details) ?<Link to="/activation/company-details" style = {{ fontSize: '16px', color:props.index==0?'#9fe5f5':"#fff",fontWeight:"600",marginTop:"-8px" }}>Company Details</Link>:<span style = {{ fontSize: '16px', color:props.index==0?'#9fe5f5':"#fff",fontWeight:"600",marginTop:"-8px" }}>Company Details</span>}</p></div>,
      
        },
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.kyc_completed ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>2</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3 ||(props.statusData?.status==1 && props.statusData?.kyc_completed)?<Link to="/activation/kyc" style = {{ fontSize: '16px',fontWeight:"600", color:props.index==1?'#9fe5f5':"#fff",marginTop:"-8px" }}>KYC</Link>:<span style = {{ fontSize: '16px',fontWeight:"600",color:props.index==1?'#9fe5f5':"#fff",marginTop:"-8px" }}>KYC</span>}</p></div>,
      
        },
    
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.bank ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>3</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3 ||(props.statusData?.status==1 && props.statusData?.bank)?<Link to="/activation/bank-details" style = {{ fontSize: '16px',fontWeight:"600",color:props.index==2?'#9fe5f5':"#fff",marginTop:"-8px" }}>Bank Details</Link>:<span style = {{ fontSize: '16px',fontWeight:"600", color:props.index==2?'#9fe5f5':"#fff",marginTop:"-8px" }}>Bank Details</span>}</p></div>,
      
        },
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.address ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>4</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3||(props.statusData?.status==1 && props.statusData?.address) ?<Link to="/activation/address" style = {{ fontSize: '16px',fontWeight:"600", color:props.index==3?'#9fe5f5':"#fff",marginTop:"-8px" }}>Address Details</Link>:<span style = {{ fontSize: '16px',fontWeight:"600", color:props.index==3?'#9fe5f5':"#fff",marginTop:"-8px" }}>Address Details</span>}</p></div>,
      
        },
       
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.status==2 ||props.statusData?.status==4 ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>5</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3 ||(props.statusData?.status==1 && isCompleted)?<Link to="/activation/status" style = {{ fontSize: '16px',fontWeight:"600", color:props.index==4?'#9fe5f5':"#fff",marginTop:"-8px" }}>Submit for verification</Link>:<span style = {{ fontSize: '16px',fontWeight:"600", color:props.index==4?'#9fe5f5':"#fff",marginTop:"-8px" }}>Submit for Verification</span>}</p></div>,
      
        },
       
        
    ]

    const itemsBuyer=[
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.details ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>1</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3 ||(props.statusData?.status==1 && props.statusData?.details) ?<Link to="/activation/company-details" style = {{ fontSize: '16px', color:props.index==0?'#9fe5f5':"#fff",fontWeight:"600",marginTop:"-8px" }}>Company Details</Link>:<span style = {{ fontSize: '16px', color:props.index==0?'#9fe5f5':"#fff",fontWeight:"600",marginTop:"-8px" }}>Company Details</span>}</p></div>,
      
        },
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.kyc_completed ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>2</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3 ||(props.statusData?.status==1 && props.statusData?.kyc_completed)?<Link to="/activation/kyc" style = {{ fontSize: '16px',fontWeight:"600", color:props.index==1?'#9fe5f5':"#fff",marginTop:"-8px" }}>KYC</Link>:<span style = {{ fontSize: '16px',fontWeight:"600",color:props.index==1?'#9fe5f5':"#fff",marginTop:"-8px" }}>KYC</span>}</p></div>,
      
        },
    
        
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.address ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>3</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3||(props.statusData?.status==1 && props.statusData?.address) ?<Link to="/activation/address" style = {{ fontSize: '16px',fontWeight:"600", color:props.index==3?'#9fe5f5':"#fff",marginTop:"-8px" }}>Address Details</Link>:<span style = {{ fontSize: '16px',fontWeight:"600", color:props.index==3?'#9fe5f5':"#fff",marginTop:"-8px" }}>Address Details</span>}</p></div>,
      
        },
       
        {
           
            dot:<div style={{ fontSize: "24px",background:"#fff" }}>{props.statusData?.status==2 ||props.statusData?.status==4 ? <CheckCircleFilled style={{fontSize:"24px",padding: '2px 4px', }} size={'large'} /> : <p style={{boxSizing: 'border-box',fontSize:"18px",fontWeight:"600", padding: '2px 4px', margin: '2px 4px', borderRadius: '50%', background: '#FFFFFF', border: '1px solid #fff',}}>4</p> }
            </div>,
            color: '#173340',
            style: { color: '#CDCDCD', },
            children: <div ><p style={{fontSize:"16px",marginTop:'0px'}}>{props.statusData?.status==3 ||(props.statusData?.status==1 && isCompleted)?<Link to="/activation/status" style = {{ fontSize: '16px',fontWeight:"600", color:props.index==4?'#9fe5f5':"#fff",marginTop:"-8px" }}>Submit for verification</Link>:<span style = {{ fontSize: '16px',fontWeight:"600", color:props.index==4?'#9fe5f5':"#fff",marginTop:"-8px" }}>Submit for Verification</span>}</p></div>,
      
        },
       
        
    ]
    
    

    useEffect(()=>{
        console.log("props.statusData=",props,props.statusData,props.index);
        setIsCompleted(props.statusData?.details && props.statusData?.kyc_completed && props.statusData?.bank && props.statusData?.address)
    },[props])

    return(<>
    {props.statusData?
    <div style={{  display: 'flex',backgroundColor:"#336B7D",height:"100%", minHeight:props.index==1?"930px":"100%"}}>
    <div style={{  display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginLeft:"40px",marginTop:"20%" }} >
        <Timeline
        items={props.statusData && props.statusData.entity_type==2?itemsBuyer:itemsSeller}
    />
    </div>
    </div>
    :
    <></>
    }
    </>
   
    )

}

export default ACTIVATION_LEFT_PANEL