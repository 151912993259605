import {axiosPrivate} from '../api/axios';

import useAuth from './useAuth';
import { URL } from '../api/urls';
const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async (reload=false) => {
        try {
            let url=URL.REFRESH;

            const response = await axiosPrivate.post(url, {
                withCredentials: true,
                reload: reload
            });
            console.log("useRefreshToken response=",response);
            setAuth({accessToken:response.data.token,name:response.data.first_name+" "+response.data.last_name,role:response.data.role});
          
           
            return response.data.token;
        } catch (error) {
            console.error("error in useRefresh =",error);
            // if(error?.response?.status === 400){
            //     error.response.status=401;
            // }
            // return Promise.reject(error);
            
        } 
    }
    return refresh;
};

export default useRefreshToken;