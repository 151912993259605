import React from 'react'
import {Grid,Typography,Box} from '@mui/material'
import BackgroundImage from "../../../../assets/images/welcome-banner.png";
import BackgroundImageMobile from "../../../../assets/images/welcome-banner-mobile.png";
import logo from '../../../../assets/images/logo.png';

/**
 * 
 * @returns The left component for welcome layout to be used in all the screens of login.register flow
 */
const WelcomeLayout = () => {
  return (
   
        <Grid
                item
                mobile={false}
                tablet={4}
                laptop={5}
              
                sx={{
                  mt:4,
                  // width:{mobile:"100%"},
                  // backgroundImage: {laptop:`url(${BackgroundImage})`,tablet:`url(${BackgroundImage})`},
                  // backgroundRepeat: 'no-repeat',
                  // backgroundSize: 'cover',
                  // backgroundPosition: 'left',
                  justifyContent:"center",
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign:"center"
                
                }}
              >
      
                <Box                   
                  sx={{mt: 4,mx: 4,mr: 4,mb:4,
                    
                    display:{mobile:'none',tablet:'flex'},
                    flexDirection: 'column',
                    justifyContent:"center",
                    alignItems:'center',

                  }}>
                    <a href="https://www.gravitasfactor.com/" target="_blank"><img src={logo} alt="Gravitas Factor Logo" width={160} height={160}/></a>
                    <Typography variant="titleLarge" sx={{textAlign:'center'}} >Gravitas Factor</Typography>
                    {/* <Typography variant="titleMedium" sx={{textAlign:'center',mt:2}}>Submit your invoice now</Typography> */}
                </Box> 
                
                </Grid>
      
      );
}

export default WelcomeLayout
