import * as React from "react";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import { SmallDashOutlined } from "@ant-design/icons";

export const FieldWrapper = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);
  if (meta && meta.touched && meta.error) {
    otherProps.error = true;
    otherProps.helperText = meta.error;
  }

  const configText = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    required: true,
  };

  return <TextField size='small' {...configText} />;
};

export default FieldWrapper;
