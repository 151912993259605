import {fieldToTextField} from 'formik-mui';
import { TextField} from '@mui/material';

/**
 * List of custom fields used in formik-mui forms
 * @param {*} props 
 * @returns 
 */
export const MuiTextFieldFormik = (props) => {
    return (
      <TextField autoComplete="new-password" {...fieldToTextField(props)}/>
    )
}

