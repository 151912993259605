import * as React from 'react';
import { useState,useRef,useEffect } from 'react';

import { Col, Row, Timeline, Input, InputNumber,Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { Formik, Form, Field,ErrorMessage} from 'formik';
import {IconButton,Alert, Snackbar,Button,CircularProgress ,useTheme} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateInvoiceRequestForm } from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CheckCircleFilled ,UploadOutlined,InfoCircleOutlined} from '@ant-design/icons';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import { Add } from '@mui/icons-material';
import axios, { post } from 'axios';
import Files from 'react-files';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Modal from '@mui/material/Modal';
import { DeleteOutlined } from '@ant-design/icons';
import AmountField from '../../components/CustomTextField/AmountField';
import FieldWrapper from '../../components/CustomTextField/InputAmount';




// {
//     "invoice_due_date": "01/04/2023",
//     "date_of_invoice": "01/04/2023",
//     "supplier_name": "27AAFCI6413B1ZY",
//     "supplier_gst": "27AAFCI6413B1ZY",
//     "customer_name": "27AAFCI6413B1ZY",
//     "customer_gst": "27AAFCI6413B1ZY",
//     "invoice_number": "abc123",
//     "purchase_order": null,
//     "discount_type": 1,
//     "original_invoice_amount": 10000.0,
//     "max_discount_percentage": 10.0,
//     "invoice_amount_after_discount": 9000.0,
//     "file": <file_path>,
// }

const { Option } = Select;
let initialValues = {
"invoice_due_date": "",
"date_of_invoice": "",
// "supplier_name": "",
// "supplier_gst": "",
"customer_name": "",
"customer_gst": "",
"invoice_number": "",
// "purchase_order": "",
"discount_type": 1,
"original_invoice_amount": "",
"max_discount_percentage": 0,
"invoice_amount_after_discount": 0,
"max_discount_days":0,

}

const style = {
    position: 'absolute',
    top: '10%',
    left: '10%',
    height: "80%",
    //   transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius:4,
    overflow: 'scroll',
  };
  
const AddRequest = (props) => {
    const theme=useTheme();
    const [entityType, setEntityType] = useState(1);
    const [isLoading,setIsLoading]=useState(false);
    // const [form] = Form.useForm();
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelSave,responseDataSave,errorSave,loadedSave,resetSave,executeAPISave] = useAxiosWithAuth();
    const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
    const [cancelDiscounting,responseDiscounting,errorDiscounting,loadedDiscounting,resetDiscounting,executeAPIDiscounting] = useAxiosWithAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const formikRef = useRef();
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
   const { open, msg,severity } = msgState;
   const [rememberMe, setRememberMe] = useState(false);
   const [companyDetails,setCompanyDetails]=useState(initialValues);
   const [statusData,setStatusData]=useState();
   const [openModal, setOpenModal] = useState(true);
   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => {
     props.closeModal();
     setOpenModal(false)
   };
 

    const onEntityChange = (event) => {
        setEntityType(event.target.value);
        formikRef.current.setFieldValue("discount_type",event.target.value);
    }


    useEffect(()=>{
        executeAPI(URL.ACTIVATION_COMPANY_DETAILS,"GET",null);
        // executeAPIStatus(URL.ACTIVATION_DOC_CATEGORIES,"GET",null);
    },[])

    

    /**
     * API response handler  
    */  
    useEffect(() => {
        if(loaded){
        console.log("loaded=true",responseData);
       
        if(responseData!=null) {
        if (responseData?.entity_type){
            setCompanyDetails(responseData);
            // initialValues=JSON.stringify(responseData);
            // setEntityType(responseData.entity_type);
        }
            setIsLoading(false);  
            
        }
        else if(error!==null){
            console.log("Error data=",error);
        
        
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                navigate("/login", { replace: true });
            }
            setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});

        }
        reset();
        }
        },[loaded,responseData]);

   

   
 /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

  const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }
  const [files, setFiles] = useState([])
  const handleChange = (newFiles) => {
     setFiles(prevFiles => [...prevFiles, ...newFiles])
     
  }

  const handleFileRemove = (fileId) => {
     setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.id !== fileId))
  }

  const handleClearFiles = () => {
     setFiles([])
  }
  useEffect(()=>{
    if(files && files[0]){
        handleUploadFiles();
    }
  },[files])

  const handleUploadFiles = () => {
   
    
  }



   /**
    * API caller  
    */  
   const onSubmit = (values) => {

    if(files && files[0]){
        let data = new FormData()
        data.append(files[0].id, new Blob([files[0]], { type: files[0].type }), files[0].name || 'file')
        
        // values.invoice_amount_after_discount=Number((values.original_invoice_amount*(1-((values.max_discount_percentage/100)*values.max_discount_days/365))).toFixed(2));
        console.log("addRequest values=",JSON.stringify(values));
        values.file=files[0]
        values.is_discounting=rememberMe;
        console.log("addRequest=",JSON.stringify(values));
    
        
    //     return;
    //     // let jsonData={
    //     //     file:files[0],
            
    //     // }
    //     let jsonData={
        
    //     "invoice_due_date": "01/04/2023",
    //     "date_of_invoice": "01/04/2023",
    //     "supplier_name": "27AAFCI6413B1ZY",
    //     "supplier_gst": "27AAFCI6413B1ZY",
    //     "customer_name": "27AAFCI6413B1ZY",
    //     "customer_gst": "27AAFCI6413B1ZY",
    //     "invoice_number": "abc123",
    //     "purchase_order": null,
    //     "discount_type": 1,
    //     "original_invoice_amount": 10000.0,
    //     "max_discount_percentage": 10.0,
    //     "invoice_amount_after_discount": 9000.0,
    //     file:files[0],
    // }
    //     console.log("Jsondata for upload=",jsonData);
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization':'Token '+ JSON.parse(localStorage.getItem("token"))
            }
        }
    
        const url=`${process.env.REACT_APP_BASE_URL}${URL.INVOICE_DISCOUNTING}`
        axios.post(url, values,config).then(() => {
            formikRef?.current.setSubmitting(false);
            // setFiles([])
            executeAPIDiscounting(URL.INVOICE_DISCOUNTING,"GET",null);
            setMsgState({open:true,msg:"Invoice request is added successfully."
            
        ,severity:"success"});   
        setTimeout(()=>{
            props.closeModal(true);
        },1000);
       
            
         }).catch((err) => {
            // window.alert(`Error uploading files: ${err.message}`)
            formikRef?.current.setSubmitting(false);
            //if unauthorized then redirect it to login page
            if(err?.response?.status === 401 || err?.response?.status === 403){
                console.log("status received =",err?.response?.status)
                navigate("/login", { replace: true });
    }
            setMsgState({open:true,msg:err?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
         })
        }
  }

  const handleInvoiceAmount=(value)=>{
    // let value=event.target.value;
    // const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    // value= value.match(regex)[0];
    formikRef.current.setFieldValue("original_invoice_amount",value);

  }

  const handleMaxDiscount=(event)=>{
    let value=event.target.value;
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,4})/s;
    value= value.match(regex)[0];
    formikRef.current.setFieldValue("max_discount_percentage",value);

  }

  const handleMaxDiscountDays=(event)=>{
    let value=event.target.value;
    const regex = /([0-9]{0,3})/s;
    value= value.match(regex)[0];
    if(value>365)
    return;
    formikRef.current.setFieldValue("max_discount_days",value);

  }

    return (
        <div style={{ width: '100vw',display:"flex",justifyContent:"center",backgroundColor:"#fafafa" }} >
            {!isLoading?
            <>
            
            <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <CancelOutlinedIcon
            onClick={() => handleCloseModal()}
            sx={{
              ml: 0, mt: 0, top: '10%',
              left: '10%', height: 34, width: 36, cursor: 'pointer', position: 'fixed', zIndex: 9999
            }}
          />
               
                
               

                    {/* content for column 2 goes here */}
                    {/* <div style={{ height: `calc(100vh - 64px)`, width:"100vw",maxWidth:"900px" ,display: 'flex', direction: 'verticle', alignItems: 'flex-start', justifyContent: 'center',backgroundColor:"#fff" }}>

                        <div style={{ width: "calc(100% - 20px)",paddingLeft:"20px",paddingRight:"20px" }} > */}
                        <Box sx={style}>
                            <Box sx={{mt:2}}>
                                <Typography  variant="titleMedium" sx={{fontSize:"24px"}}>
                                    Add Invoice
                                </Typography>
                            </Box>
                        <Formik enableReinitialize={true} innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validate={validateInvoiceRequestForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form>
                 
          
                  {/* <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:2,mb:3}}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="supplier_name"
                        name="supplier_name"
                        type="text"
                        label="Supplier Name"
                        
                        />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="supplier_gst"
                        name="supplier_gst"
                        type="text"
                        label="Supplier GSTIN"
                        
                        />
                    </Grid>
                    
                   
                    
                </Grid>
                </Box> */}

                <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:2,mb:3}}>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                    
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="customer_name"
                        name="customer_name"
                        type="text"
                        label="Customer's Name"
                        
                        />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Name of the customer.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}} >
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                    
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="customer_gst"
                        name="customer_gst"
                        type="text"
                        label="Customer's GSTIN"
                        
                        />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>GSTIN of the customer.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                    </Grid>
                    
                   
                    
                </Grid>
                </Box>
                
                <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="invoice_number"
                        id="invoice_number"
                        type="text"
                        label="Invoice Number"
                        required
                       
                    />
                     <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Invoice Number of invoice being discounted. </div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="date_of_invoice"
                        name="date_of_invoice"
                        type="text"
                        label="Date of Invoice Issue"
                        placeholder="DD/MMM/YYYY"
                        required
                        
                        />
                          <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Original issuance date of the invoice. </div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                  </Grid> 
                   
              </Grid>
              </Box>

              <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                <Grid item mobile={12} tablet={6}>
                <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="invoice_due_date"
                        name="invoice_due_date"
                        type="text"
                        label="Invoice Due Date"
                        placeholder="DD/MMM/YYYY"
                        required
                        />
                          <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Date on which invoice is due.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                  </Grid> 
                  <Grid item mobile={12} tablet={6}>
                     <Box display="flex" >
                        <FormControl fullWidth size="small" required >
                        <InputLabel  sx={{backgroundColor: '#fff'}} id="demo-select-small-label">Discount Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                required
                                value={entityType}
                                label="Discount Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                onChange={(event) => onEntityChange(event)}>

                                <MenuItem value={1}>Factoring</MenuItem>
                                <MenuItem value={3}>Reverse Factoring</MenuItem>
                                <MenuItem value={4}>Invoice Discounting</MenuItem>
                                <MenuItem value={2}>Dynamic Discounting</MenuItem>
                               
                            </Select>
                            
                        </FormControl>
                        <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Type of discounting.</div>}
                         zIndex={99999}  overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                    
                    </Grid>
                  
                    
                   
              </Grid>
              </Box>

              <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                

                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        {/* <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        onChange={handleInvoiceAmount}
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="original_invoice_amount"
                        name="original_invoice_amount"
                        type="number"
                        label="Original Invoice Amount"
                        required
                        
                        /> */}
                        <AmountField 
                        name="dummy_original_invoice_amount" 
                        onValueChange={(values) => {
                            const {formattedValue, value, floatValue} = values;
                            // do something with floatValue
                            handleInvoiceAmount(floatValue)
                          }}
                        customInput={FieldWrapper} />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Value of the invoice being discounted.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}} >
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                  </Grid> 
                  {/* <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        onChange={handleMaxDiscount}
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="max_discount_percentage"
                        id="max_discount_percentage"
                        type="number"
                        label="Max Annual Discount %"
                        required
                        
                    />
                     <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Max Annual Percent at which you are will to discount the invoice.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}} >
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                    </Grid> */}
                     <Grid item mobile={12} tablet={6}>
                  <Box display="flex" sx={{ mb: 0 }} justifyContent="start" alignItems="center">
                <Field as={FormControlLabel}
                    name='remember_me'
                    size='small'
                    control={<Checkbox 
                      checked={rememberMe}
                      onChange={()=>setRememberMe(!rememberMe)}
                      sx={{
                        color: theme.palette.primaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.primaryTheme.shade03,
                        },
                      }}/>
                    }
                    label={<Typography variant="linkSupportText" >
                       Enable Discounting?
                      </Typography>
                    }
                />
                 <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Enable discounting for the invoice.</div>}
                         zIndex={99999}  overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                 </Box>   
                </Grid>
                   
              </Grid>
              </Box>

                
                {/* <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        onChange={handleMaxDiscountDays}
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="max_discount_days"
                        id="max_discount_days"
                        type="text"
                        label="No. of Discount Days"
                        required
                       
                    />
                      <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Number of days invoice is due for.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                    </Grid>
                     
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        value={values.max_discount_percentage!=""&&values.original_invoice_amount!="" && values.max_discount_days!=""?((values.original_invoice_amount*(1-((values.max_discount_percentage/100)*values.max_discount_days/365)))).toLocaleString(undefined,{  minimumFractionDigits: 2,maximumFractionDigits: 2}):0}
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="invoice_amount_after_discount"
                        name="invoice_amount_after_discount"
                        type="text"
                        label="Invoice Amount After Max Discount"
                        required
                        disabled
                        />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Total invoice amount you will be paid after discount.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}} >
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                       
                    </Box>
                  </Grid> 
                 

               
                    
                   
              </Grid>
              </Box> */}
             
              <Box display="flex" justifyContent="center" sx={{height:"110px",alignItems:'center',flexDirection:'column',mt:3,border:'1px dashed'}}>
                                <Files
                                    className='files-dropzone'
                                    onChange={handleChange}
                                    onError={handleError}
                                    accepts={['image/*', '.pdf']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    >
                                       
                                 <Tooltip zIndex={99999} title={"Upload your invoice"} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>    
                                    <UploadOutlined style = {{ fontSize:"40px",marginTop:"10px",cursor:'pointer' }}/>
                               </Tooltip>
                                   
                                </Files>
                                <Typography sx={{mt:1}}>Upload Invoice</Typography>
                                {files[0]?
                                <Box display='flex'>
                                    <Typography sx={{mt:0,fontSize:"12px"}}>{files[0]?files[0].name:""}</Typography>
                                    <Tooltip zIndex={99999} title={"Delete"} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>   
                                    <DeleteOutlined
                                        onClick={() => {
                                        handleClearFiles();
                                        }}
                                        style={{ color: "red", marginLeft: 12, cursor: "pointer" }}
                                    />
                                    </Tooltip>
                                </Box>:
                                 <></>}
                            </Box>
                            


                            <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
                        <Button type='submit'   sx={{mb:2, mt:6,height:"50px",width:"240px"}} variant="contained" disabled={isSubmitting ||(!files[0])}
                      >{isSubmitting ? "ADD & SUBMIT" : "ADD & SUBMIT"}</Button>
                      </Grid>
             

          </Form>
          )}
        </Formik>
       
                        
                        {/* </div> */}

                    {/* </div> */}
                    </Box>
                    </>
                    </Modal>
               
            <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>:
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/></div>}
        </div>
    )
}

export default AddRequest