import React, { useState,useEffect } from 'react'
import {Link, Grid ,Snackbar,Alert,Popover,CircularProgress} from "@mui/material"
import Typography from '@mui/material/Typography';
// import upiImg from '../../assets/images/Gravitas FactorChartsUPI.png';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/Add';
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { useAxios } from '../../common/api/hooks/useAxios';
import useAuth from '../../common/hooks/useAuth';
import axios from 'axios';
import useRefreshToken from '../../common/hooks/useRefreshToken';
import { useNavigate, useLocation } from 'react-router-dom';
import { SUBSCRIPTION_PLAN_LIST,SNACKBAR_AUTO_HIDE_DURATION_SHORT,TEXT_MSGS,PAYMENT_STATUS } from '../../common/utility/constant';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Popper from '@mui/material/Popper';

const cardSX={
  minWidth: 360,maxWidth:340,minHeight:614, display:"flex", flexDirection:'column',alignItems:'center',marginTop:"40px",pl:1,pr:1,backgroundColor:"#36455e",borderRadius:4 ,
  "&:hover": {
    // transition: "transform 0.15s ease-in-out",
    // transform: "scale3d(1.05, 1.05, 1)" 
  }
}

const cardSXPlan={
  minWidth: 360,maxWidth:340,minHeight:580, display:"flex", flexDirection:'column',alignItems:'center',marginTop:"40px",pl:1,pr:1,backgroundColor:"#36455e",borderRadius:4 ,
  "&:hover": {
    // transition: "transform 0.15s ease-in-out",
    // transform: "scale3d(1.05, 1.05, 1)" 
  }
}

const buttonSXLogin = {
 
    width:320,mb:1,height:52,fontSize:22,background:"#ffffff",backgroundColor:"#ffffff",color:"#000",
    "&:hover": {
      borderColor: "rgba(255,240,10,0.8)",
      backgroundColor:"#ffffff",
      background:"#ffffff",
      opacity:0.9,
     
    },
  };
const buttonSX = {
 
  width:320,mb:1,height:52,fontSize:22,background:"#23B1BA",backgroundColor:"#23B1BA",
  "&:hover": {
    borderColor: "rgba(255,240,10,0.8)",
    backgroundColor:"#23B1BA",
    background:"#23B1BA",
    opacity:0.9,
   
  },
};
const buttonSXSmall = {
 
  width:30,height:24,mb:1,fontSize:14,background:"#23B1BA",backgroundColor:"#23B1BA",
  "&:hover": {
    borderColor: "rgba(255,240,10,0.8)",
    backgroundColor:"#23B1BA",
    background:"#23B1BA",
    opacity:0.9
  },
};

const buttonSXPlus= {
 
  width:320,mb:1,height:52,fontSize:22,background:"#E7B401",backgroundColor:"#E7B401",
  "&:hover": {
    backgroundColor:"#E7B401",
    background:"#E7B401",
    opacity:0.9
  },
};

const buttonSXPlusSmall= {
 
  width:30,height:24,mb:1,fontSize:14,background:"#E7B401",backgroundColor:"#E7B401",
  "&:hover": {
    backgroundColor:"#E7B401",
    background:"#E7B401",
    opacity:0.9
  },
};

const Pricing = () => {
  const from = "/login";
  const navigate = useNavigate(); 
  const location = useLocation();
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  const {cancel,responseData,error,loaded,reset,executeAPI} = useAxios();
  
  const [subscriptionState,setSubscriptionState]=useState({
    isLoaded:false,
    isError:false,
    subsciptionData:undefined
  })

  const {isLoaded,isError, subsciptionData}=subscriptionState;

  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const { open, msg, severity } = msgState;


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPremium = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  
  const openPopperPremium = Boolean(anchorEl);
  const id = openPopperPremium ? 'simple-popper' : undefined;

  const [anchorElPlus, setAnchorElPlus] = React.useState(null);

  const handleClickPremiumPlus = (event) => {
    setAnchorElPlus(anchorElPlus ? null : event.currentTarget);
  };

  const openPopperPremiumPlus = Boolean(anchorElPlus);
  const idPlus = openPopperPremiumPlus ? 'simple-popper' : undefined;

  /**
   * autoclosing of the snackbar msg bar 
   */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setAnchorElPlus(null);
  };

    //load subscription plans
    useEffect(()=>{
    //   executeAPI(URL.GET_SUBSCRIPTION_PLANS,"GET",{}); 
    },[]);

  

   

  /**
   * API response handler subscription data
   */  
  useEffect(() => {
    if(loaded){
      if(responseData!=null){
        setSubscriptionState({
          isLoaded:true,
          isError:false,
          subsciptionData:responseData.data
        })
        
      }
      else if(error!==null){
        setSubscriptionState({
          isLoaded:true,
          isError:true,
          subsciptionData:undefined
        })
       
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(error?.response?.status === 401 || error?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          navigate(from, { replace: true });
        }
    }
    reset();
    }
  },[loaded,responseData]);



  const handleBuySuscription=(plan)=>{
    navigate(from, { state: { from:{pathname:"/subscription"}} },{ replace: true });
 }

  

  
  


    return (
      <>
      {isLoaded || true ?
      <Box sx={{ml:4}}>
        <Typography variant="h3" component="div" sx={{mb:1,mt:3,textAlign:'center'}}>Subscription Plans</Typography>
        

        {!isError || true?  
        <Box sx={{display:"flex",justifyContent:'center',alignItems:'center'}}>
          
        
          <Box sx={{ml:4,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column"}}>
            
            <Card  variant="outlined" sx={cardSX}>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-start',height:"560px"}}>
                <Typography  sx={{mb:3,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700}}>Free Usage</Typography>
                <Box display='flex' >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts, Options Table, Greeks Table and Index Table for Nifty and BankNifty instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Delayed intra data across charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Historical MP Charts in different timeframes for higher timeframe market analysis</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto/Manual Saving ability for only continuous instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Basic Candlestick and Line Charts for NF/BNF FnO instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Advanced Open Interest, Inventory and Market Visualization Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Compare current data snapshot with old data at selected date / time in Options Table and all OI and Inventory Visualization Charts to analyze how data changed</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Options position payoff analysis feature in Greeks Table</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Proprietary Options Spectrum Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Custom and Sharable Bookmarks</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Multi-window chart view</Typography>
                </Box>
                
              </Box>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"120px",alignItems:'center'}}>
                <Button size="large"   onClick={handleBuySuscription}  sx={buttonSXLogin}>Login</Button>
              </Box>
              <Typography variant="subtitle1" sx={{fontSize:"12px",ml:1,mt:0,mb:1}}>&nbsp;</Typography>              
            </Card>
            
        </Box>
          <Box sx={{ml:6,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column"}}>
            
            <Card  variant="outlined" sx={cardSXPlan}>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-start',height:"560px"}}>
                <Typography sx={{mb:3,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700,color:"#23B1BA"}}>Premium</Typography>
                <Box display='flex' >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts, Options Table, Greeks Table and Index Table for Nifty and BankNifty instruments</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success'></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Live data across all charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Historical MP Charts in different timeframes for higher timeframe market analysis</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto/Manual Saving supported across all instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Basic Candlestick and  Line Charts for NF/BNF FnO instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Advanced Open Interest and IV Curve Visualization Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Compare current data snapshot with old data at selected date / time in Options Table and all OI and Inventory Visualization Charts to analyze how data changed</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Options position payoff analysis feature in Greeks Table</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Proprietary Options Spectrum Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Custom and Sharable Bookmarks</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='error' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Multi-window chart view</Typography>
                </Box>
                
              </Box>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"120px",alignItems:'center'}}>
              {/* onClick={()=>handleBuySuscription(SUBSCRIPTION_PLAN_LIST[1])}  */}
              
                <Button size="large"  endIcon={<ArrowRightAltIcon sx={{width:"36px",height:"36px"}} />}  onClick={handleClickPremium}  sx={buttonSX}>Purchase Plan</Button>
                  <Popover id={id} open={openPopperPremium} anchorEl={anchorEl} onClose={handleClosePopover} sx={{marginTop:"-5px"}}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  >
                    <Box sx={{  p: 1, height:"200px",bgcolor: '#ffffff',width:"320px",borderBottomRightRadius:"10px",borderBottomLeftRadius:"10px",
                    background: 'linear-gradient(180deg, #23B1BA 0.01%, #FFFFFF 12.08%)',display:'flex',flexDirection:"row" }}>
                     <Box sx={{width:"100px",mt:4}}>
                        <Typography sx={{color:"#000",fontWeight:700,fontSize:14}}>Monthly</Typography>
                        <Typography sx={{color:"#000",fontWeight:700,mt:2,fontSize:14}}>Half-yearly</Typography>
                        <Typography sx={{color:"#000",fontWeight:700,mt:5,fontSize:14}}>Yearly</Typography>
                     </Box>
                     <Box sx={{width:"160px",mt:4}}>
                      <Typography sx={{color:"#000",fontWeight:700,fontSize:14,display:'flex'}}>Rs 590/-&nbsp;&nbsp;&nbsp;
                          <Typography sx={{color:"#000",fontWeight:500,fontSize:10,marginTop:"3px"}}>incl. 18% GST</Typography>
                      </Typography>
                      
                      <Typography sx={{color:"#DF1A0D",fontWeight:700,fontSize:14,mt:2,display:'flex'}}>Rs 2950/-&nbsp;
                          <Typography sx={{color:"#000",fontWeight:500,fontSize:10,marginTop:"3px"}}>incl. 18% GST</Typography>
                      </Typography>
                      <Typography sx={{color:"#000",fontWeight:700,fontSize:14,textDecoration:'line-through'}}>Rs 3540</Typography>
                      
                      <Typography sx={{color:"#DF1A0D",fontWeight:700,marginTop:"18px",fontSize:14,display:"flex"}}>Rs 5900/-&nbsp;
                        <Typography sx={{color:"#000",fontWeight:500,fontSize:10,marginTop:"3px"}}>incl. 18% GST</Typography>
                      </Typography>
                      <Typography sx={{color:"#000",fontWeight:700,fontSize:14,textDecoration:'line-through'}}>Rs 7080</Typography>
                     
                     </Box>
                     <Box sx={{width:"80px",mt:3}}>
                        <Box sx={{mt:1,pl:0}}>
                          <Button sx={buttonSXSmall} onClick={()=>handleBuySuscription(SUBSCRIPTION_PLAN_LIST[0])}>Buy</Button>
                       </Box>
                        <Box sx={{mt:1,pl:0}}>
                          <Button sx={buttonSXSmall} onClick={()=>handleBuySuscription(SUBSCRIPTION_PLAN_LIST[1])}>Buy</Button>
                        </Box>
                        <Box sx={{marginTop:"26px",pl:0}}>
                          <Button sx={buttonSXSmall} onClick={()=>handleBuySuscription(SUBSCRIPTION_PLAN_LIST[2])}>Buy</Button>
                        </Box>
                     </Box>
                    </Box>
                </Popover>
              </Box>
              <Typography variant="subtitle1" sx={{fontSize:"12px",ml:1,mt:0,mb:1}}>Please Check the Refund and Cancellation policy below</Typography>
            </Card>
        </Box>
        <Box sx={{ml:6,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column",alignItems:'center'}}>
            
            <Card  variant="outlined" sx={cardSXPlan}> 
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-start',height:"560px"}}>
                <Typography  sx={{mb:3,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700,color:"#E7B401"}}>Premium +</Typography>
                <Box display='flex' >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts, Options Table, Greeks Table and Index Table for Nifty and BankNifty instruments</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon sx={{mb:1}} color ='success'></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Live data across all charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Historical MP Charts in different timeframes for higher timeframe market analysis</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto/Manual Saving supported across all instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Basic Candlestick and Line Charts for NF/BNF FnO instruments</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Advanced Open Interest, IV Curve, Inventory and Market Visualization Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Compare current data snapshot with old data at selected date / time in Options Table and all OI and Inventory Visualization Charts to analyze how data changed</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Options position payoff analysis feature in Greeks Table</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Proprietary Options Spectrum Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Custom and Sharable Bookmarks</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Multi-window chart view</Typography>
                </Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"120px",alignItems:'center'}}>
              <Button size="large"  endIcon={<ArrowRightAltIcon sx={{width:"36px",height:"36px"}} />}  onClick={handleClickPremiumPlus}  sx={buttonSXPlus}>Purchase Plan</Button>
                  <Popover id={idPlus} open={openPopperPremiumPlus} anchorEl={anchorElPlus} onClose={handleClosePopover} sx={{marginTop:"-5px"}}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  >
                    <Box sx={{  p: 1, height:"200px",bgcolor: '#ffffff',width:"320px",borderBottomRightRadius:"10px",borderBottomLeftRadius:"10px",
                    background: 'linear-gradient(180deg, #E7B401 0.01%, #FFFFFF 12.08%)',display:'flex',flexDirection:"row" }}>
                     <Box sx={{width:"100px",mt:4}}>
                        <Typography sx={{color:"#000",fontWeight:700,fontSize:14}}>Monthly</Typography>
                        <Typography sx={{color:"#000",fontWeight:700,mt:2,fontSize:14}}>Half-yearly</Typography>
                        <Typography sx={{color:"#000",fontWeight:700,mt:5,fontSize:14}}>Yearly</Typography>
                     </Box>
                     <Box sx={{width:"160px",mt:4}}>
                      <Typography sx={{color:"#000",fontWeight:700,fontSize:14,display:'flex'}}>Rs 1180/-&nbsp;&nbsp;&nbsp;
                          <Typography sx={{color:"#000",fontWeight:500,fontSize:10,marginTop:"3px"}}>incl. 18% GST</Typography>
                      </Typography>
                      
                      <Typography sx={{color:"#DF1A0D",fontWeight:700,fontSize:14,mt:2,display:'flex'}}>Rs 5900/-&nbsp;
                          <Typography sx={{color:"#000",fontWeight:500,fontSize:10,marginTop:"3px"}}>incl. 18% GST</Typography>
                      </Typography>
                      <Typography sx={{color:"#000",fontWeight:700,fontSize:14,textDecoration:'line-through'}}>Rs 7080</Typography>
                      
                      <Typography sx={{color:"#DF1A0D",fontWeight:700,marginTop:"18px",fontSize:14,display:"flex"}}>Rs 11800/-&nbsp;
                        <Typography sx={{color:"#000",fontWeight:500,fontSize:10,marginTop:"3px"}}>incl. 18% GST</Typography>
                      </Typography>
                      <Typography sx={{color:"#000",fontWeight:700,fontSize:14,textDecoration:'line-through'}}>Rs 14160</Typography>
                     
                     </Box>
                     <Box sx={{width:"80",mt:3}}>
                        <Box sx={{mt:1,pl:0}}>
                          <Button sx={buttonSXPlusSmall} onClick={()=>handleBuySuscription(SUBSCRIPTION_PLAN_LIST[3])}>Buy</Button>
                       </Box>
                        <Box sx={{mt:1,pl:0}}>
                          <Button sx={buttonSXPlusSmall} onClick={()=>handleBuySuscription(SUBSCRIPTION_PLAN_LIST[4])}>Buy</Button>
                        </Box>
                        <Box sx={{marginTop:"26px",pl:0}}>
                          <Button sx={buttonSXPlusSmall} onClick={()=>handleBuySuscription(SUBSCRIPTION_PLAN_LIST[5])}>Buy</Button>
                        </Box>
                     </Box>
                    </Box>
                </Popover>
              
              </Box>
              <Typography variant="subtitle1" sx={{fontSize:"12px",ml:1,mt:0,mb:1}}>Please Check the Refund and Cancellation policy below</Typography>
            </Card>
        </Box>

     
      </Box>:
      <></>}
        {/* <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h4">
          Pricing and Payment Plans
          <br />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 600 }} variant="h6">
          Free Usage
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 0, ml: 0 }}>
          Under Free access you get: <br/>
          &nbsp;&nbsp;- MP Charts, Options Table, Index Table and Greeks Table with delayed live data.<br/>
          &nbsp;&nbsp;- Historical MP Charts in different timeframes for higher timeframe market analysis.<br/>
          &nbsp;&nbsp;- Auto saving / reload ability for non expiry instruments.<br/>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 600 }} variant="h6">
          Premium Plan
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 0, ml: 0 }}>
          This subscription Plan includes access to, but not limited to, the following: <br/>
          &nbsp;&nbsp;- Real time data across MP Charts, Options Table and Greeks Table.<br/>
          &nbsp;&nbsp;- Candlestick and Line Charts for Futures and Options of NF and BNF.<br/>
          &nbsp;&nbsp;- Autosaving supported across all instruments.<br/>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0 }}>
          Monthly subscription plan of Rs 500 + 18% GST = Rs 590/- <br/>
          Half-yearly subscription plan of Rs 2500 + 18% GST = Rs 2950/- <br/>
          Yearly subscription plan of Rs 5000 + 18% GST = Rs 5900/- <br/>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 600 }} variant="h6">
          Premium Plus Plan
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 0, ml: 0 }}>
          This subscription Plan includes all features of the Premium plan along with access to, but not limited to, the following: <br/>
          &nbsp;&nbsp;- Advanced Market Visualization Charts.<br/>
          &nbsp;&nbsp;- Custom bookmarking and sharability of MP Charts.<br/>
          &nbsp;&nbsp;- Multi-window view to see different charts and instruments data in same page.<br/>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0 }}>
          Monthly subscription plan of Rs 1000 + 18% GST = Rs 1180/- <br/>
          Half-yearly subscription plan of Rs 5000 + 18% GST = Rs 5900/- <br/>
          Yearly subscription plan of Rs 10000 + 18% GST = Rs 11800/- <br/>
        </Typography> */}
        <br/>
        <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0 }}>
          Prices are subject to change, and our plans may become more expensive in the future as we further enhance our services and develop more feature rich, unique and proprietary chart visualizations for you.
          <br/>Subscribe now to secure the best possible rate for our services.
        </Typography>
        <br/>
        <hr/>
        <Typography id="modal-modal-description" sx={{ mt: 4, ml: 0 }} variant="h6">
          In case you are not able to use the above payment options, 
          please use the following QR code for making payments via any UPI application: <br/>
          {/* <img src={upiImg} alt="Gravitas Factorcharts@icici" width={200} height={200} style={{marginLeft:60, marginTop:10}} /><br/> */}
          UPI ID: <u>Gravitas Factorcharts@icici</u><br/>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0, fontSize: 20 }}>
          &nbsp;&nbsp; - Please send a screenshot of the payment success page which has the Transaction Reference Number, your login Email ID and the details of subcription plan applied for to <Link href="mailto:charts@Gravitas Factor.com">charts@Gravitas Factor.com</Link> so that we can process your payment.<br/>
          &nbsp;&nbsp; - Please allow for a time of around 12-24 hours for the subscription to be activated.
        </Typography>
        <br/>
        <hr/>
        <Typography id="modal-modal-description" sx={{ mt: 8, fontWeight: 600 }} variant="h5">
          Cancellations and Refunds for Products
          <br />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          We are committed to providing you with the highest quality service possible. However, please be advised that our charting service is a digital product and as such, we do not offer refunds once the service has been purchased and access has been granted.
          <br />
          <br />
          In order to ensure your satisfaction with our charting service, we recommend that you carefully review all product information, including the product description, before making a purchase. If you have any questions or concerns about the product, please feel free to contact us prior to making a purchase.
          <br />
          <br />
          By purchasing our charting service, you acknowledge and agree that you understand that there will be no refunds for any reason, including but not limited to:
          <br />
          &nbsp;&nbsp;- Dissatisfaction with the product or its performance
          <br />
          &nbsp;&nbsp;- Technical difficulties or issues with accessing the service
          <br />
          &nbsp;&nbsp;- Change of mind or circumstances
          <br />
          &nbsp;&nbsp;- Inability to use the product due to technical requirements or compatibility issues
          <br />
          <br />
          If you have any technical issues with accessing the charting service, please contact us immediately so that we can assist you in resolving the issue. We provide you with the best possible experience and will work to resolve any technical difficulties in a timely manner.
          <br />
          <br />
          Please note that our no refund policy is a fundamental part of our charting service and is non-negotiable. By purchasing and accessing the charting service, you agree to be bound by this policy.
          <br />
          <br />
          If you have any questions or concerns about our no refund policy, please feel free to contact us.

        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 700 }} variant="h5">
          No Refund Policy
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Gravitas Factor Charts provides a comprehensive charting service product and ensures the highest quality of service to our clients.
          However, please note that due to the nature of our services, we have a strict no refund policy.
          All sales are final, and no refunds will be issued for any reason, including but not limited to dissatisfaction with the product, changes in personal circumstances, or technical difficulties.
          <br /><br />
          By purchasing our charting service product, you acknowledge and agree to our no refund policy and that your purchase is a one-time transaction and non-refundable.
          Gravitas Factor Charts is not responsible for any inconvenience or loss that may result from using our services.
          <br /><br />
          If you have any questions or concerns about our charting service product or no refund policy, please contact us before making a purchase.
          Our customer service team will be happy to assist you and answer any questions you may have.
          <br /><br />
        </Typography>

        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>

      </Box> :
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
        </div>} 
      </>          
       
    )
}

export default Pricing
