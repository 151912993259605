import React from 'react'
import {Grid, Box, Paper,Typography} from "@mui/material";
import logo from '../../../../assets/images/logo.png';


/**
 * 
 * @param {*} param0 
 * @returns Conatiner to be used in all the form for login/register
 */
function FormContainer({children}) {
  return (
    <Grid item mobile={12} tablet={12} laptop={12} component={Paper} elevation={0} 
    sx={{pt:{mobile:0,tablet:0},backgroundColor:"#f1f1f1"}}
    square>
       <Box
            sx={{
             
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent:'center',
              backgroundColor:"#fff",
            }}
            >
               <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',pb:0,backgroundColor:"#173340",width:"100%",pb:1,pt:1}}>
               <a href="https://www.gravitasfactor.com/" target="_blank" style={{marginTop:"5px"}}><img src={logo} alt="Gravitas Factor Logo" width={60} height={60}/></a>
              <Typography variant="titleMedium" sx={{textAlign:'center',fontSize:"24px",color:"#fff"}} >Gravitas Factor</Typography>
            </Box>
          <Box
            sx={{
              px: {mobile:2,tablet:2,laptop:6},
              py: {mobile:2,tablet:2,laptop:2},
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent:'center',
            }}
          >
           
            {children}
            </Box>
            </Box>
    </Grid>        
  )
}

export default FormContainer
