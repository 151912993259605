import React, { useEffect, useState, useRef } from 'react'
import Navbar from './components/Navbar'
import { Col, Row, Tabs, Table, Tag, Avatar, Form, Input, Pagination, Dropdown, Button, Space, Menu, Modal } from 'antd'
import { SearchOutlined, DownOutlined, UserOutlined, PlusOutlined, InfoCircleOutlined, CloseOutlined, CloseCircleOutlined, EditOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { TEXT_MSGS, SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import axios from 'axios';
import AddRequest from './add_request';
import TermsOfUseModal from './components/terms_of_use';
import InvoiceInfo from './invoice_info_popup';
import UpdateRequest from './update_request';
import "./styles/styles.scss"
const { TabPane } = Tabs;

const INVOICES = () => {

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTab, setIsLoadingTab] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [cancel, responseData, error, loaded, reset, executeAPI] = useAxiosWithAuth();
    const [cancelSave, responseDataSave, errorSave, loadedSave, resetSave, executeAPISave] = useAxiosWithAuth();
    const [cancelStatus, responseDataStatus, errorStatus, loadedStatus, resetStatus, executeAPIStatus] = useAxiosWithAuth();
    const navigate = useNavigate();
    const [tableHeight, setTableHeight] = useState(Math.max(window.innerHeight - 380, 300))
    const [tableWidth, setTableWidth] = useState(Math.max(window.innerWidth - 40, 600))
    const location = useLocation();
    const [msgState, setMsgState] = useState({
        open: false,
        msg: "",
        severity: "info"
    });
    const { open, msg, severity } = msgState;

    const searchTimeoutRef = useRef(null);

    const [searchVal, setSearchVal] = useState("");
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [data, setData] = useState([]);

    const [filteredData, setFilteredData] = useState([]);

    const [elementsPerPage, setElementsPerPage] = useState('10');

    const [activeTabKey, setActiveTabKey] = useState('1');
    const [paginationData, setPaginationData] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        position: ["bottomCenter"],
    });
    const [orderBy, setOrderBy] = useState({ field: 'name', order: 'ascend' });
    const [stats, setStats] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        document.title = "Discounted Invoices";
      }, []);

    const [isRequestModelOpen, setIsRequestModelOpen] = useState(false)

    const openRequestModal = () => {
        setIsRequestModelOpen(true);
    }

    const closeRequestModal = (isModified = false) => {
        if (isModified)
            fetchData2(paginationData.current, paginationData.pageSize, searchVal, orderBy);
        setIsRequestModelOpen(false);

    }

    const [isEditModelOpen, setIsEditModelOpen] = useState(false)

    const openEditModal = () => {
        setIsEditModelOpen(true);
    }

    const closeEditModal = (isModified = false) => {
        console.log("close isModefied=", isModified);
        if (isModified)
            fetchData2(paginationData.current, paginationData.pageSize, searchVal, orderBy);
        setIsEditModelOpen(false);
    }

    const [invoiceRecord, setInvoiceRecord] = useState();
    const [isInvoiceModelOpen, setIsInvoiceModelOpen] = useState(false)

    const openInvoicetModal = (record) => {
        setInvoiceRecord(record)
        setIsInvoiceModelOpen(true);
    }

    const closeInvoiceModal = () => {
        setIsInvoiceModelOpen(false);
    }





    const handleTableChange = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        console.log("page", pagination, order, sorter);
        if (order != false)
            setOrderBy(sorter);
        let orderBy = null;
        if (order === 'ascend') {
            orderBy = `${sorter.field}`;
        } else if (order === 'descend') {
            orderBy = `-${sorter.field}`;
        }

        fetchData2(current, pageSize, searchVal, orderBy);
    };

    const fetchData2 = async (page = paginationData.current, pageSize = paginationData.pageSize, search = '', order = null, status = null) => { // giving default constructor, incase if any value is not present, then it will search for default values
        try {
            // setIsLoading(true);
            setIsLoadingTab(true)
            status = activeTabKey == "1" ? null : activeTabKey == "2" ? 1 : activeTabKey == "3" ? 3 : activeTabKey == "4" ? 2 : activeTabKey == "5" ? 4 : null;
            // console.log("search= 0=",search);
            // search = search && searchVal;
            console.log("search==",search,search=="");
            console.log("Paramas", {
                offset: (page * pageSize - 1),
                limit: pageSize,
                search: searchVal,
                ordering: order,
                status: status,
            })
            let response;
            const url = `${process.env.REACT_APP_BASE_URL}${URL.INVOICE_DISCOUNTING}`
            if (search.length < 3) {
                response = await axios.get(url, {
                    params: {
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                        search: search,
                        ordering: order,
                        status: status,
                    },
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': 'Token ' + JSON.parse(localStorage.getItem("token"))
                    }
                });
            } else {

                response = await axios.get(url, {
                    params: {
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                        search: search,
                        ordering: order,
                        status: status,
                    },
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': 'Token ' + JSON.parse(localStorage.getItem("token"))
                    }
                });
            }
            const { results, count, global_stats } = response.data;
            console.log("response.data ", response.data);
            setFilteredData(results);
            setData(results);
            let counts = {};
            global_stats.forEach((stat) => {
                counts[stat.status] = stat.total;
            });
            console.log("counts ", stats, stats.length)
            // if(stats && stats.length<=0)
            setStats(counts);
            console.log(stats)

            setPaginationData({ ...paginationData, current: page, total: count });
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
            setIsLoading(false);
            setIsLoadingTab(false);
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchVal(value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        const timeout = setTimeout(() => {
            fetchData2(1, paginationData.pageSize, value, orderBy);
        }, 1000);
        searchTimeoutRef.current = timeout;
        // if (value.length >= 3) {

        // } else {
        //     // setFilteredData(data);
        // }
    };

    // * below code works
    // useEffect(() => {
    //     // fetchData2();
    //     fetchData2(paginationData.current, paginationData.pageSize, searchVal, orderBy.order === 'ascend' ? `+${orderBy.field}` : `-${orderBy.field}`)
    // }, [paginationData.current, paginationData.pageSize, orderBy.field, orderBy.order, activeTabKey]);


    useEffect(() => {
        // Only fetch data if all required dependencies are available
        // if (paginationData.current && paginationData.pageSize && orderBy.field && orderBy.order && activeTabKey) {
        //   fetchData2(paginationData.current, paginationData.pageSize, searchVal, orderBy.order === 'ascend' ? `+${orderBy.field}` : `-${orderBy.field}`);
        // }
        console.log("search page useEffet called for api");
        fetchData2(paginationData.current, paginationData.pageSize, searchVal, orderBy);
    }, [paginationData.pageSize, activeTabKey]);

    const handleTabClick = (key) => {
        if(activeTabKey==key) return;
        setSearchVal("");
        setPaginationData({ ...paginationData, current: 1 });
        setActiveTabKey(key); // callAPIAgain();
    };

    const handleShowModal = (record) => {
        setInvoiceRecord(record)
        setIsInvoiceModelOpen(true);

    };

    const updateRequest = (record) => {
        setInvoiceRecord(record);
        setIsEditModelOpen(true);

    }

    const renderStatus = (text, record) => {
        let content = '';
        switch (text) {
            case 1:
                // setIsModalOpen(true);
                return (
                    <div style={{ width: "140px", display: 'flex' }}>
                        <Tag color="yellow" style={{ width: "70px" }} bordered={false}>Pending</Tag>
                        <small onClick={() => handleShowModal(record)} style={{ color: "#338091", cursor: "pointer", marginTop: "4px" }} >
                            <InfoCircleOutlined style={{ fontSize: "16px" }} />
                        </small>
                    </div>
                )
            case 2:
                // setIsModalOpen(true);
                return (
                    <div style={{ width: "140px", display: 'flex' }}>
                        <Tag color="red" style={{ width: "70px" }} bordered={false}>Ineligible</Tag>
                        <small onClick={() => handleShowModal(record)} style={{ color: "#338091", cursor: "pointer", marginTop: "4px" }} >
                            <InfoCircleOutlined style={{ fontSize: "16px" }} />
                        </small>
                    </div>

                )
            case 3:
                // setIsModalOpen(true);
                return (
                    <div style={{ width: "140px", display: 'flex' }}>
                        <Tag color="green" style={{ width: "70px" }} bordered={false}>Accepted</Tag>
                        <small onClick={() => handleShowModal(record)} style={{ color: "#338091", cursor: "pointer", marginTop: "4px" }} >
                            <InfoCircleOutlined style={{ fontSize: "16px" }} />
                        </small>
                    </div>

                    // <span><Tag color="green" bordered={false}>Accepted</Tag>
                    //     <small onClick={() => handleShowModal(text,record)} style={{ color: "#0000EE", cursor: "pointer", }} >
                    //         <InfoCircleOutlined />
                    //     </small>
                    //     <Modal
                    //         title="Status Details"
                    //         open={isModalOpen}
                    //         onOk={handleOk} 
                    //         onCancel={handleCancel}
                    //     >
                    //         <p>{modalContent}</p>
                    //     </Modal>    
                    // </span>
                )
            case 4:
                // setIsModalOpen(true);
                return (

                    <div style={{ width: "140px", display: 'flex' }}><Tag color="red" style={{ width: "70px" }} bordered={false}>Declined</Tag>
                        <small onClick={() => handleShowModal(record)} style={{ color: "#338091", cursor: "pointer", marginTop: "4px" }} >
                            <InfoCircleOutlined style={{ fontSize: "16px" }} />
                        </small>
                        <small onClick={() => updateRequest(record)} style={{ color: "#338091", cursor: "pointer", marginTop: "4px" }} >
                            <EditOutlined style={{ fontSize: "16px", marginLeft: '8px' }} />
                        </small>
                    </div>
                )


            //   return (
            //     <div>
            //       <span>Declined</span>
            //       <Button type="link" icon={<EditOutlined />} />
            //       <Button type="link" icon={<CloseCircleOutlined />} />
            //     </div>
            //   );
            default:
                content = '';
                setModalContent(content);
                setIsModalOpen(false);
                break;
        }

    }
    const columns = [

        {
            title: 'Invoice #',
            dataIndex: 'invoice_number',
            width: "110px",
            minWidth: "90px"
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: "160px",
            minWidth: "160px",

            render: (text, record, index) => renderStatus(text, record)
        },
        {
            title: 'Date Of Invoice',
            dataIndex: 'date_of_invoice',
            width: "120px",
            minWidth: "120px",
            sorter: true,
            sortOrder: orderBy.field === 'date_of_invoice' && orderBy.order,
        },
        {
            title: `Invoice \nDue Date`,
            dataIndex: 'invoice_due_date',
            sorter: true,
            sortOrder: orderBy.field === 'invoice_due_date' && orderBy.order,
            width: "150px",
            minWidth: "150px"
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            width: "200px",
            minWidth: "160px"
        },
        {
            title: 'Customer GST',
            dataIndex: 'customer_gst',
            width: "190px",
            minWidth: "190px",
            render: (text) => <b>{text}</b>,
        },
       
        {
            title: `Discount\n Type`,
            dataIndex: 'discount_type',
            width: "190px",
            minWidth: "190px",
            render: (text) => `${text == "1" ? "Factoring" : text == "2" ? "Dynamic Discounting" :text == "3" ? "Reverse Factoring":text == "4" ? "Invoice Discounting": "NA"}`,
        },
        {
            title: `Enable\n Discounting`,
            dataIndex: 'is_discounting',
            width: "150px",
            minWidth: "150px",
            render: (text) => `${text == true ? "Yes" : text == false ? "No" : "NA"}`,
        },
        {
            title: `Invoice\n Amount`,
            dataIndex: 'original_invoice_amount',
            width: "150px",
            minWidth: "150px",
            render: (text) => `${text.toLocaleString()}`,
        },
        // {
        //     title: `Maximum\n Discount\n Percentage`,
        //     dataIndex: 'max_discount_percentage',
        //     render: (text) => <b>{text}%</b>,
        //     width: "150px",
        //     minWidth: "150px",
        // },
        // {
        //     title: `No. of Discount \n days`,
        //     dataIndex: 'max_discount_days',
        //     width: "150px",
        //     minWidth: "150px",
        // },
        // {
        //     title: `Invoice\n Amount\n After\n Discount`,
        //     dataIndex: 'invoice_amount_after_discount',
        //     width: "150px",
        //     minWidth: "150px",
        //     render: (text) => `${text.toLocaleString()}`,
        // },

    ];

    const dropdownitemsForPagination = [
        {
            label: '10',
            key: '10',
            // icon: <UserOutlined />,
        },
        {
            label: '20',
            key: '20',
        },
        {
            label: '30',
            key: '30',
        },
    ];

    const handleMenuClick = (e) => {
        setElementsPerPage(e.key);
        setPaginationData({ ...paginationData, current: 1, pageSize: parseInt(e.key) }); // callAPIAgain();
        // fetchData2(1, parseInt(e.key));
    };

    const menuProps = {
        items: dropdownitemsForPagination,
        onClick: handleMenuClick,
    }

    const handleClearSearch = () => {
        if (searchVal != '') {
            setSearchVal('');
            fetchData2(paginationData.current, paginationData.pageSize, ''); // else no data will be displayed!
        }
    };


    const searchBar = (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <Input
                placeholder="🔍 on Customer's Name, GST & Invoice Number"
                value={searchVal}
                size="large"
                key="search"
                // autoFocus="autoFocus"
                onChange={handleSearch}
                suffix={
                    (
                        <Button
                            type="link"
                            style={{ color: '#338091' }}
                            icon={<CloseOutlined />}
                            onClick={handleClearSearch}
                        />
                    )
                }
                style={{ width: 400, marginRight: 8, borderRadius: '15px', }}
            />
            <Button onClick={openRequestModal} size="large" shape="round" style={{ "background": "#173340", color: "white", borderRadius: '15px', marginRight: "4px" }} >Add Invoice
                {/* <span style={{ border: "1px solid white",color:"#fff", margin: "0px 5px", padding: "0px 6px",marginLeft:"10px" }}>+</span> */}
            </Button>
        </div>
    );

    const showModal = () => {
        setIsModalOpen(true);
        console.log("showModal");
    };

    const handleOk = () => {
        setIsModalOpen(false);
        console.log("handleOk");
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        console.log("handleCancel");
    };

    const editRequest = (record) => {
        setIsInvoiceModelOpen(false);
        setIsEditModelOpen(true);
    }

    const TabComponent =React.useMemo(
        () =>(name, ind, val) => {
        console.log("Test TabComponent",name);
        return (
            <TabPane tab={<>{name} <span><Avatar shape="square" style={activeTabKey == { ind } ? { background: "#173340", margin: '5px' } : { background: "#ABABAB", margin: '5px' }} size="small" >{val}</Avatar></span></>} key={ind}>
                <>
                    <Row justify="space-between" style={{ margin: '5px' }} >
                        {/* <p style={{ color: "#ABABAB", margin: '2px 10px' }} >Showing result {(paginationData.current - 1) * paginationData.pageSize + 1} - {(paginationData.total == ((paginationData.current) * paginationData.pageSize)) ? ((paginationData.current) * paginationData.pageSize) : paginationData.total} Result</p> */}
                        <p style={{ color: "#ABABAB", margin: '2px 10px' }} >Showing result {(paginationData.current - 1) * paginationData.pageSize + 1} - {(paginationData.total == ((paginationData.current) * paginationData.pageSize)) ? ((paginationData.current) * paginationData.pageSize) : (paginationData.total > ((paginationData.current) * paginationData.pageSize) ? ((paginationData.current) * paginationData.pageSize) : paginationData.total)} Result</p>
                        <div>
                            Items per Page:
                            <span style={{ margin: '2px 3px' }}>
                                <Dropdown menu={menuProps} >
                                    <Button>
                                        <Space>
                                            {paginationData.pageSize}
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </span>
                        </div>


                    </Row>
                    {!isLoadingTab?
                    <Table
                        columns={columns}
                        dataSource={filteredData} // rowKey={getKey} // pagination={false}
                        pagination={paginationData}
                        loading={isLoading}
                        onChange={handleTableChange}
                        bordered
                        scroll={{ y: tableHeight }}
                    // scroll={{ x: '100%' }} // add horizontal scroll if table exceeds screen width
                    />:
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <CircularProgress sx={{ marginTop: "10%" }} />
                        </div>
                    }

                </>
            </TabPane>
        )
    
    }
    )


    return (
        <div style={{ width: `calc(100vw - 0px)`, overflowX: 'scroll',marginTop:"64px" }} >
            {!isLoading ?
                <>
                    {/* <Navbar /> */}


                    <Row style={{ margin: "10px", height: "calc(100vh-64px)", width: '800px', display: "flex", alignItems: "center", }}>
                        {isRequestModelOpen ?
                            <AddRequest closeModal={closeRequestModal}></AddRequest>
                            :
                            <></>}

                        {isEditModelOpen ?
                            <UpdateRequest record={invoiceRecord} closeModal={closeEditModal}></UpdateRequest>
                            :
                            <></>}

                        {isInvoiceModelOpen ?
                            <InvoiceInfo editRequest={editRequest} record={invoiceRecord} closeModal={closeInvoiceModal}></InvoiceInfo>
                            :
                            <></>}
                        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}> */}
                        <div >
                            <Tabs
                                defaultActiveKey="1"
                                style={{ flex: 1, width: `calc(100vw - 20px)`, minWidth: '1260px', paddingLeft: '10px' }}
                                tabBarExtraContent={searchBar}
                                activeKey={activeTabKey}
                                onTabClick={handleTabClick}
                                destroyInactiveTabPane={true}
                            >
                                {TabComponent("All", 1, (stats[1] || 0) + (stats[2] || 0) + (stats[3] || 0) + (stats[4] || 0))}
                                {TabComponent("Pending", 2, (stats[1] || 0))}
                                {TabComponent("Accepted", 3, (stats[3] || 0))}
                                {TabComponent("Ineligible", 4, (stats[2] || 0))}
                                {TabComponent("Declined", 5, (stats[4] || 0))}
                            </Tabs>
                        </div>
                        {/* </Col> */}
                    </Row>

                    {/* <Row justify="center">
                        <Pagination
                            defaultCurrent={currentPage}
                            current={currentPage} 
                            total={totalNoOfElements}
                            hideOnSinglePage={true}
                            pageSize={elementsPerPage}
                            onChange={onPaginationNumberChange}
                            // showTotal={(total) => `Total ${total} items`}
                            responsive={true}
                            showSizeChanger={false}
                        // simple = {true}
                        />
                    </Row> */}
                </>
                :
                <div style={{ textAlign: "center", width: "100%" }}>
                    <CircularProgress sx={{ marginTop: "10%" }} />
                </div>
            }

        </div>
    )
}


export default INVOICES