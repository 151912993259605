import React from 'react'
import { Toolbar,Grid,Button,Box,Link} from '@mui/material'
import {styled} from '@mui/material/styles';
import logo from '../../../../assets/images/logo.svg';


const HeaderToolbar=styled(Toolbar)(
  ({theme})=> `
  background-color:${theme.palette.primaryTheme.shade10};
  width:100vw;
  height:64px;
  position:fixed;
  ${theme.breakpoints.up('mobile')}{
    min-height:64px;
  }
  `
)

/**
 * 
 * @param {showLogin} props: it will used to toggle between Login and Register button in the flow 
 * @returns The main header component for the login/registerflow
 */

function Header(props) {
  return (
    <div>
        <HeaderToolbar >
        <Grid  container  >
          <Grid item mobile={6} tablet={6} laptop={6} >
            <Box display="flex" justifyContent="flex-start">
              
            </Box>
        </Grid>
        <Grid item mobile={6} tablet={6} laptop={6} >
            <Box display="flex" justifyContent="flex-end" alignItems={"center"}>
               
            
              </Box>
              </Grid>
        </Grid>
        </HeaderToolbar>
      
    </div>
  )
}

export default Header


