import * as React from 'react';
import { useState,useRef,useEffect } from 'react';

import { Col, Row, Timeline, Input, InputNumber } from 'antd';
import { Link } from 'react-router-dom';

import { Formik, Form, Field,ErrorMessage} from 'formik';
import {IconButton,Alert, Snackbar,Button,useTheme,CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateAddressDetailsForm } from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CheckCircleFilled } from '@ant-design/icons';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import ACTIVATION_LEFT_PANEL from './components/activation_left_panel';





const { Option } = Select;

const initialValues = {
    address_1:'',
    pincode_1:'',
    city_1:"",
    state_1:"",                            
    email_1: '',
    mobile_1:'',
    address_2:'',
    pincode_2:'',
    city_2:"",
    state_2:"",                            
    email_2: '',
    mobile_2:'',
    
  };
  
const Address_Page = () => {

    const [entityType, setEntityType] = useState();
    const [isLoading,setIsLoading]=useState(true);
    const theme=useTheme();
    const [rememberMe, setRememberMe] = useState(false);
    // const [form] = Form.useForm();
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelSave,responseDataSave,errorSave,loadedSave,resetSave,executeAPISave] = useAxiosWithAuth();
    const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const formikRef = useRef();
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
   const { open, msg,severity } = msgState;
   const [addressDetails,setAddressDetails]=useState(initialValues);
   const [statusData,setStatusData]=useState();

   useEffect(() => {
    document.title = "Address Details";
     }, []);



    useEffect(()=>{
        executeAPIStatus(URL.ACTIVATION_STATUS,"GET",null);
        executeAPI(URL.ACTIVATION_ADDRESS_DETAILS,"GET",null);
    },[])

    /**
     * API response handler  status API 
    */  
    useEffect(() => {
        if(loadedStatus){
        console.log("activation status response",responseDataStatus);
        if(responseDataStatus!=null){
            setStatusData(responseDataStatus);
            // setIsLoading(false);
        }
        else if(errorStatus!==null){
            // setIsLoading(false);
            console.log("Error data=",errorStatus);
           
        
            //if unauthorized then redirec it to login page
            if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
                console.log("status received =",errorStatus?.response?.status)
                 navigate("/login", { replace: true });
            }
            setMsgState({open:true,msg:errorStatus?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        }
        resetStatus();
        }
        },[loadedStatus,responseDataStatus]);

    /**
     * API response handler  
    */  
    useEffect(() => {
        if(loaded){
        console.log("loaded=true",responseData);
        
        if(responseData!=null){
        if(responseData && responseData[0]&& responseData["1"].company_address){
           
            let tempData={
                address_1:responseData[0].company_address,
                pincode_1:responseData[0].pincode,
                city_1:responseData[0].city,
                state_1:responseData[0].state,                            
                email_1: responseData[0].email,
                mobile_1:responseData[0].mobile,
                address_2:responseData[1].company_address,
                pincode_2:responseData[1].pincode,
                city_2:responseData[1].city,
                state_2:responseData[1].state,                            
                email_2: responseData[1].email,
                mobile_2:responseData[1].mobile,
            }
            setAddressDetails(tempData);
        }

        setIsLoading(false);  
            
        }
        
        else if(error!==null){
            setIsLoading(false);
            console.log("Error data=",error);
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                navigate("/login", { replace: true });
            }
            setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        }
        reset();
        }
        },[loaded,responseData]);

     /**
    * API caller  
    */  
  const onSubmit = (values, props) => {
    console.log("onSubmit=",JSON.stringify(values));
    // setAPIError(undefined);
    
    let data_1={
        "city": values.city_1,
        "pincode": values.pincode_1,
        "state": values.state_1,
        "mobile": values.mobile_1,
        "email": values.email_1,
        "company_address": values.address_1
    }
    let data_2={
        "city": values.city_2,
        "pincode": values.pincode_2,
        "state": values.state_2,
        "mobile": values.mobile_2,
        "email": values.email_2,
        "company_address": values.address_2
    }

    let jsonData={
        "1":data_1,
        "2":data_2

    }

    console.log('jsonData=',jsonData,JSON.stringify(jsonData));
    // return;


    executeAPISave(URL.ACTIVATION_ADDRESS_DETAILS,"POST",JSON.stringify(jsonData));    
   }

   /**
     * API response handler Save detailes
    */  
   useEffect(() => {
    if(loadedSave){
    console.log("Save data response",responseDataSave);
    if(responseDataSave!=null){
        setMsgState({open:true,msg:"Address details saved successfully.",severity:"success"});
        setTimeout(()=>{
            navigate("/activation/status", { replace: true });
        },2000);
    
    }
    else if(errorSave!==null){
        formikRef?.current.setSubmitting(false);
        console.log("Error data=",errorSave);
        //if unauthorized then redirect it to login page
        if(errorSave?.response?.status === 401 || errorSave?.response?.status === 403){
            console.log("status received =",errorSave?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
    }
    resetSave();
    }
    },[loadedSave,responseDataSave]);


 /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };


  const handleCheckChange=(val)=>{
    // console.log("handleCheckChange=",val,formikRef.current.values.address_1,formikRef.current.errors);
    if(val){
            formikRef.current.setFieldValue("address_2",formikRef.current.values.address_1);
            formikRef.current.setFieldValue("pincode_2",formikRef.current.values.pincode_1);
            formikRef.current.setFieldValue("city_2",formikRef.current.values.city_1);
            formikRef.current.setFieldValue("state_2",formikRef.current.values.state_1);
            formikRef.current.setFieldValue("email_2",formikRef.current.values.email_1);
            formikRef.current.setFieldValue("mobile_2",formikRef.current.values.mobile_1);
    }
  }



const handleFormChange = (event) => {
    // console.log("Form::onChange", event);
    if(rememberMe){
        formikRef.current.setFieldValue("address_2",formikRef.current.values.address_1);
        formikRef.current.setFieldValue("pincode_2",formikRef.current.values.pincode_1);
        formikRef.current.setFieldValue("city_2",formikRef.current.values.city_1);
        formikRef.current.setFieldValue("state_2",formikRef.current.values.state_1);
        formikRef.current.setFieldValue("email_2",formikRef.current.values.email_1);
        formikRef.current.setFieldValue("mobile_2",formikRef.current.values.mobile_1);
}
};

const goBack=()=>{
    if(statusData.entity_type==1)
        navigate("/activation/bank-details", { replace: true });
    else
        navigate("/activation/kyc", { replace: true });

}

    return (
        <div style={{ width: '100vw',marginTop:"64px"  }} >
             {!isLoading?
            <>
            {/* <Navbar /> */}

            <Row>
            <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={5}>
                    <ACTIVATION_LEFT_PANEL statusData={statusData} index={3}></ACTIVATION_LEFT_PANEL>    
                </Col>
                
                <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={19}>

                    {/* content for column 2 goes here */}
                    <div style={{ height: `calc(100vh - 64px)`, display: 'flex', direction: 'verticle', alignItems: 'flex-start', justifyContent: 'center',backgroundColor:"#fff" }}>

                        {/* <div style={{ width: "calc(100% - 20px)",marginTop:"40px" }} >
                        <Typography  variant="titleMedium" sx={{fontSize:"24px",mb:2}}>
          Address Details:
        </Typography> */}
        <div style={{ width: "calc(100% - 20px)",paddingLeft:"20px",paddingRight:"20px" }} >
                            <Box sx={{mt:6}}>
                                <Typography  variant="titleMedium" sx={{fontSize:"24px"}}>
                                Address Details:
                                </Typography>
                            </Box>
                        <Formik enableReinitialize={true} innerRef={formikRef} initialValues={addressDetails} onSubmit={onSubmit} validate={validateAddressDetailsForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form 
                // onChange={handleFormChange}
                >
                 <Box display="flex"  sx={{fontSize:"16px",mt:2}}>
                 <Typography  variant="titleMedium" sx={{fontSize:"18px",mt:2}}>
                    
                 Company Registeration Address
                </Typography>
                </Box>
                  <Box display="flex" >
                    
                  <Grid container spacing={2} sx={{mt:1,mb:2}}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="address_1"
                        name="address_1"
                        type="text"
                        label="Address"
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                       
                        />
                    </Grid>
                    
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="pincode_1"
                        name="pincode_1"
                        type="text"
                        label="Pin Code"
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                </Grid>
                </Box>

                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="city_1"
                        id="city_1"
                        type="text"
                        label="City"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="state_1"
                        id="state_1"
                        type="text"
                        label="State"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                  </Grid> 
                   
              </Grid>
              </Box>

                
                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="mobile_1"
                        id="mobile_1"
                        type="text"
                        label="Mobile Number"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="email_1"
                        name="email_1"
                        type="text"
                        label="Email Address"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                  </Grid> 
                   
              </Grid>
              </Box>
              

             
              <Box display="flex" sx={{ mt: 2 }} justifyContent="flex-start" alignItems="center">
              <Typography  variant="titleMedium" sx={{fontSize:"18px"}}>
              Communication Address
              </Typography>

                <Field as={FormControlLabel}
                    name='remember_me'
                    size='small'
                    disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    control={<Checkbox 
                      checked={rememberMe}
                      onChange={()=>{
                        setRememberMe(!rememberMe);
                        handleCheckChange(!rememberMe);
                    }}
                      sx={{
                        ml:2,
                        color: theme.palette.primaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.primaryTheme.shade03,
                        },
                      }}/>
                    }
                    label={<Typography variant="linkSupportText" >
                        Same as above
                      </Typography>
                    }
                />
                </Box>
                <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:0,mb:2}}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="address_2"
                        name="address_2"
                        type="text"
                        label="Address"
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="pincode_2"
                        name="pincode_2"
                        type="text"
                        label="Pin Code"
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                </Grid>
                </Box>

                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="city_2"
                        id="city_2"
                        type="text"
                        label="City"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="state_2"
                        id="state_2"
                        type="text"
                        label="State"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                  </Grid> 
                   
              </Grid>
              </Box>

                
                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="mobile_2"
                        id="mobile_2"
                        type="text"
                        label="Mobile Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="email_2"
                        name="email_2"
                        type="text"
                        label="Email Address"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                  </Grid> 
                   
              </Grid>
              </Box>
              

             
              
               <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'space-between',alignItems:"center"}}> 
               <Button onClick={()=>goBack()}  sx={{mb:2, mt:4,height:"50px",width:"160px",mr:2}} variant="contained" disabled={(statusData?.status && (statusData?.status==2 || statusData?.status==4))}
                      >{"BACK"}</Button>
                <Button type='submit'  sx={{mb:2,mr:0, mt:4,height:"50px",width:"160px"}} variant="contained" disabled={isSubmitting||(statusData?.status && (statusData?.status==2|| statusData?.status==4))}
                      >{isSubmitting ? "Loading" : "SAVE & NEXT"}</Button>
              </Grid>
              <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
              {statusData?.status && (statusData?.status==2|| statusData?.status==4)?
                 <Typography  variant="titleMedium" sx={{fontSize:"16px"}}>
                    Account verification is under review.
                    </Typography>:
                    <></>

                 }  
                 </Grid>

          </Form>
          )}
        </Formik>
                        
                        </div>

                    </div>
                </Col>
            </Row>
            <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>:
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/></div>}
        </div>
    )
}

export default Address_Page