import * as React from "react";
import { NumericFormat } from "react-number-format";

import { useField } from "formik";

const AmountField = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);

  const passwordConfig = {
    ...field,
    ...otherProps,
    name: "dummy_original_invoice_amount",
    label: "Invoice Amount",
    thousandSeparator: ",",
    allowNegative: false,
    decimalScale: 2,
    decimalSeparator: ".",
    fixedDecimalScale: false
  };

  return <NumericFormat {...passwordConfig} />;
};

export default AmountField;
