import { useLocation } from "react-router-dom";


export const useQuery = (param) => {
    return new URLSearchParams(useLocation().search).get(param);
  };
  
export const getAccessToken = () => {
    return JSON.parse(localStorage.getItem("accessToken"));
  };
  
  export const setAccessToken = (token) => {
    localStorage.setItem("accessToken", JSON.stringify(token));
  };
  
  export const updateAccessToken = (token) => {
    let accessToken = JSON.parse(localStorage.getItem("accessToken"));
    accessToken = token;
    localStorage.setItem("accessToken", JSON.stringify(accessToken));
  };

  export const getIndexOfRoute=(route,search,auth)=>{
    // console.log("getIndexOfRoute: route and search",route,search,route+search,auth)
    let index=-1;
    // const drawerListData=getDrawerList(auth)
    // // console.log("getIndexOfRoute: route and search",route,search,route+search,drawerListData)
    // if(route!=undefined || route!=null)
    //   {
    //     for(let i = 0; i < drawerListData.length; i++){ 
    //       if(drawerListData[i].route==route+search){
    //         console.log("getIndexOfRoute found: route and search",i)
    //         return i;
            
    //       }
    //     }
    //     for(let i = 0; i < drawerListData.length; i++){ 
    //       if(drawerListData[i].route==route){
    //         console.log("getIndexOfRoute found: route",i)
    //         return i;
    //       }
    //     }
    // }
    // console.log("getIndexOfRoute not found: ",index)
    return index;
  }

  //TODO: handle no last name otherwise it will crash the app
  export const stringAvatar=(name)=> {
    
    return {
      sx: {
        bgcolor:'primaryTheme.shade01',
        color:'white',
        width: 36, height: 36, fontSize:"16px", cursor:"pointer"
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  } 
  

  export const createDictList=(startTime, endTime, interval,gap)=> {
    // Define starting time, value and label
    let time = startTime;
    let value = 0;
    let label = "A";
    let lastLabel="A"
  
    // Define an empty list to store the dictionaries
    let dictList = [];
  
    // Loop through the time range from start to end
    while (time <= endTime) {
      // Create a new dictionary with the current value, label and time
      let dict = {
        value: value,
        label: label,
        time: time
      };
      // Add the dictionary to the list
      dictList.push(dict);
      // Increment the value
      value++;
      // Increment the label every interval minutes
      // if (value % (60 / interval) === 0) {
        if(value%gap==0){
        label = String.fromCharCode(lastLabel.charCodeAt(0) + 1);
        lastLabel=label;
        }
        else
        label=""
      // }
      // Increment the time by interval minutes
      let [hours, minutes] = time.split(":").map(Number);
      minutes += interval;
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }
      time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }
    // console.log("time interval list= ",dictList);
    return dictList;
  }
  
  