import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar';
import { Col, Row, Timeline, Table, Dropdown, Space, message, } from 'antd';
import { DeleteOutlined, DownOutlined, UserOutlined, UploadOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useFilePicker } from 'use-file-picker';
import Files from 'react-files';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button,Snackbar,Alert,CircularProgress,Divider,TextField,OutlinedInput } from '@mui/material';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateCompanyDetailsForm } from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios, { post } from 'axios';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import { Tooltip } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import ACTIVATION_LEFT_PANEL from './components/activation_left_panel';



const KYC_Form = () => {

    const navigate = useNavigate();
    const location = useLocation();
      const [selectedFile,setSelectedFile]=useState();
      const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
      const [cancelKYC,responseDataKYC,errorKYC,loadedKYC,resetKYC,executeAPIKYC] = useAxiosWithAuth();
      const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
      const [cancelDelete,responseDataDelete, errorDelete, loadedDelete, resetDelete,executeAPIDelete ] = useAxiosWithAuth();
      const [state,setState]=useState({
        isLoading:true,
        docList:undefined,
        categoryList:[],
        subCategoryList:[],
        categoryGroups:[],
        selectedCategory:undefined,
        selectedSubCategory:undefined,
        selectedCategoryID:-1,
        selectedSubCategoryID:-1
      })
      const {isLoading,docList,categoryList,subCategoryList,categoryGroups,selectedCategory,selectedSubCategory,selectedCategoryID,selectedSubCategoryID}=state;

      const [savedDocs,setSavedDocs]=useState([]);
   
      const [msgState, setMsgState] = useState({
        open: false,
        msg:"" ,
        severity:"info"
      });
     const { open, msg,severity } = msgState;

     const [isSubmitting,setIsSubmitting]=useState(true);
     const [statusData,setStatusData]=useState();
     const [deletedId,setDeletedId]=useState();
     const [tableData,setTableData]=useState();

     const [filesIP, setFilesIP] = useState([])
     const [filesAP, setFilesAP] = useState([])
     const [filesMSME, setFilesMSME] = useState([])
     const [filesOthers, setFilesOthers] = useState([])

     const [docTypeIP,setDocTypeIP]=useState(undefined);
     const [docTypeAP,setDocTypeAP]=useState(undefined);
     const [docTypeMSME,setDocTypeMSME]=useState(undefined);
     const [docTypeOthers,setDocTypeOthers]=useState(undefined);

     useEffect(() => {
        document.title = "KYC & Documents";
         }, []);

     const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
            
        },
        {
            title: 'Document Cateogry',
            dataIndex: 'category',
            key: '2',
            
        },
        {
            title: 'Document Type',
            dataIndex: 'type',
            key: '3',
            
        },
        {
            title: 'Upload Time',
            dataIndex: 'date',
            key: '4',
            
        },
        {
            key: "5",
            title: "Actions",
            render: (record) => {
              return (
                <>
                 
                  <DeleteOutlined
                    onClick={() => {
                      deleteDoc(record.id);
                    }}
                    style={{ color: "red", marginLeft: 12 }}
                  />
                </>
              );
            },
          },
        ];
    


      useEffect(()=>{
        executeAPIStatus(URL.ACTIVATION_STATUS,"GET",null);
        executeAPI(URL.ACTIVATION_DOC_CATEGORIES,"GET",null);
        executeAPIKYC(URL.ACTIVATION_COMPANY_KYC,"GET",null);
    },[])
    /**
     * API response handler  status API 
    */  
    useEffect(() => {
        if(loadedStatus){
        console.log("activation status response",responseDataStatus);
        if(responseDataStatus!=null){
            setStatusData(responseDataStatus);
            // setIsLoading(false);
        }
        else if(errorStatus!==null){
            // setIsLoading(false);
            console.log("Error data=",errorStatus);
            setMsgState({open:true,msg:errorStatus?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        
            //if unauthorized then redirec it to login page
            if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
                console.log("status received =",errorStatus?.response?.status)
                 navigate("/login", { replace: true });
            }
        }
        resetStatus();
        }
        },[loadedStatus,responseDataStatus]);

        /**
         * API response handler  
        */  
        useEffect(() => {
            if(loaded){
            console.log("loaded=true",responseData);
            if(responseData!=null){
                // const cateogriesList = [...new Set( responseData.map(obj => obj.doc_category__id)) ];
               
                const cateogriesList= responseData.filter((a, i) => responseData.findIndex((s) => a.doc_category__id === s.doc_category__id) === i)
                console.log("cateogriesList",cateogriesList);

                  const groups = responseData.reduce((groups, item) => {
                    const group = (groups[item.doc_category__id] || []);
                    group.push(item);
                    groups[item.doc_category__id] = group;
                    return groups;
                  }, {});

                  console.log("group=",groups,groups[1]);
                  setState((prevState) => ({
                    ...prevState,
                    isLoading:false,
                    docList:responseData,
                    categoryList:cateogriesList,
                    categoryGroups:groups
                    }));

                
            }
            else if(error!==null){
                console.log("Error data=",error);
                setState((prevState) => ({
                    ...prevState,
                    isLoading:false,
                    
                    }));
            
            
                //if unauthorized then redirec it to login page
                if(error?.response?.status === 401 || error?.response?.status === 403){
                    console.log("status received =",error?.response?.status)
                    // navigate(from, { replace: true });
                }
            }
            reset();
            }
            },[loaded,responseData]);
     

     useEffect(()=>{
        console.log("tableData=",tableData)
     },[tableData])       
      /**
     * API response handler  for get saved KYC docs
    */  
      useEffect(() => {
        if(loadedKYC){
        console.log("loaded=true",responseDataKYC);
        if(responseDataKYC!=null){
            setSavedDocs(responseDataKYC)
            setTableData(
                responseDataKYC.map(row => ({
                  name: row.name,
                  type:row.sub_category_name,
                  category: row.category_name,
                  date:row.created_date,
                  id: row.id
                }))
              );

            //check if all the docs are present 
            let present=true;
            if(responseDataKYC && responseDataKYC.length==0)
            present=false;
            else{
           
            for(let i=0;i<categoryList.length;i++){
                // let index=categoryList.map(e => e.doc_category__id).indexOf(responseDataKYC[i].doc_category);
                let index=responseDataKYC.map(e => e.doc_category).indexOf(categoryList[i].doc_category__id);
                // console.log("index=",index,responseDataKYC[i].doc_category);
                if(index==-1 && categoryList[i].doc_category__id!=3){
                    present=false;
                    break;
                }

            }


            if(present)
            setIsSubmitting(false)
        }
            
        }
        else if(errorKYC!==null){
            console.log("Error data=",error);
        
        
            //if unauthorized then redirec it to login page
            if(errorKYC?.response?.status === 401 || errorKYC?.response?.status === 403){
                console.log("status received =",errorKYC?.response?.status)
                // navigate(from, { replace: true });
            }
        }
        resetKYC();
        }
        },[loadedKYC,responseDataKYC]);
     
      const handleError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
      }
     

      const handleChangeIP = (newFiles) => {
        setFilesIP(prevFiles => [...prevFiles, ...newFiles])
         
      }
      const handleChangeAP = (newFiles) => {
        setFilesAP(prevFiles => [...prevFiles, ...newFiles])
         
      }
      const handleChangeMSME = (newFiles) => {
        setFilesMSME(prevFiles => [...prevFiles, ...newFiles])
         
      }
      const handleChangeOthers = (newFiles) => {
        setFilesOthers(prevFiles => [...prevFiles, ...newFiles])
         
      }

     useEffect(()=>{
        if(filesIP && filesIP[0]){
            handleUploadFiles(filesIP,1,docTypeIP);
        }
      },[filesIP])

      useEffect(()=>{
        if(filesAP && filesAP[0]){
            handleUploadFiles(filesAP,2,docTypeAP);
        }
      },[filesAP])

      useEffect(()=>{
        if(filesMSME && filesMSME[0]){
            handleUploadFiles(filesMSME,3,docTypeMSME);
        }
      },[filesMSME])

      useEffect(()=>{
        if(filesOthers && filesOthers[0]){
            handleUploadFiles(filesOthers,4,docTypeOthers);
        }
      },[filesOthers])
   
      const handleUploadFiles = (filesList,category,subCategory) => {
       
        if(filesList && filesList[0]){
        let data = new FormData()
        data.append(filesList[0].id, new Blob([filesList[0]], { type: filesList[0].type }), filesList[0].name || 'file')
        

        console.log("formdata=",filesList[0],data);
        let jsonData={
            file:filesList[0],
            category:category,
            sub_category:subCategory
        }
        console.log("Jsondata for upload=",jsonData);
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization':'Token '+ JSON.parse(localStorage.getItem("token"))
            }
        }

        const url=`${process.env.REACT_APP_BASE_URL}${URL.ACTIVATION_COMPANY_KYC}`
        axios.post(url, jsonData,config).then(() => {
            // window.alert(`${files.length} files uploaded succesfully!`)
            setFilesIP([]);
            setFilesAP([]);
            setFilesMSME([]);
            setFilesOthers([]);
            executeAPIKYC(URL.ACTIVATION_COMPANY_KYC,"GET",null);
            setMsgState({open:true,msg:"Document uploaded successfully."
        ,severity:"success"});    
            
         }).catch((err) => {
            // window.alert(`Error uploading files: ${err.message}`)
           
            //if unauthorized then redirect it to login page
            if(err?.response?.status === 401 || err?.response?.status === 403){
                console.log("status received =",err?.response?.status)
                navigate("/login", { replace: true });
}
            setMsgState({open:true,msg:err?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
         })
        }
      }

   
    const handleMenuClick = (e) => {
        message.info('Click on menu item.');
        console.log('click', e);
    };

    

    const onCategoryChange = (event) => {
        // console.log("subcategory=",categoryGroups[event.target.value]);
        setState((prevState) => ({
            ...prevState,
            selectedCategoryID:event.target.value,
            subCategoryList:categoryGroups[event.target.value],
            
            }));

       
    }

    const onSubCategoryChangeIP= (event) => {
       setDocTypeIP(event.target.value);
    }
    const onSubCategoryChangeAP= (event) => {
        setDocTypeAP(event.target.value);
    }
    const onSubCategoryChangeMSME= (event) => {
        setDocTypeMSME(event.target.value);
    }
    const onSubCategoryChangeOthers= (event) => {
        setDocTypeOthers(event.target.value);
    }

 /**
 * autoclosing of the snackbar msg bar 
 */ 
 const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

const deleteDoc=(id)=>{
    // return;
    if(statusData?.status && (statusData?.status==2 || statusData?.status==4))
    return;

    setDeletedId(id);
    executeAPIDelete(`${URL.ACTIVATION_COMPANY_KYC}${id}/`,"DELETE",{})
  }

  //delete api response handler
  useEffect(()=>{
    if(loadedDelete){
      console.log("Delete response ========>",responseDataDelete);
      if(responseDataDelete!=null){
        setMsgState({open:true,msg:"Document deleted successfully",severity:"success"});
        let deletedItem= tableData.filter(item => item.id==deletedId);
        console.log("test Deleted item",deletedItem,tableData);
        let updatedData= tableData.filter(item => item.id!=deletedId);
        setTableData(updatedData);
        setDeletedId(undefined);
        console.log("test",updatedData)
        let present=false;
        if(updatedData && updatedData.length==0)
        present=true;
        else{
       
        for(let i=0;i<categoryList.length;i++){
            // let index=categoryList.map(e => e.doc_category__id).indexOf(responseDataKYC[i].doc_category);
            
            let index=updatedData.map(e => e.category).indexOf(categoryList[i].doc_category__name);
            console.log("test",index,categoryList[i].doc_category__name)
            // console.log("index=",index,responseDataKYC[i].doc_category);
            if(index==-1 && categoryList[i].doc_category__id!=3){
                console.log("test",index,i,categoryList[i].doc_category__name)
                present=true;
                break;
            }

        }

        if(present)
        setIsSubmitting(true)
    }

        // executeAPI(URL.ACTIVATION_DOC_CATEGORIES,"GET",null);
        // let found=false;
        // for(let i=0;i<updatedData.length;i++){
        //     if(deletedItem.category==updatedData[i].category)
        //     found=true;
        // }
        // if(!found)
        // setIsSubmitting(true);
       
       }else if(errorDelete!==null){
       
        setDeletedId(undefined);
        if(errorDelete?.response?.status === 401 || errorDelete?.response?.status === 403){
            console.log("status received =",errorDelete?.response?.status)
            navigate("/login", { replace: true });
}
        setMsgState({open:true,msg:errorDelete?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
      }
      resetDelete();
      
    }

  },[loadedDelete,responseDataDelete])


const goNext=()=>{
    if(statusData.entity_type==1)
    navigate("/activation/bank-details", { replace: true });
    else
    navigate("/activation/address", { replace: true });
}
const goBack=()=>{
    navigate("/activation/company-details", { replace: true });
}
  return (
    <>
     {isLoading?
             <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
             </div>:
    <div style={{ width: '100vw',minHeight:"930px",marginTop:"64px",zIndex:1  }} >

        {/* <Navbar  /> */}
      
        <Row>
        <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={5}>
                    <ACTIVATION_LEFT_PANEL statusData={statusData} index={1}></ACTIVATION_LEFT_PANEL>    
                </Col>
                
                <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={19}>
                
                <div style={{backgroundColor:"#fff",height:"calc(100vh - 64px)"}}>
                <div style={{ display:"flex", justifyContent: 'flex-start',backgroundColor:"#fff",marginTop:"0px",paddingBottom:"0px" }}>
                    
                <div style={{ width: "calc(100% - 20px)",paddingLeft:"20px",paddingRight:"20px" }} >
                            <Box sx={{mt:4,display:'flex',flexDirection:'column'}}>
                                <Typography  variant="titleMedium" sx={{fontSize:"24px"}}>
                                KYC & Documents:
                                </Typography>
                                <Typography  variant="titleMedium" sx={{fontSize:"14px"}}>
                                (Please upload at least one document for Identity Proof, Address Proof and Others)
                                </Typography>
                            </Box>

                        
                   
                             <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:1,mb:2}}>
                  

                    <Grid item mobile={10} tablet={12} display="flex" flexDirection='row'>
                    <Box sx={{mr:2,alignItems:'center',width:"180px" }}>
                        <Typography sx={{fontSize:"16px"}}>Identity Proof:</Typography>
                        </Box>
                    <Box sx={{ width:"300px"}}>
                            <FormControl fullWidth size="small" required disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)} >
                            <InputLabel sx={{backgroundColor: '#fff'}}  id="demo-select-small-label">Document Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                required
                                label="Document Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                onChange={(event) => onSubCategoryChangeIP(event)}
                            >
                                {categoryGroups[1].map((item,index) => (
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                ))}        
                                
                               
                            </Select>
                        </FormControl>
                        </Box>
                        <Box display="flex" >
                                <Files
                                    className='files-dropzone'
                                    onChange={handleChangeIP}
                                    onError={handleError}
                                    accepts={['image/*', '.pdf']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    >
                                       
                                 <Tooltip title={"Upload "}>    
                                <Button  disabled={docTypeIP==undefined ||(statusData?.status && (statusData?.status==2||statusData?.status==4))}  type="text" style = {{ fontSize:"28px", marginLeft: '20px', boxShadow: '0px 0px 6px rgba(0.2, 0.2, 0.2, 0.8)', }}><UploadOutlined style={{}}/></Button> 
                               </Tooltip>
                                   
                                </Files>
                            </Box>
                       
                        </Grid>

                        <Grid item mobile={10} tablet={12} display="flex" flexDirection='row'>
                    <Box sx={{mr:2,alignItems:'center',width:"180px" }}>
                        <Typography sx={{fontSize:"16px"}}>Address Proof:</Typography>
                        </Box>
                    <Box sx={{ width:"300px"}}>
                            <FormControl fullWidth size="small" required disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)} >
                            <InputLabel sx={{backgroundColor: '#fff'}}  id="demo-select-small-label">Document Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                required
                                label="Document Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                onChange={(event) => onSubCategoryChangeAP(event)}
                            >
                                {categoryGroups[2].map((item,index) => (
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                ))}        
                                
                               
                            </Select>
                        </FormControl>
                        </Box>
                        <Box display="flex" >
                                <Files
                                    className='files-dropzone'
                                    onChange={handleChangeAP}
                                    onError={handleError}
                                    accepts={['image/*', '.pdf']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    >
                                       
                                 <Tooltip title={"Upload "}>    
                                <Button  disabled={docTypeAP==undefined ||(statusData?.status && (statusData?.status==2||statusData?.status==4))}  type="text" style = {{ fontSize:"28px", marginLeft: '20px', boxShadow: '0px 0px 6px rgba(0.2, 0.2, 0.2, 0.8)', }}><UploadOutlined style={{}}/></Button> 
                               </Tooltip>
                                   
                                </Files>
                            </Box>
                       
                        </Grid>
                    
                        <Grid item mobile={10} tablet={12} display="flex" flexDirection='row'>
                    <Box sx={{mr:2,alignItems:'center',width:"180px" }}>
                        <Typography sx={{fontSize:"16px"}}>MSME Certification:</Typography>
                        </Box>
                    <Box sx={{ width:"300px"}}>
                            <FormControl fullWidth size="small"  disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)} >
                            <InputLabel sx={{backgroundColor: '#fff'}}  id="demo-select-small-label">Document Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                // required
                                label="Document Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                onChange={(event) => onSubCategoryChangeMSME(event)}
                            >
                                {categoryGroups[3].map((item,index) => (
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                ))}        
                                
                               
                            </Select>
                        </FormControl>
                        </Box>
                        <Box display="flex" >
                                <Files
                                    className='files-dropzone'
                                    onChange={handleChangeMSME}
                                    onError={handleError}
                                    accepts={['image/*', '.pdf']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    >
                                       
                                 <Tooltip title={"Upload "}>    
                                <Button  disabled={docTypeMSME==undefined ||(statusData?.status && (statusData?.status==2||statusData?.status==4))}  type="text" style = {{ fontSize:"28px", marginLeft: '20px', boxShadow: '0px 0px 6px rgba(0.2, 0.2, 0.2, 0.8)', }}><UploadOutlined style={{}}/></Button> 
                               </Tooltip>
                                   
                                </Files>
                            </Box>
                       
                        </Grid>

                        <Grid item mobile={10} tablet={12} display="flex" flexDirection='row'>
                    <Box sx={{mr:2,alignItems:'center',width:"180px" }}>
                        <Typography sx={{fontSize:"16px"}}>Others:</Typography>
                        </Box>
                    <Box sx={{ width:"300px"}}>
                            <FormControl fullWidth size="small" required disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)} >
                            <InputLabel sx={{backgroundColor: '#fff'}}  id="demo-select-small-label">Document Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                required
                                label="Document Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                onChange={(event) => onSubCategoryChangeOthers(event)}
                            >
                                {categoryGroups[4].map((item,index) => (
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                ))}        
                                
                               
                            </Select>
                        </FormControl>
                        </Box>
                        <Box display="flex" >
                                <Files
                                    className='files-dropzone'
                                    onChange={handleChangeOthers}
                                    onError={handleError}
                                    accepts={['image/*', '.pdf']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    >
                                       
                                 <Tooltip title={"Upload "}>    
                                <Button  disabled={docTypeOthers==undefined ||(statusData?.status && (statusData?.status==2||statusData?.status==4))}  type="text" style = {{ fontSize:"28px", marginLeft: '20px', boxShadow: '0px 0px 6px rgba(0.2, 0.2, 0.2, 0.8)', }}><UploadOutlined style={{}}/></Button> 
                               </Tooltip>
                                   
                                </Files>
                            </Box>
                       
                        </Grid>

                     


                        
                   
                            </Grid>
                        </Box>

                        
                  
                       
                         
                        </div>

                    </div>

                    <br />
                    <div style ={{ width: "calc(100% - 20px)",marginLeft:"20px"}} >
                        {/* <h3 style={{ fontSize: '20px', }}>Attached Files</h3> */}
                        <Typography sx={{mt:0,fontSize:20,mb:2}}>Uploaded Documents:</Typography>
                        <Table 
                            scroll={{ y: 320 }}
                            dataSource={tableData} 
                            columns={columns} 
                            bordered 
                            pagination={false}
                            // style = {{ border: '2px solid black'}}
                            />
                    </div>

                    <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'space-between',alignItems:"center",ml:2,mr:2}}> 
                    <Button onClick={()=>goBack()}  sx={{mb:2, mt:4,height:"50px",width:"160px",mr:2}} variant="contained" disabled={(statusData?.status && (statusData?.status==2 || statusData?.status==4))}
                      >{"BACK"}</Button>
           <Button onClick={()=>goNext()}  sx={{mb:2, mt:4,height:"50px",width:"160px"}} variant="contained" disabled={isSubmitting||(statusData?.status && (statusData?.status==2|| statusData?.status==4))}
                      >{isSubmitting ? "SAVE & NEXT" : "SAVE & NEXT"}</Button>
                      </Grid>
           

                   

                    
                    
              <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
              {statusData?.status && (statusData?.status==2|| statusData?.status==4)?
                 <Typography  variant="titleMedium" sx={{fontSize:"16px"}}>
                    Account verification is under review.
                    </Typography>:
                    <></>

                 }  
                 </Grid>

                    
                  
            
                {/* </div> */}
                </div>
            </Col>
        </Row>
        
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
    </div>
        }
    </>
  )
}

export default KYC_Form