import React from 'react'
import { Link,Grid,Box} from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import { ToolbarFixed } from '../component.js';


/**
 * 
 * @returns Fixed header at the top with website link and login or register button
 */

function HeaderFixed() {
  return (
    <div>
        <ToolbarFixed >
        <Grid  container >
            {/* <Grid item mobile={3} tablet={6} laptop={6} >
              <Box display="flex"  alignItems={"center"}>
                <Link href="https://www.Gravitas Factor.com/" variant="secondaryLink"  target="_blank" rel="noopener noreferrer">Website</Link>
              </Box>
            </Grid> */}
            <Grid item mobile={12} tablet={12} laptop={12} >
                <Box display="flex" justifyContent="flex-end" alignItems={"center"}>
                  <LocalPhoneIcon sx={{ color: 'iconColor.main' }} fontSize={"12px"}/>
                  <Link href='tel:(+91) 9014061003'   variant="secondaryLink" sx={{mr:2}}>+91 90140 61003</Link>
                  <MailIcon sx={{ color: 'iconColor.main' }} fontSize={"12px"}/>
                  <Link  href="mailto:contact@gravitasfactor.com" variant="secondaryLink"  sx={{mr:2,marginLeft:"2px"}}> Email Us</Link>
               </Box>
            </Grid>
        
          </Grid>
        
        </ToolbarFixed>
      
    </div>
  )
}

export default HeaderFixed


