import { Grid } from '@mui/material';
import React from 'react'
// import DashboardHeader from '../../components/AppHeader/header'
import { CONSTANTS } from '../../common/utility/constant';
import {useState} from "react";

function MarketProfile() {
  const  [CurrentComponent,setCurrentComponent]=useState(null);

  const setRoute=(route,component)=>{
    console.log("Route at main=",route);
    setCurrentComponent(component);
  }
 
  return (
        <>
        {/* <DashboardHeader setCurrentRoute={setRoute}/> */}
          <div style={{paddingTop:`${CONSTANTS.DASHBOARD_HEADER_HEIGHT}px`}}>
            <Grid container component="main" sx={{height: `calc(100vh - ${CONSTANTS.DASHBOARD_HEADER_HEIGHT}px)` }}>  
              {CurrentComponent!=null?CurrentComponent:<></>}
            </Grid>
          </div>
        </>
  )
}

export default MarketProfile
