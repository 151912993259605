import { axiosPrivate } from "../axios";
import { useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";

/**
 * this hook will be used to call api once the user is on dashboard after login/registeration
 * @returns axios instance with interceptor which will handle token expiry as well
 */
const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const token= JSON.parse(localStorage.getItem("token"));

    useEffect(() => {
        //request interceptor: will add the Authorization header if not present
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `TOKEN ${token}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );
        
         //response interceptor: it will try to get the new token if the access token has expired
         //TODO: need to check the status code from the in case of expired token.
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 ||error?.response?.status === 403 && !prevRequest?.sent) {
                    console.log("token error =",error)
                    prevRequest.sent = true;
                    try {
                        // const newAccessToken = await refresh();
                        // console.log("Refresh token reponse =",newAccessToken)
                        // prevRequest.headers['Authorization'] = `${newAccessToken}`;
                        // return axiosPrivate(prevRequest);
                    }catch (error) {
                        console.error("error in useAxiosPrivate refresh call 1=",error);
                        if(error?.response?.status === 400 ||error?.response?.status === 403){
                            error.response.status=401;
                        }
                      
                        return Promise.reject(error);
                        
                    }
                    
                }
                // console.log("Reject =",error)
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;