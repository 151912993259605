import * as React from 'react';
import { useState,useRef,useEffect } from 'react';

import { Col, Row, Timeline, Input, InputNumber,ConfigProvider,Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { Formik, Form, Field,ErrorMessage} from 'formik';
import {IconButton,Alert, Snackbar,Button,CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateCompanyDetailsForm } from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import '../dashboard/styles/styles.scss';
import ACTIVATION_LEFT_PANEL from './components/activation_left_panel';
import { FormHelperText } from '@mui/material';








const { Option } = Select;
let initialValues = {
    entity_name:'',
    entity_type:"",
    gst:"",                            //1 & 2, where 1 == seller & 2 == buyer
    email: '',
    company_registration_id: '',
    company_pan_card:'',
    mobile:'',
    date_of_incorporation:'',           //date dd/mm/yy
  };
  
const Company_Details_Page = () => {

    const [entityType, setEntityType] = useState();
    const [isLoading,setIsLoading]=useState(true);
    // const [form] = Form.useForm();
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelSave,responseDataSave,errorSave,loadedSave,resetSave,executeAPISave] = useAxiosWithAuth();
    const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const formikRef = useRef();
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
   const { open, msg,severity } = msgState;
   const [companyDetails,setCompanyDetails]=useState(initialValues);
   const [statusData,setStatusData]=useState();
   const [selectError,setSelectError]=useState(false);
   
   useEffect(() => {
    document.title = "Company Details";
     }, []);

    const onEntityChange = (event) => {
        setEntityType(event.target.value);
        formikRef.current.setFieldValue("entity_type",event.target.value);
    }


    useEffect(()=>{
        executeAPIStatus(URL.ACTIVATION_STATUS,"GET",null);
        executeAPI(URL.ACTIVATION_COMPANY_DETAILS,"GET",null);
        // executeAPIStatus(URL.ACTIVATION_DOC_CATEGORIES,"GET",null);
    },[])

     /**
     * API response handler  status API 
    */  
     useEffect(() => {
        if(loadedStatus){
        console.log("activation status response",responseDataStatus);
        if(responseDataStatus!=null){
            // responseDataStatus.status=3;
            setStatusData(responseDataStatus);
            // setIsLoading(false);
        }
        else if(errorStatus!==null){
            // setIsLoading(false);
            console.log("Error data=",errorStatus);
            setMsgState({open:true,msg:errorStatus?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        
            //if unauthorized then redirec it to login page
            if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
                console.log("status received =",errorStatus?.response?.status)
                 navigate("/login", { replace: true });
            }
        }
        resetStatus();
        }
        },[loadedStatus,responseDataStatus]);

    /**
     * API response handler  
    */  
    useEffect(() => {
        if(loaded){
        console.log("loaded=true",responseData);
       
        if(responseData!=null) {
        if (responseData?.entity_type){
            setCompanyDetails(responseData);
            // initialValues=JSON.stringify(responseData);
            setEntityType(responseData.entity_type);
        }
            setIsLoading(false);  
            
        }
        else if(error!==null){
            console.log("Error data=",error);
        
        
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                navigate("/login", { replace: true });
            }
            setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});

        }
        reset();
        }
        },[loaded,responseData]);

     /**
    * API caller  
    */  
  const onSubmit = (values, props) => {
    setSelectError(false);
    if(entityType==undefined){
        setSelectError(true);
    }
    console.log("onSubmit=",JSON.stringify(values));
    // setAPIError(undefined);
    executeAPISave(URL.ACTIVATION_COMPANY_DETAILS,"POST",JSON.stringify(values));    
   }

   /**
     * API response handler Save detailes
    */  
   useEffect(() => {
    if(loadedSave){
    console.log("Save data response",responseDataSave);
    if(responseDataSave!=null){
        formikRef?.current.setSubmitting(false);
        setMsgState({open:true,msg:"Company details saved successfully.",severity:"success"});
        setTimeout(()=>{
            navigate("/activation/kyc", { replace: true });
        },2000);
       
        
    }
    else if(errorSave!==null){
        formikRef?.current.setSubmitting(false);
        console.log("Error data=",errorSave);
        //if unauthorized then redirect it to login page
        if(errorSave?.response?.status === 401 || errorSave?.response?.status === 403){
            console.log("status received =",errorSave?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
    }
    resetSave();
    }
    },[loadedSave,responseDataSave]);
 /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

    return (
        <div style={{ width: '100vw',marginTop:"64px"  }} >
            {!isLoading?
            <>
            {/* <Navbar /> */}

            <Row>
                <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={5}>
                    <ACTIVATION_LEFT_PANEL statusData={statusData} index={0}></ACTIVATION_LEFT_PANEL>    
                </Col>
                
                <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={19}>

                    {/* content for column 2 goes here */}
                    <div style={{ height: `calc(100vh - 64px)`, display: 'flex', direction: 'verticle', alignItems: 'flex-start', justifyContent: 'center',backgroundColor:"#fff" }}>

                        <div style={{ width: "calc(100% - 20px)",paddingLeft:"20px",paddingRight:"20px" }} >
                            <Box sx={{mt:6}}>
                                <Typography  variant="titleMedium" sx={{fontSize:"24px"}}>
                                    Company Details:
                                </Typography>
                            </Box>
                        <Formik enableReinitialize={true} innerRef={formikRef} initialValues={companyDetails} onSubmit={onSubmit} validate={validateCompanyDetailsForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form>
                 
          
                  <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:2,mb:3}}>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="entity_name"
                        name="entity_name"
                        type="text"
                        label="Entity Name"
                        disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}
                        />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Your company's name.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                    </Grid>
                    
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <FormControl fullWidth size="small" required disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}>
                        <InputLabel  sx={{backgroundColor: '#fff'}} id="demo-select-small-label">Entity Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                // required
                                value={entityType}
                                label="Entity Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                // onChange={onEntityChange}
                                onChange={(event) => onEntityChange(event)}
                            >
                                <MenuItem value={1}>Seller</MenuItem>
                                <MenuItem value={2}>Buyer</MenuItem>
                                {/* <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                            {/* {selectError && <FormHelperText>Please select Entity Type</FormHelperText>} */}
       
                        </FormControl>
                        <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>How are you registering with us.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                    
                    </Grid>
                    
                </Grid>
                </Box>

                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="gst"
                        id="gst"
                        type="text"
                        label="GSTIN Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}
                    />
                     <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>GSTIN of your company.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="date_of_incorporation"
                        name="date_of_incorporation"
                        type="text"
                        label="Date of Incorporation"
                        placeholder="DD/MM/YYYY"
                        required
                        disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}
                        />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Your company's date of registration.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                  </Grid> 
                   
              </Grid>
              </Box>

              <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="company_registration_id"
                        id="company_registration_id"
                        type="text"
                        label="Registration/CIN No."
                        required
                        disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}
                    />
                     <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Your company's CIN Number.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="company_pan_card"
                        name="company_pan_card"
                        type="text"
                        label="PAN Number"
                        // placeholder="dd/mm/YYYY"
                        required
                        disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}
                        />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Your company's PAN Number.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                  </Grid> 
                   
              </Grid>
              </Box>

                
                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="mobile"
                        id="mobile"
                        type="text"
                        label="Mobile Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}
                    />
                     <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Company's contact number.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Box display="flex" >
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="email"
                        name="email"
                        type="text"
                        label="Email Address"
                        required
                        disabled={statusData?.status && (statusData?.status==2 || statusData?.status==4)}
                        />
                         <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Company's contact Email Address.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                  </Grid> 
                   
              </Grid>
              </Box>


              {/* <Grid item mobile={12} tablet={12}> 
                <Button type='submit'  sx={{mb:2,mr:1, mt:1,height:"50px"}} variant="contained" disabled={isSubmitting}
                      fullWidth>{isSubmitting ? "Loading" : "SAVE & NEXT"}</Button>
              </Grid> */}
               <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
                <Button type='submit'  sx={{mb:2,mr:1, mt:4,height:"50px",width:"240px"}} variant="contained" disabled={isSubmitting ||(statusData?.status && (statusData?.status==2 || statusData?.status==4))}
                      >{isSubmitting ? "Loading" : "SAVE & NEXT"}</Button>
              </Grid>
              <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
              {statusData?.status &&statusData?.status==2?
                 <Typography  variant="titleMedium" sx={{fontSize:"16px"}}>
                    Account verification is under review.
                    </Typography>:
                    <></>

                 }  
                 </Grid>

          </Form>
          )}
        </Formik>
                        
                        </div>

                    </div>
                </Col>
            </Row>
            <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>:
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/></div>}
        </div>
    )
}

export default Company_Details_Page