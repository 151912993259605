export const URL = {
    REGISTER: "auth/signup/",
    ACTIVATE_USER: "auth/verify-email/",
    LOGIN: "auth/signin/",
    FORGOT_PASSWORD: "auth/forgot-password-link/",
    REFRESH: "authentication/refresh",
    MPCHART: "chart/mp",
    MPCHART_STATUS: "chart/status",
    RESET_PASSWORD: "auth/change-password/",
    UPDATE_PASSWORD: "user/update-password",
    UPDATE_USER: "user/update-user",
    RESEND_ACTIVATION_EMAIL: "authentication/resend-activation-email",
    RESEND_FORGOT_PASSWORD_EMAIL: "authentication/resend-forgot-password-email",
    
    LOGOUT: "auth/logout/",
    USER_PROFILE:"auth/profile/",
    
    // Private apis
    ACTIVATION_STATUS:"company/status/",
    ACTIVATION_COMPANY_DETAILS:"company/details/",
    ACTIVATION_COMPANY_KYC:"company/kyc/",
    ACTIVATION_BANK_DETAILS:"company/bank/",
    ACTIVATION_ADDRESS_DETAILS:"company/address/",
    ACTIVATION_DOC_CATEGORIES:"company/document/categories/",
    ACTIVATION_VERIFY:"company/submit-for-verification/",
    INVOICE_DISCOUNTING:"invoice/discounting/",
    DASHBOARD_DATA:"invoice/discounting/dashboard/",
    DASHBOARD_COMBO_DATA:"invoice/discounting/year/range/"
  


  };
  