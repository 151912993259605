import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const style = {
  position: 'absolute',
  top: '10%',
  left: '10%',
  height: "80%",
  //   transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
};

export default function TermsOfUseModal(props) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.closeModal();
    setOpen(false)
  };

  // console.log(props);

  return (
    <div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <CancelOutlinedIcon
            onClick={() => handleClose()}
            sx={{
              ml: 0, mt: 0, top: '10%',
              left: '10%', height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
            }}
          />
          {props.privacyPolicy
          ?
            <Box sx={style}>
              
              <Typography id="modal-modal-description" sx={{ ml:4, mr:4, mt: 4, fontWeight: 600 }} variant="h5">
                Privacy Policy
              </Typography>
              <Typography id="modal-modal-description" sx={{ ml:4, mr:4, mt: 4 }}>
                To ensure transparency and protect your privacy, we have created the following privacy policy:
                <br /><br />
                {/* <span style={{ fontWeight: 700 }}>Information we collect:</span> We collect the data you provide us when using our service, including your name, email address, and other personal information you choose to share.
                We may also have non-personal data, such as your IP address, device type, and browser information.
                <br /><br />
                <span style={{ fontWeight: 700 }}>Use of information:</span> We use your personal information to provide our service to you, including
                charting and analytical services. We may also use your email address to send you promotional
                materials and updates about our service. Non-personal information is used to improve our service and for analytical purposes.
                <br /><br />
                <span style={{ fontWeight: 700 }}>Sharing of information:</span> We do not share your personal information with third parties unless required by law.
                <br /><br />
                <span style={{ fontWeight: 700 }}>Security:</span> We take reasonable measures to protect your personal information from unauthorized
                access, disclosure, or destruction.
                <br /><br />
                <span style={{ fontWeight: 700 }}>Data retention:</span> We will retain your personal information for as long as necessary to provide our
                service to you, or as required by law. Non-personal information may be retained indefinitely.
                <br /><br />
                <span style={{ fontWeight: 700 }}>Your rights:</span> You have the right to access, correct, or delete your personal information. You may
                also unsubscribe from our promotional emails at any time.
                <br /><br />
                <span style={{ fontWeight: 700 }}>Changes to policy:</span> We may make changes to this privacy policy from time to time. If we make
                any changes, we will notify you by posting a notice on our website.
                <br /><br />
                If you have any questions or concerns about our privacy policy, please contact us at <Link href="mailto:support@Gravitas Factor.com">support@Gravitas Factor.com</Link>

                <br /><br />
                Notwithstanding the foregoing, we will not use your credit card or other personal payment information for any purpose other than to complete your ordering transactions,
                and we will not maintain records of such credit card or ordering information after the order has been fulfilled and full payment received. */}
              </Typography>
            </Box>
          : 
            props.contactInfo
            ?
              <Box sx={style}>
                
                <Typography id="modal-modal-description" sx={{ ml:4, mr:4, mt: 4, fontWeight: 600 }} variant="h5">
                  Contact Details
                </Typography>
                <Typography id="modal-modal-description" sx={{ ml:4, mr:4, mt: 4 }}>
                  <span style={{ fontWeight: 700 }}>Contact number:</span> 9284331270
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Email address:</span> <u>charts@Gravitas Factor.com</u> or <u>mplite@Gravitas Factor.com</u>
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Address:</span>
                  <Typography id="modal-modal-description" sx={{ mt: -3, ml: 10 }}>
                  Gravitas Factor <br />
                  Villa No 18, <br />
                  Ground floor, <br />
                  Gera's Isle Royale <br />
                  Paud Road <br />
                  Bavdhan Khurd <br />
                  Pune 411021 <br />
                  </Typography>
                  <br /><br />
                </Typography>
              </Box>
            :
              props.refundPolicy
              ?
                <Box sx={style}>
                  
                  <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h5">
                    Cancellations and Refunds for Products
                    <br />
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    We are committed to providing you with the highest quality service possible. However, please be advised that our charting service is a digital product and as such, we do not offer refunds once the service has been purchased and access has been granted.
                    <br />
                    <br />
                    In order to ensure your satisfaction with our charting service, we recommend that you carefully review all product information, including the product description, before making a purchase. If you have any questions or concerns about the product, please feel free to contact us prior to making a purchase.
                    <br />
                    <br />
                    By purchasing our charting service, you acknowledge and agree that you understand that there will be no refunds for any reason, including but not limited to:
                    <br />
                    &nbsp;&nbsp;-Dissatisfaction with the product or its performance
                    <br />
                    &nbsp;&nbsp;-Technical difficulties or issues with accessing the service
                    <br />
                    &nbsp;&nbsp;-Change of mind or circumstances
                    <br />
                    &nbsp;&nbsp;-Inability to use the product due to technical requirements or compatibility issues
                    <br />
                    <br />
                    If you have any technical issues with accessing the charting service, please contact us immediately so that we can assist you in resolving the issue. We provide you with the best possible experience and will work to resolve any technical difficulties in a timely manner.
                    <br />
                    <br />
                    Please note that our no refund policy is a fundamental part of our charting service and is non-negotiable. By purchasing and accessing the charting service, you agree to be bound by this policy.
                    <br />
                    <br />
                    If you have any questions or concerns about our no refund policy, please feel free to contact us.

                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 700 }} variant="h5">
                    No Refund Policy
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Gravitas Factor provides a comprehensive charting service product and ensures the highest quality of service to our clients.
                    However, please note that due to the nature of our services, we have a strict no refund policy.
                    All sales are final, and no refunds will be issued for any reason, including but not limited to dissatisfaction with the product, changes in personal circumstances, or technical difficulties.
                    <br /><br />
                    By purchasing our charting service product, you acknowledge and agree to our no refund policy and that your purchase is a one-time transaction and non-refundable.
                    Gravitas Factor is not responsible for any inconvenience or loss that may result from using our services.
                    <br /><br />
                    If you have any questions or concerns about our charting service product or no refund policy, please contact us before making a purchase.
                    Our customer service team will be happy to assist you and answer any questions you may have.

                  </Typography>
                </Box>
              :
              props.aboutUs
              ?
                <Box sx={style}>
                  
                  <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h5">
                    About Us
                    <br />
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  They say in the financial markets Money meets Experience and then Experience meets Money. <br />
When the man with the money, meets the man with the experience, the man with the experience ends up with the Money and the man with the money gets the experience.
Don’t let this happen to you.
<br /><br />
Experience is indeed the biggest teacher. Our effort at Gravitas Factor is to shorten your learning curve by supplying our experience to help you not go through the mistakes a Trader does when he first starts with Trading the markets.
We have been with the Marketprofile  since 2009, every single day, talking about the market and viewing it through the prism of Market Profile and OrderFlow.
<br /><br />

We are amongst the earliest adopters of Market Profile and OrderFlow here in India. This is today, our domain. We have never shied away from the use of technology or hesitated to use our experience in these markets to clear an edge for us in an increasingly competitive Trading world. We realized that the markets, especially in the shorter form, have changed a lot of post-2007 and today’s algo driven markets require more competitive strategies.
<br /><br />
Welcome to Gravitas Factor Charts ! We are a team of experienced traders and financial analysts who are passionate about helping traders make informed decisions in the market.
Our focus is on providing charting and analytical tools for the Marketprofile and Options Markets, which can be complex and difficult to navigate without the right information. We believe that with the right tools and education, traders can make better decisions and improve their chances of success.
Gravitas Factor Charts offers a comprehensive suite of tools designed to help traders analyze and interpret market data in real-time. Our platform is user-friendly and intuitive, making it easy for traders of all experience levels to access and use our tools.
<br /><br />
Everything is perfect in hindsight and if only the markets could reward us for the great hindsight!! But the truth has always been about how you respond to the markets in real-time, ‘cos the markets and the opportunity do not wait for anyone. 
<br /><br />

Our domain has been always MarketProfile and OrderFlow and there is a huge difference between working with Gravitas Factor and Others. Our charts will show you We will help you connect the dots. Our team is dedicated to staying up-to-date on the latest trends and developments in the market, and we are constantly updating our platform to ensure that our clients have access to the most advanced tools and features available.
Thank you for choosing Gravitas Factor Charts , and we look forward to helping you achieve your trading goals!
<br />
                  </Typography>
                </Box>
              :
                <Box sx={style}>

                <Typography id="modal-modal-title" variant="h4">
                  Terms of Use
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  The content and all trademarks, service marks, logos, pictures, slogans, written material and other content and marks used are the property of Gravitas Factor Charts.
                  <br />
                  <br />
                  Gravitas Factor Charts respects the intellectual property of others, and we ask you to do the same.
                  Failure to comply with copyright, trademark and other laws, subject you to criminal and or civil penalties.
                  <br />
                  <br />
                  Hyper-linking or uploading pictures or other content at Gravitas Factor Charts is prohibited.
                  The following User Agreement ("Agreement") governs the use of the Gravitas Factor Charts online service ("Service"),
                  including participation in its online bulletin boards and chats (if any), and access to the various content on the Service, as provided Gravitas Factor Charts ("Owner").
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 700 }} variant="subtitle1">
                  Please read this Agreement carefully.
                  <br />

                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  By your use of and/or registration on any aspect of the Service, you are indicating your agreement to comply with the terms of this Agreement.
                  If you do not agree with these terms, you are not authorized to use the Service.
                  These terms may be modified from time to time; the date of the most recent revisions will appear on this page.
                  Continued access of the Service by you will constitute your acceptance of any changes or revisions to the Agreement.
                  Additional terms of use may appear in other places in the Service and shall be incorporated herein and made a part of this Agreement (to the extent of any conflict, the terms of this Agreement shall control).
                  Your failure to follow these terms, whether listed below or posted at various points in the Service, may result in suspension or termination of your access to the Service, without notice, in addition to Owner's other remedies as may be allowed by law.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Ownership
                  <br />
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  The Content and all trademarks, service marks, logos, slogans, and other marks used in the Service are the property of their respective owners,
                  and you are not granted rights in them other than as specifically set forth herein. "Gravitas Factor Charts" is a trademark of the Owner.
                  Your failure to comply with these terms could be a violation of copyright, trademark and other laws, and subject you to criminal and/or civil penalties.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Disclaimer
                  <br />
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  The content and material presented for view or sale on this website is for educational and informational purposes only.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="subtitle1">
                  Cancellations and Refunds for Products
                  <br />
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  We are committed to providing you with the highest quality service possible. However, please be advised that our charting service is a digital product and as such, we do not offer refunds once the service has been purchased and access has been granted.
                  <br />
                  <br />
                  In order to ensure your satisfaction with our charting service, we recommend that you carefully review all product information, including the product description, before making a purchase. If you have any questions or concerns about the product, please feel free to contact us prior to making a purchase.
                  <br />
                  <br />
                  By purchasing our charting service, you acknowledge and agree that you understand that there will be no refunds for any reason, including but not limited to:
                  <br />
                  &nbsp;&nbsp;-Dissatisfaction with the product or its performance
                  <br />
                  &nbsp;&nbsp;-Technical difficulties or issues with accessing the service
                  <br />
                  &nbsp;&nbsp;-Change of mind or circumstances
                  <br />
                  &nbsp;&nbsp;-Inability to use the product due to technical requirements or compatibility issues
                  <br />
                  <br />
                  If you have any technical issues with accessing the charting service, please contact us immediately so that we can assist you in resolving the issue. We provide you with the best possible experience and will work to resolve any technical difficulties in a timely manner.
                  <br />
                  <br />
                  Please note that our no refund policy is a fundamental part of our charting service and is non-negotiable. By purchasing and accessing the charting service, you agree to be bound by this policy.
                  <br />
                  <br />
                  If you have any questions or concerns about our no refund policy, please feel free to contact us.

                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="subtitle1">
                  License to Use Service
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  You are granted a nonexclusive, non-transferable license to use the Service to view, read, listen to, access, copy and privately perform and display
                  the information, text, photos, illustrations, recordings, and other content ("Content") that is posted on or uploaded to the portions of the Service to which you are granted access;
                  and to access the various services provided in the Service and to use them in accordance with these terms and other terms as may be posted throughout the Service.
                  Notwithstanding the foregoing, you shall have no rights to copy any of the Content for public distribution, transmission, display, performance, archiving, sublicense, rent, lease,
                  or further use or distribution or for the creation of derivative works other than as specifically allowed herein.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, fontWeight: 600 }} variant="subtitle1">
                  Limitations of Rights to Use Service
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  The Service is not intended for users under the age of 18, and Owner does not knowingly collect personally identifiable information from users under the age of 18.
                  Such users are expressly prohibited from submitting their personally identifiable information to us, and from using our forums and chat areas;
                  any information submitted by such users will not knowingly be used, posted, or retained by us.
                  <br /><br />
                  You agree not to use any obscene, indecent, or offensive language or to place on the Service any material that is defamatory, libellous, abusive, harassing, hateful, pornographic,
                  illegal, obscene, or otherwise offensive (in the sole discretion of Owner).
                  Further, you may not place on the Service any material that is encrypted, constitutes junk mail or unauthorized advertising, invades anyone's privacy,
                  or encourages conduct that would constitute a criminal offense, give rise to civil liability, or that otherwise violates any local, state, national or international law or regulation.
                  You agree to use the Service only for lawful purposes and you acknowledge that your failure to do so may subject you to civil and criminal liability.
                  You are responsible for ensuring that any material you provide to the Service or post on Service's bulletin board or forum, including but not limited to text, photographs and sound,
                  does not violate the copyright, trademark, trade secret or any other personal or proprietary rights of any third party or is posted with the permission of the owner(s) of such rights.
                  You agree to provide to Owner, upon request, copies of all releases from third parties for the use of their content on the Service.
                  You agree not to disrupt, modify or interfere with the Service or its associated software, hardware and/or servers in any way, and you agree not to impede or interfere with others' use of the Service.
                  You further agree not to alter or tamper with any information or materials on or associated with the Service.
                  Other than connecting to Owner's servers by https requests using a Web browser, you may not attempt to gain access to Owner's servers by any means
                  including, without limitation, by using administrator passwords or by masquerading as an administrator while using the Service or otherwise.
                  You acknowledge that Owner has not reviewed and does not endorse the content of all sites linked to or from this Service and is not responsible for the content
                  or actions of any other sites linked to or from this Service.
                  Your use of links from the Service to any other service or site is at your sole risk.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="subtitle1">
                  Materials Provided to Service
                </Typography>

                <br />
                For information regarding use of the material and information you supply or communicate with the Service, please see our Privacy Policy.


                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Privacy Policy
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  To ensure transparency and protect your privacy, we have created the following privacy policy:
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Information we collect:</span> We collect the data you provide us when using our service, including your name, email address, and other personal information you choose to share.
                  We may also have non-personal data, such as your IP address, device type, and browser information.
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Use of information:</span> We use your personal information to provide our service to you, including
                  charting and analytical services. We may also use your email address to send you promotional
                  materials and updates about our service. Non-personal information is used to improve our service and for analytical purposes.
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Sharing of information:</span> We do not share your personal information with third parties unless required by law.
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Security:</span> We take reasonable measures to protect your personal information from unauthorized
                  access, disclosure, or destruction.
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Data retention:</span> We will retain your personal information for as long as necessary to provide our
                  service to you, or as required by law. Non-personal information may be retained indefinitely.
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Your rights:</span> You have the right to access, correct, or delete your personal information. You may
                  also unsubscribe from our promotional emails at any time.
                  <br /><br />
                  <span style={{ fontWeight: 700 }}>Changes to policy:</span> We may make changes to this privacy policy from time to time. If we make
                  any changes, we will notify you by posting a notice on our website.
                  <br /><br />
                  If you have any questions or concerns about our privacy policy, please contact us at <Link href="mailto:support@Gravitas Factor.com">support@Gravitas Factor.com</Link>

                  <br /><br />
                  Notwithstanding the foregoing, we will not use your credit card or other personal payment information for any purpose other than to complete your ordering transactions,
                  and we will not maintain records of such credit card or ordering information after the order has been fulfilled and full payment received.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Edits to Content
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Owner reserves the right, but undertakes no duty, to review, edit, move or delete any materials, information, postings or Content provided for display or placed on the Service
                  or its bulletin boards, in its sole discretion, without notice. Owner shall have the right to change the Service or your access to the Service without notice or liability.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Copyright Infringement
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Owner respects the intellectual property of others, and we ask our users to do the same.
                  Owner may, in appropriate circumstances and at its discretion, suspend or terminate the access of and take other action against users, subscribers, registrants and account holders
                  who infringe the copyrights of others (or otherwise appear to violate the law).
                  <br /><br />
                  Indemnification
                  <br /><br />
                  You agree to indemnify Owner and its affiliates, employees, agents, representatives and third party service providers, and to defend and hold each of them harmless,
                  from any and all claims and liabilities (including attorney fees and legal costs) which may arise from your submissions of Content to the Service, from your unauthorized use of material,
                  email addresses, information, or Content obtained through the Service, from your breach of this Agreement or any of the terms herein, or from any acts related to your use of the Service.
                  Owner reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to this indemnification.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Disclaimer of Warranty
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  YOU ACKNOWLEDGE THAT YOU ARE USING THE SERVICE AT YOUR OWN RISK.
                  THE SERVICE IS PROVIDED "AS IS", AND THE OWNER, ITS AFFILIATES AND ITS THIRD PARTY SERVICE PROVIDERS HEREBY EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS AND IMPLIED,
                  INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF ACCURACY, RELIABILITY, TITLE, MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE OR ANY OTHER WARRANTY, CONDITION,
                  GUARANTEE OR REPRESENTATION, WHETHER ORAL, IN WRITING OR IN ELECTRONIC FORM, INCLUDING BUT NOT LIMITED TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION CONTAINED THEREIN OR PROVIDED BY THE OWNER,
                  ITS AFFILIATES, AND ITS THIRD PARTY SERVICE PROVIDERS. OWNER DOES NOT REPRESENT OR WARRANT THAT ACCESS TO THE SERVICE WILL BE UNINTERRUPTED OR THAT THERE WILL BE NO FAILURES,
                  ERRORS OR OMISSIONS OR LOSS OF TRANSMITTED INFORMATION, OR OTHER HARM TO YOUR SOFTWARE, HARDWARE OR EQUIPMENT.<br />
                  This disclaimer does not apply in all states, and therefore may not apply to you.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Limitation of Liability
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  OWNER, ITS AFFILIATES AND ITS THIRD PARTY SERVICE PROVIDERS SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTIES FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                  ALLEGEDLY SUSTAINED ARISING OUT OF THIS AGREEMENT, THE PROVIDING OF SERVICES HEREUNDER, THE SALE OR PURCHASE OF ANY GOODS OR MERCHANDISE FROM THE SERVICE,
                  YOUR ACCESS TO OR INABILITY TO ACCESS THE SERVICE, YOUR USE OF OR RELIANCE ON THE SERVICE OR ANY OF THE MERCHANDISE, INFORMATION OR MATERIALS AVAILABLE ON THE SERVICE,
                  REGARDLESS OF THE TYPE OF CLAIM OR THE NATURE OF THE CAUSE OF ACTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Release
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  YOU HEREBY AGREE TO RELEASE OWNER, ITS AFFILIATES AND THIRD-PARTY SERVICE PROVIDERS, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM CLAIMS, DEMANDS AND DAMAGES
                  (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED ("CLAIMS"), ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THIS SERVICE.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Arbitration
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Any and all disputes arising out of, under or in connection with this Agreement or your use of the Service, including, without limitation, infringement claims by or against you and/or Owner,
                  shall be settled by arbitration. Any decision by such an arbitrator shall be binding upon the parties, and the costs of the arbitration shall be borne by the non-prevailing party.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 600 }} variant="h6">
                  Miscellaneous
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  You may not assign or transfer this Agreement. If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall continue to be enforceable in full.
                  Owner makes no representation that materials on the Service are appropriate, available, or legal in any location. Those who choose to access the Service do so on their own initiative
                  and are responsible for compliance with all applicable laws. You agree that this Agreement, for all purposes, shall be governed and construed in accordance with the laws of the Union of India
                  applicable to contracts to be wholly performed therein, and any action based on or alleging a breach of this Agreement must be brought in a state in India.
                  In addition, both parties agree to submit to the exclusive personal jurisdiction and venue of such courts.
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 700 }} variant="h6">
                  No Refund Policy
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Gravitas Factor Charts provides a comprehensive charting service product and ensures the highest quality of service to our clients.
                  However, please note that due to the nature of our services, we have a strict no refund policy.
                  All sales are final, and no refunds will be issued for any reason, including but not limited to dissatisfaction with the product, changes in personal circumstances, or technical difficulties.
                  <br /><br />
                  By purchasing our charting service product, you acknowledge and agree to our no refund policy and that your purchase is a one-time transaction and non-refundable.
                  Gravitas Factor Charts is not responsible for any inconvenience or loss that may result from using our services.
                  <br /><br />
                  If you have any questions or concerns about our charting service product or no refund policy, please contact us before making a purchase.
                  Our customer service team will be happy to assist you and answer any questions you may have.

                </Typography>
                </Box>
          }
        </>
      </Modal>
    </div>
  );
}