import { Outlet } from "react-router-dom";
// import { useState, useEffect } from "react";
// import useRefreshToken from "../common/hooks/useRefreshToken";
// import useAuth from "../common/hooks/useAuth";
// import {Grid, CircularProgress} from "@mui/material";

const PersistLogin = () => {
    // const [isLoading, setIsLoading] = useState(true);
    // const refresh = useRefreshToken();
    // const { auth, persist } = useAuth();
    // console.log("Auth=",auth);

    // useEffect(() => {
    //     let isMounted = true;

    //     const verifyRefreshToken = async () => {
    //         try {
    //             await refresh();
    //         }
    //         catch (err) {
    //             console.error(err);
    //         }
    //         finally {
    //             isMounted && setIsLoading(false);
    //         }
    //     }

       
    //     // Avoids unwanted call to verifyRefreshToken
    //     // // !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);
    //     !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    //     return () => isMounted = false;
    // }, [])

    // useEffect(() => {
    //     console.log(`isLoading: ${isLoading}`)
    //     console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    // }, [isLoading])

    return (
        <>
        <Outlet />
            {/* {!persist
                ? <Outlet />
                : isLoading
                    ? <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
                        <div className="flexGrow">
                            <CircularProgress />
                        </div>
                    </Grid>
                    : <Outlet />
            } */}
        </>
    )
}

export default PersistLogin