import React,{useEffect,useContext} from 'react'
import {Grid} from "@mui/material";
import { CONSTANTS } from '../../../common/utility/constant';
// import DashboardHeader from '../../../components/AppHeader/header';
import {useState} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { Construction } from '@mui/icons-material';
import {UserSettingsContext} from '../../../setup/routes-manager/RequireAuth';
import Navbar from './Navbar';
import {Link, CircularProgress,Snackbar,Alert } from "@mui/material";
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';



/**
 * 
 * @param {*} param0 
 * @returns Main container for the dashboard
 */
function DashboardLayoutContainer({children}) {
    const  [CurrentComponent,setCurrentComponent]=useState(children);
    const navigate = useNavigate();
    const location = useLocation();
    const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
    
    const [isLoading,setIsLoading]=useState(true);
    const [msgState, setMsgState] = useState({
     open: false,
     msg:"" ,
     severity:"info"
   });
    const { open, msg,severity } = msgState;
 
    const { userSettings, setUserSettings } = useContext(UserSettingsContext);
    const [statusData,setStatusData]=useState();
    
    // console.log("userSettings from context=",userSettings)
    useEffect(() => {
        // document.title = "Dashboard";
        executeAPI(URL.ACTIVATION_STATUS,"GET",null);
      }, []);

       /**
     * API response handler  
    */  
     useEffect(() => {
        if(loaded){
        console.log("activation status response",responseData);
        if(responseData!=null){
            setStatusData(responseData);
            setIsLoading(false);

            

        }
        else if(error!==null){
            setIsLoading(false);
            console.log("Error data=",error);
          ;
        
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                navigate("/login", { replace: true });
            }
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"})
        }
        reset();
        }
        },[loaded,responseData]);


    const setRoute=(route,component)=>{
      console.log("Route at main=",route);
      navigate(route, {stateNew: { id: 7, color: 'green',userSetting:userSettings }, replace: true });
    //   setCurrentComponent(component);
    }

     /**
    * autoclosing of the snackbar msg bar 
    */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};
  

    
    return (

        <>  
            {/* <DashboardHeader setCurrentRoute={setRoute} /> */}
            {!isLoading?
            <>
            <Navbar statusData={statusData}/>
                {/* <div style={{paddingTop:`${CONSTANTS.DASHBOARD_HEADER_HEIGHT}px`}}> */}
                    <Grid container component="main" sx={{height: `calc(100vh - ${CONSTANTS.DASHBOARD_HEADER_HEIGHT}px)` }}>    
                        {CurrentComponent}
                    </Grid>
                {/* </div> */}
                </>:
            <>
            <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"20%"}}/>
             </div>
            </>}
            <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                    {msg}
                </Alert>  
            </Snackbar>  
        </>      
  )
}

export default DashboardLayoutContainer
