import React,{useEffect, useState} from 'react'
import Navbar from './components/Navbar';
import { Col, Row, Timeline, } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { Formik, Form, Field,ErrorMessage} from 'formik';
import {IconButton,Alert, Snackbar,Button,CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateCompanyDetailsForm } from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import { Link } from 'react-router-dom';
import ACTIVATION_LEFT_PANEL from './components/activation_left_panel';


const Status_Page = () => {
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelSave,responseDataSave,errorSave,loadedSave,resetSave,executeAPISave] = useAxiosWithAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading,setIsLoading]=useState(true);
    const [msgState, setMsgState] = useState({
     open: false,
     msg:"" ,
     severity:"info"
   });
    const { open, msg,severity } = msgState;

    const [statusData,setStatusData]=useState();
    
    useEffect(() => {
        document.title = "Status";
         }, []);

    useEffect(()=>{
        executeAPI(URL.ACTIVATION_STATUS,"GET",null);
       
    },[])

    const getStatusText=(val)=>{
        if(val==1){
            return "Not Submitted"
        }else if(val==2){
            return "Review Pending"
        }else if(val==3){
            return "Rejected"
        }else if(val==4){
            return "Verified"
        }else{
            return "NA"
        }
    }
    /**
     * API response handler  
    */  
    useEffect(() => {
        if(loaded){
        console.log("activation status response",responseData);
        if(responseData!=null){
            let data=responseData;
            
            setStatusData(responseData);
            setIsLoading(false);
           

        }
        else if(error!==null){
            setIsLoading(false);
            console.log("Error data=",error);
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                 navigate("/login", { replace: true });
            }
        }
        reset();
        }
        },[loaded,responseData]);

         /**
    * autoclosing of the snackbar msg bar 
    */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
    };

    const verify=()=>{
        executeAPISave(URL.ACTIVATION_VERIFY,"POST",{});
    }
     /**
     * API response handler Save detailes
    */  
   useEffect(() => {
    if(loadedSave){
    console.log("Save data response",responseDataSave);
    if(responseDataSave!=null){
       
        setMsgState({open:true,msg:"Your application is submitted successfully.",severity:"success"});
        executeAPI(URL.ACTIVATION_STATUS,"GET",null);
        
    }
    else if(errorSave!==null){
       
        console.log("Error data=",errorSave);
       
    
        //if unauthorized then redirec it to login page
        if(errorSave?.response?.status === 401 || errorSave?.response?.status === 403){
            console.log("status received =",errorSave?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
    
    }
    resetSave();
    }
    },[loadedSave,responseDataSave]);

    const goBack=()=>{
        navigate("/activation/address", { replace: true });
    }
    
    
  return (
    <div style={{ width: '100vw',marginTop:"64px"  }} >
        {!isLoading?
        <>
        {/* <Navbar /> */}

        <Row>
        <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={5}>
                    <ACTIVATION_LEFT_PANEL statusData={statusData} index={4}></ACTIVATION_LEFT_PANEL>    
                </Col>
                
                <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={19}>

                {/* content for column 2 goes here */}
                <div style={{ height: `calc(100vh - 64px)`, display: 'flex', direction: 'verticle', alignItems: 'flex-start', justifyContent: 'center',backgroundColor:"#fff" }}>

                <div style={{ width: "calc(100% - 20px)",paddingLeft:"20px",paddingRight:"20px" }} >
                            <Box sx={{mt:6,display:'flex',flexDirection:"row"}}>
                            <Typography  variant="titleMedium" sx={{fontSize:"24px",mb:2}}>
                             Status:
                         </Typography>
                         <Typography  variant="titleMedium" sx={{fontSize:"24px",mb:2,ml:1,color:"error"}}>
                            {getStatusText(statusData.status)}
                         </Typography>
                         </Box>
                         <Box sx={{mt:6,display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                            
                            <Typography variant="titleMedium" sx={{fontSize:"18px",mb:4}}>
                                {statusData?.message}
                            </Typography>
                            {/* {statusData && statusData?.status==1 && statusData.details && statusData.kyc_completed && statusData.bank && statusData.address ?
                            <>
                            <Button onClick={()=>goBack()}  sx={{mb:2, mt:4,height:"50px",width:"100px",mr:2}} variant="contained" disabled={(statusData?.status && (statusData?.status==2 || statusData?.status==4))}
                                    >{"BACK"}</Button>
                             <Button  onClick={verify} sx={{mb:2,mr:1, mt:1,height:"50px",width:"240px"}} variant="contained" >{"Submit for Verification"}</Button>
                            </>:
                            <>
                            
                            </>
                            } */}

                            {statusData && statusData?.status==1 ?
                             <Box sx={{mt:6,display:'flex',flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                            <Button onClick={()=>goBack()}  sx={{mb:2, mt:1,height:"50px",width:"100px",mr:2}} variant="contained" disabled={(statusData?.status && (statusData?.status==2 || statusData?.status==4))}
                                    >{"BACK"}</Button>
                             <Button disabled={!statusData.details || !statusData.kyc_completed || (statusData.entity_type==1 && !statusData.bank )|| !statusData.address} onClick={verify} sx={{mb:2,mr:1, mt:1,height:"50px",width:"240px"}} variant="contained" >{"Submit for Verification"}</Button>
                            </Box>:
                            <>
                            
                            </>
                            }   

                            {statusData && (statusData?.status==2 || statusData?.status==4) ?
                            <>
                             <Button  disabled onClick={verify} sx={{mb:2,mr:1, mt:1,height:"50px",width:"240px"}} variant="contained" >{"Submit for Re-Verification"}</Button>
                            </>:
                            <>
                            </>
                            }
                            {statusData && statusData?.status==3 ?
                             <Box sx={{mt:6,display:'flex',flexDirection:"row",justifyContent:"space-between",alignItems:"center",width:'100%'}}>
                                <Button onClick={()=>goBack()}  sx={{mb:2, mt:1,height:"50px",width:"100px",mr:2}} variant="contained" disabled={(statusData?.status && (statusData?.status==2 || statusData?.status==4))}
                                    >{"BACK"}</Button>
                             <Button  onClick={verify} sx={{mb:2,mr:0, mt:1,height:"50px",width:"240px"}} variant="contained" >{"Submit for Re-Verification"}</Button>
                            </Box>:
                            <>
                            </>
                            }
                         </Box>

                </div>
                </div>
            </Col>
        </Row>
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>:
        <><div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/></div></>
        }
    </div>
    
  )
}
export default Status_Page