import React,{useEffect, useState} from 'react'
import {Link, Grid,CircularProgress,Snackbar,Alert } from "@mui/material";
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import Navbar from './components/Navbar';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import { useNavigate, useLocation } from 'react-router-dom';


const ActivationPage = () => {
   const [isLoading,setIsLoading]=useState(true);
   const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
   const { open, msg,severity } = msgState;
   const [apiError,setAPIError]=useState(undefined);
   const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
   const navigate = useNavigate();
   const location = useLocation();

    React.useEffect(() => {
        document.title = "Entity Registration";
        // executeAPIStatus(URL.ACTIVATION_STATUS,"GET",null);
        executeAPI(URL.ACTIVATION_STATUS,"GET",null);
      }, []);

   
     /**
     * API response handler  
    */  
     useEffect(() => {
        if(loaded){
        console.log("activation status response",responseData);
        if(responseData!=null){
            setIsLoading(false);
            // {"details":false,"kyc_completed":false,"bank":false,"address":false,"status":1}
            if(responseData.status==1){                 //1==Not Submitted, 2==Pending, 3 ==Rejected, 4==Approved
                if(responseData.details==false)
                    navigate("/activation/company-details", { replace: true });
                else if(responseData.kyc_completed==false)
                    navigate("/activation/kyc", { replace: true });
                else if(responseData.bank==false && responseData.entity_type==1)
                    navigate("/activation/bank-details", { replace: true });
                else if(responseData.address==false)
                    navigate("/activation/address", { replace: true });
                else
                    navigate("/activation/status", { replace: true });
            }else{
                navigate("/activation/status", { replace: true });
            }    

        }
        else if(error!==null){
            setIsLoading(false);
            console.log("Error data=",error);
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                // navigate(from, { replace: true });
            }
        }
        reset();
        }
        },[loaded,responseData]);

    /**
    * autoclosing of the snackbar msg bar 
    */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};
  
    return (
        <>
        {/* <Navbar /> */}
        <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
            {isLoading?
             <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
             </div>:<></>}
             <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
         <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
            {msg}
          </Alert>  
        </Snackbar>      
        </Grid>
        </>
    )
}

export default ActivationPage
