
/**
 * Common constants to be used throughout the project
 */
export const CONSTANTS = {
    FIXED_HEADER_HEIGHT:32,
    FIXED_FOOTER_HEIGHT:32,
    DASHBOARD_HEADER_HEIGHT:64,
    FIXED_RIGHT_PANEL_WIDTH:50
}

export const TEXT_MSGS={
    NETWORK_ERROR_MSG:"Something went wrong. Please check your internet connection and try again.",
    REGISTERATION_SUCCESS:"Please check your inbox to activate the account.",
    ACTIVATION_SUCCESS:"Congratulations!!! You have successfully verified you account.",
    PASSWORD_RESET_LINK_SUCCESS:"Please check your inbox to reset your password.",
    PROFILE_LOCKED:"Your profile has been locked for 24hrs. Click on Forgot/Reset password to access your account.",
    PASSWORD_RESET_SUCCESS:"Password has been reset successfully.",
    MERGE_ERROR:"You can merge only the adjacent profiles.",
    UNMERGE_NOT_ALLOWED:"Monthly/Yearly Profile unmerge is not supported right now.",
    USER_SETTINGS_SAVED:"User details updated successfully.",
    SUBSCRIPTION_POPUP_TITLE:"Premium",
    SUBSCRIPTION_PRO_POPUP_TITLE:"Premium +",
    SUBSCRIPTION_POPUP_DESC:"This feature is available only to our premium users right now.",
    LIVE_PANEL_NOT_SUPPORTED:"Live panel only supported in full chart view.",
    LIVE_PANEL_NOT_SUPPORTED_LC:"Live panel only supported for market profile chart",
    MARKET_NOT_LIVE:"Market is not live.",
    POSITION_SAVED_SUCCESS:"Position is saved successfully.",
    POSITION_DELETED_SUCCESS:"Position is deleted successfully.",
    POSITION_NOTHING_TO_SAVE:"There is no non zero position to save.",
    BOOKMARK_SAVED_SUCCESS:"Bookmark is saved successfully.",
    CHART_SAVED_SUCCESS:"Saved successfully.",
    CHART_SAVE_NOT_ALLOWED_LIVE_COMPOSITE:"Can't save the chart. Please unmerge the live composite before saving the chart.",
    BOOKMARK_SAVE_NOT_ALLOWED_LIVE_COMPOSITE:"Can't save the chart. Please unmerge the live composite before saving the chart.",
    BOOKMARK_DELETED_SUCCESSFULLY:"Bookamrk deleted.",
    BOOKMARK_OVERWRITTEN_TITLE:"Overwrite bookmark",
    BOOKMARK_OVERWRITTEN_DESC:"Bookmark is not up to date. Do you want to overwrite it as per the current chart state?",
    CLEAR_AUTOSAVED_TITLE:"Clear Saved State",
    CLEAR_AUTOSAVED_BOOKMARK:"Do you want to clear the saved chart state?",
    CLEAR_AUTOSAVED_BOOKMARK_SUCCESS:"Chart state is reset successfully",
    USER_DETAILS_UPDATED_SUCCESSFULLY:"Contact details updated successfully",
    PASSWORD_UPDATED_SUCCESSFULLY:"Password is updated successfully",
    PREMIUM_PLUS_UPGRADE_MSG:"Upgrade to a Premium + plan to get access to more advanced features and proprietary charts.",
    MAX_ALLOWED_STRIKES:"Maximum 3 strikes can be selected.",
    CHART_DATA_NOT_AVAILABLE:"Chart data is not available",
    ERROR_MSG_TABLE_NO_DATA:"Data not available",
    MULTICHART_NOT_SUPPORTED:"Multichart is not supported on small screen devices"
}

export const PREMIUM_UPGRADE_MSGS_LIST = [
    "Upgrade Today and Take Your Trading to the Next Level",
    "Unlock Exclusive Features Now",
    "Get Access to Advanced Tools and Real-Time Data",
    "Experience the Full Potential of our Charting Service",
    "Unlock Pro-level Insights and Analysis",
    "Don't Miss Out, Upgrade to Premium Today",
    "Maximize Your Potential, Upgrade to Premium",
    "Elevate Your Trading, Upgrade to Premium",
    "Upgrade and Achieve Your Trading Goals",
    "Gain Access to Exclusive Features Today",
    "Unlock Advanced Analysis and Insights",
    "Upgrade for Pro-level Tools and Data"
]

export const USER_ROLE_FIELDS={
    BASIC:'basic',
    PRO:'pro_1',
    PRO2:'pro_2',
    ADMIN:'admin',
    FAST_DATA:'fast_data'
}

export const CHART_RELOAD_TIME={
    SLOW_DATA:60*1000,  //1min  reload
    FAST_DATA:1100,     //1.1sec reload
    LC_DATA:60*1000,
    POLLING_TIME:5*60*1000, //5mins
    // POLLING_TIME:10*1000,
    OPTIONS_DATA:2*1000,     //2seconds
    GREEKS_DATA:5*1000,      //5seconds 
    INDEX_DATA_FAST:70*1000,
    INDEX_DATA_SLOW:70*1000,
    STRIKES_RELOAD_TIME:30*60*1000, //30mins
    // STRIKES_RELOAD_TIME:10*1000 //test 10sec
    FAST_POLLING:60*1000    //1min polling for market status check  
}


export const MP_CHART_RELOAD_TIME={
    SLOW_DATA:70000,      //70 seconds  reload
    FAST_DATA:1100,         //1.1sec reload
    POLLING_TIME:5*60*1000, //5mins polling
    // POLLING_TIME:30*1000, //5mins polling
    FAST_POLLING:60*1000    //1min polling for market status check        
}


export const RESEND_TIMER_VAL=120; //120 seconds

export const SNACKBAR_AUTO_HIDE_DURATION=5000;  //5seconds
export const SNACKBAR_AUTO_HIDE_DURATION_SHORT=2000;  //5seconds

export const TIME_FRAME_VALUES={
    daily:"daily",
    weekly:"weekly",
    weekly_series:"weekly_s",
    monthly:"monthly",
    monthly_series:"monthly_s",
    yearly:"yearly_d"
}

export const PROFILE_VIEW={
    tpo_only:"TPO_ONLY",
    vol_tpo_side:"VOL_TPO_SIDE",
    only_vol:"VOL_ONLY",
    tpo_on_vol:"TPO_ON_VOL",
 }

 export const AUTO_CENTER_CONFIG={
    DELAY:1000,  //throttle/debounce delay
    THROTTLING:1,
    DEBOUCE:2,
    USE_THROTTLE_OR_DEBOUNCE:1,     //1 for throttling based auto center, 2 for debounce based
    DELTA_X:10,                     //represent x translation required to trigger auto center
    DELTA_Y:20                      //less than this value required for auto centering otherwise user is vertically scrolling so dont trigger auto center here
 }

 export const WATERMARK_CONFIG={
    TEXT:"© 2023 Gravitas Factor Charts",
    TABLE_TEXT:"© 2023 Gravitas Factor Charts",
    LC_TEXT:"© 2023 Gravitas Factor Charts"
 }

 // allowed roles are the selected and above roles (for hierarchy considerations)
 // admin is not ideally hierarchial, but some pages are xclusive only to admin user
 export const ALL_USER_ROLES=["basic", "fast_data", "pro_1", "pro_2", "admin"];
 export const ALLOWED_ROLES_ALL=ALL_USER_ROLES;
 export const ALLOWED_ROLES_ADMIN=["admin"] ;
 export const ALLOWED_ROLES_PRO_1=["pro_1", "pro_2"] ;
 export const ALLOWED_ROLES_PRO_2=["pro_2"] ;
 export const ALLOWED_ROLES_FAST_DATA=["fast_data", "pro_1", "pro_2"] ;

export const  LOCALE_STRING="en-IN" ;

export const BOOKMARKT_CATEGORY_DATA=[
    {
        name:"New",
        value:"New"
    },
    {
      name:"Options",
      value:"Options"
    },
    {
      name:"Futures",
      value:"Futures"
    },
  ]

  export const BOOKMARK_NAME_MAX_LENGTH=30;
  export const BOOKMARK_CATEGORY_MAX_LENGTH=30;
  export const BOOKMARK_CATEGORY_AUTOSAVE="AUTO_SAVED";
  export const BOOKMARK_AUTO_SAVE_DURATION=3*60*1000;             //3mins
  export const ERROR_CODE_BOOKMARK_ALREADY_MODIFIED=307;

  export const DateFormat = 'DD-MM-YYYY';
  
  const ITEM_HEIGHT = 26;
  const ITEM_PADDING_TOP = 8;
  export const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        width: 80,
        autoFocus: false
      },
    },
  };
 
  export const NO_OF_DAYS=[
    {
        key:"Intra Only",
        value:-1
    },
    {
        key:1,
        value:1
    },
    {
        key:2,
        value:2
    }, 
    {
        key:3,
        value:3
    },    
    {
        key:4,
        value:4
    },
    {
        key:5,
        value:5
    },    
    {
        key:6,
        value:6
    },    
]

export const HV_CHART_V_TYPES=[
    {
        key:"C2C Log Ratio Std Dev",
        value:"c2c_sigma"
    },
    {
        key:"C2O Log Ratio Std Dev",
        value:"c2o_sigma"
    },
    {
        key:"Gap Std Dev",
        value:"gap_sigma"
    },
    {
        key:"Day Range Std Dev",
        value:"o2c_sigma"
    },
    {
        key:"C2C Diff Std Dev",
        value:"c2cd_sigma"
    },
    {
        key:"C2C Log Ratio Mean",
        value:"c2c_mean"
    },
    {
        key:"C2O Log Ratio Mean",
        value:"c2o_mean"
    },
    {
        key:"Gap Mean",
        value:"gap_mean"
    },
    {
        key:"Day Range Diff Mean",
        value:"o2c_mean"
    },
    {
        key:"C2C Diff Mean",
        value:"c2cd_mean"
    }
]

export const HV_CHART_PLOT_TYPES=[
    {
        key:"ATR 5",
        value:"5_atr"
    },
    {
        key:"ATR 20",
        value:"20_atr"
    },
    {
        key:"ADR 5",
        value:"5_adr"
    },
    {
        key:"ADR 20",
        value:"20_adr"
    },
]

export const SNAKE_CHART_PLOT_TYPE=[
    {
        key:"Multi Strike Straddle Price",
        value:"pair_price"
    },
    {
        key:"Multi Strike Straddle OI",
        value:"oi"
    },
    {
        key:"Multi Strike IV and Writer's Cash",
        value:"iv_wcash"
    },
    
]

// this menu is for admin and pro_2
export const BAR_CHART_PLOT_TYPE=[
    {
        key:"Strike Available Intra Value - Split",
        value:"_gcash"
    },
    {
        key:"Strike Available Total Value - Split",
        value:"_dgcash"
    },
    {
        key:"Writer's Cash - Split",
        value:"_wcash"
    },
    {
        key:"OI - Split",
        value:"_oi"
    },
    {
        key:"OI Change - Split",
        value:"_doi"
    },
    {
        key:"Volume - Split",
        value:"_vol"
    },
    {
        key:"Strike Available Intra Value - Net",
        value:"net_gcash"
    },
    {
        key:"Strike Available Total Value - Net",
        value:"net_dgcash"
    },
    {
        key:"Strike Available Intra Value - Sum",
        value:"total_gcash"
    },
    {
        key:"Strike Available Total Value - Sum",
        value:"total_dgcash"
    },
    {
        key:"Put Call Ratio",
        value:"pcr"
    },
    {
        key:"OI Difference - (Call OI - Put OI)",
        value:"net_oi"
    },
    {
        key:"Straddle OI - Sum",
        value:"total_oi"
    },
    {
        key:"Straddle Value",
        value:"straddle"
    },
    {
        key:"OI Pain Value",
        value:"oi_pain"
    },
    {
        key:"IV",
        value:"iv"
    },
]

// this is Options Strikewise OI Visualization menu for premium users
export const BAR_CHART_OI_PLOT_TYPE=[
    {
        key:"OI - Split",
        value:"_oi"
    },
    {
        key:"OI Change - Split",
        value:"_doi"
    },
    {
        key:"OI Difference - (Call OI - Put OI)",
        value:"net_oi"
    },
    {
        key:"Straddle OI - Sum",
        value:"total_oi"
    },
    {
        key:"Volume - Split",
        value:"_vol"
    },
    {
        key:"OI Pain Value",
        value:"oi_pain"
    },
    {
        key:"IV",
        value:"iv"
    },
]

// this is Options Strikewise Market Inventory Visualization menu, mainly for premium plus users
export const BAR_CHART_MI_PLOT_TYPE=[
    {
        key:"Unrealized Positional Value - Split",
        value:"_gcash"
    },
    {
        key:"Inventory Sensitivity Estimate - Split",
        value:"_dgcash"
    },
    {
        key:"Writers' Unrealized Cash - Split",
        value:"_wcash"
    },
    {
        key:"Unrealized Positional Value - Net",
        value:"net_gcash"
    },
    {
        key:"Unrealized Positional Value - Sum",
        value:"total_gcash"
    },
    {
        key:"Inventory Sensitivity Estimate - Net",
        value:"net_dgcash"
    },
    {
        key:"Inventory Sensitivity Estimate - Sum",
        value:"total_dgcash"
    }
]


export const MARKET_OPEN_TIME="09:15";
export const MARKET_CLOSE_TIME="15:30";

export const TABLE_TYPES={
    INDEX_COMPONENTS:"INDEX_COMPONENTS",
    OPTCHAIN:"OPTCHAIN",
    INDEX_TICKER:"INDEX_TICKER"
}

export const INDEX_TICKER_COLUMNS_LIST={
    spot_3D_comp_va:"spot_va",
    spot_open_high_low:"spot_open_high_low",
    spot_open_change_ltp:"spot_change",
    spot_timestamp:"spot_timestamp",
    fut_tr_atr:"fut_tr_atr",
    fut_open_high_low:"fut_open_high_low",
    hv_high_low:"hv_high_low",
    pe_chg_ltp:"pe_chg_ltp",
    fut_timestamp:"fut_timestamp",
    fut_prem_group:"fut_prem_group",
    prem_stats:"prem_stats",
    max_min_prem:"max_min_prem",
    sma_wma:"sma_wma",
    idx_ad:"idx_ad"
}

export const INDEX_COMPONENTS_COLUMNS_LIST={
    high:"high",
    low:"low",
    ltp:"ltp",
    change:"change",
    change_percent:"change_percent",
    tr:"tr",
    atr:"atr",
    avg_daily_vol:"avg_daily_vol",
    max_vol:"max_vol",
    wt_percent:"wt_percent",
    idx_change_pts:"idx_change_pts",
    change_ltp:"change_ltp",
    spot_chg:"spot_chg",
    netidxpts:"netidxpts",
    vol:"vol",
    percent_vol:"perc_vol",
    percent_avg_vol:"perc_avg_vol",
    max_daily_stats:"max_daily_stats",

 }

export const OPTCHAIN_COLUMNS_LIST={
    calls_max_min_oi:"min_max_oi",
    calls_doi_oi:"calls_doi_oi",
    calls_iv:"iv",
    calls_vol:"vol",
    calls_vpoc_vwap:"vpoc_vwap",
    calls_chg_ltp:"chg_ltp",
    puts_max_min_oi:"min_max_oi",
    puts_doi_oi:"calls_doi_oi",
    puts_iv:"iv",
    puts_vol:"vol",
    puts_vpoc_vwap:"vpoc_vwap",
    puts_chg_ltp:"chg_ltp",
    oi_spike:"oi_spike",
    straddle_pcr:"straddle_pcr",
    strike_pain:"strike_pain",
    synthetic_fut:"synthetic_fut"
}


export const SUBSCRIPTION_PLAN_LIST=[
    {
        key:"PREMIUM_MONTHLY_1",
        value:"PREMIUM_MONTHLY_1"
    },
    {
        key:"PREMIUM_MONTHLY_6",
        value:"PREMIUM_MONTHLY_6"
    },
    {
        key:"PREMIUM_MONTHLY_12",
        value:"PREMIUM_MONTHLY_12"
    },
    {
        key:"PREMIUM_PLUS_MONTHLY_1",
        value:"PREMIUM_PLUS_MONTHLY_1"
    },
    {
        key:"PREMIUM_PLUS_MONTHLY_6",
        value:"PREMIUM_PLUS_MONTHLY_6"
    },
    {
        key:"PREMIUM_PLUS_MONTHLY_12",
        value:"PREMIUM_PLUS_MONTHLY_12"
    },     
]   


export const PAYMENT_STATUS={
    SUCCESS:"success",
    CANCELLED:"cancelled",
    FAILURE:"failure",
    ERROR:"error"
}

export const MIN_CHART_WIDTH="630px";
export const MIN_SNAKE_CHART_WIDTH="1024px";
export const MIN_HV_CHART_WIDTH="1210px";
export const MIN_BAR_CHART_WIDTH="1024px";
export const MIN_TABLE_CONTAINER_WIDTH="1180px";
