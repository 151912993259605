import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/styles.scss';
import { ConfigProvider} from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));

//disable console logs in prod env
if (process.env.NODE_ENV != "development"){
    console.log = () => {};
    console.error =()=>{};
    console.warn =()=>{};
}   
root.render(
  //TODO: use strict mode in production build. uncooment this
  //https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  
  <React.StrictMode>
  <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Verdana,sans-serif" 
                },
            }}
        > 
        <App />

        </ConfigProvider>
   
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
