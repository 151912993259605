import * as React from 'react';
import { useState,useRef,useEffect } from 'react';
import Button from '@mui/material/Button';
import {IconButton} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { Formik, Form, Field,ErrorMessage } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiTextFieldFormik } from '../../../components/CustomTextField/TextField';
import { validateLoginForm } from '../../../common/utility/validators'
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from  '../../../common/hooks/useAuth';
import { useTheme, Snackbar, Alert } from '@mui/material';
import WelcomeLayout from '../components/welcome-layout';
import FormContainer from '../components/form-container';
import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../../../assets/images/logo.png';


 //form initial values for login
 const initialValues = {
  email: '',
  password: '',
  remember_me: true
};

export default function Login() {
  const theme=useTheme();
  const { setAuth, persist, setPersist } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const inputRef = React.useRef();
  
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();

  const from =  "/dashboard";
  const formikRef = useRef();
  const matches = useMediaQuery('(min-width:640px)');
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
   const { open, msg,severity } = msgState;
   const [apiError,setAPIError]=useState(undefined);
   
   useEffect(() => {
    document.title = "Login";
     }, []);

  
 /**
 * API response handler  
 */  
  useEffect(() => {
    if(loaded){
      if(responseData!=null){
        const accessToken = responseData?.token;
        // setAuth({accessToken:accessToken,name:responseData.first_name+" "+responseData.last_name,role:responseData.role});
        localStorage.setItem("token", JSON.stringify(accessToken));
        navigate(from, { replace: true });
      }
      else if(error!==null){
        formikRef?.current.setSubmitting(false);
        // setAPIError(TEXT_MSGS.PROFILE_LOCKED);
        if(error?.response?.data?.error_code===2002){
          setAPIError(TEXT_MSGS.PROFILE_LOCKED);
        }
        else if(error?.response?.data?.error_code===2001){
          formikRef?.current.setErrors({password:"Provided password is incorrect"})
        }else if(error?.response?.data?.error_code===1007){
          formikRef?.current.setErrors({email:"Email address is not registered"})
        
        }
        else{
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
        }
        reset();
      }
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate,from]);


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  
  
/**
 * API caller  
 */  
 const onSubmit = (values, props) => {
    values.remember_me=rememberMe;
    executeAPI(URL.LOGIN,"POST",JSON.stringify(values)); 
 }

 /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};



  return (
     
      <MainLayoutContainer>
        
      {/* Header component */}
        {/* <Header showLogin={false} showIntro={true}/> */}
       
        {/*Welcome banner left component*/}
        {/* <WelcomeLayout/> */}
       
        {/* Form container for right layut */}
        <FormContainer>
            
            
            <Box sx={{ justifyContent:"center",alignItems:"center",width:'340px',backgroundColor:"#fff",mt:6 }}>
              
              {/* <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',pb:6}}>

               <img src={logo} alt="Gravitas Factor Logo" width={60} height={60}/>
                    <Typography variant="titleMedium" sx={{textAlign:'center',fontSize:"24px"}} >Gravitas Factor</Typography>
              </Box> */}
            
            <Typography  variant="titleMedium"  sx={{fontSize:"20px"}}>
              Log in to Gravitas Factor
            </Typography>

            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validate={validateLoginForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
              <Form>
                <input name="email" style={{display:'none'}}></input>
               
  
                {/* Email field */}
                <Box>
                      <Field
                      component={MuiTextFieldFormik}
                      // autoFocus
                      fullWidth
                      required
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      sx={{ mt: 3, mb: 3, }}
                      name="email"
                      type="text"
                      label="Email Address"
                    />
              </Box>
             
             {/* Password field */}
             <input name="password" style={{display:'none'}}></input>
              <Box>
                <Field
                  component={MuiTextFieldFormik}
                  fullWidth
                  
                  required
                  // autocomplete="new-password"
                  
                  variant="outlined" 
                  size="small" 
                  color="inputFieldColor"
                  sx={{ mb: 2 }}
                  type={showPassword ? "text" : "password"} 
                  label="Password"
                  name="password"
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              
              {/* Remember Me */}
              <Box display="flex" sx={{ mb: 2 }} justifyContent="space-between" alignItems="center">
                <Field as={FormControlLabel}
                    name='remember_me'
                    size='small'
                    control={<Checkbox 
                      checked={rememberMe}
                      onChange={()=>setRememberMe(!rememberMe)}
                      sx={{
                        color: theme.palette.primaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.primaryTheme.shade03,
                        },
                      }}/>
                    }
                    label={<Typography variant="linkSupportText" >
                        Remember Me
                      </Typography>
                    }
                />

                <ErrorMessage name="remember_me" render={msg =>  <Typography variant="warningText">{msg}</Typography> } />                
                {/*Forgot/reset password link  */}
                <Link href="/forgot-password" sx={{alignContent:"flex-end"}} variant="link">Forgot/Reset password?</Link>
                </Box>           

                {apiError!=undefined?
                <Box sx={{display:'flex',flexDirection:'column'}}>
                <Typography variant="errorText" sx={{fontSize:'16px',fontWeight:600}}>We couldn't sign you in.</Typography>
                <Typography variant="errorText" sx={{mt:1,color:'#000'}}>To protect your account, its been temporarily locked. Try again in 24hrs.</Typography>
                <Typography variant="errorText" sx={{mt:1,color:'#000'}}>To be on the safe side, you can reset your password before trying again.</Typography>
                </Box>:
                <></>
              }

                {/* submit log in button */}
                <Button type='submit' sx={{mb:2,mt:1,height:"50px"}} variant="contained" disabled={isSubmitting}
                      fullWidth>{isSubmitting ? "Loading" : "Log In"}
                </Button>
              
              </Form>
                )}
            </Formik>
 
              <Grid item>
                  <Typography variant="link">{"Don't have an account yet? "}  
                    <Link href="/register" variant="link" sx={{pb:6}}>  {"Sign Up"} </Link>
                  </Typography>
              </Grid>
 
          </Box>
 
        </FormContainer>
 
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
         <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
            {msg}
          </Alert>  
        </Snackbar>     
 
        </MainLayoutContainer>
     
  );
}