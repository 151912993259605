/**
 * validator utility for all the forms
 * @param {*} values 
 * @returns 
 */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const isPositiveNumber=(val)=>{
  if(isNaN(val)) 
    return false;
  
  if(val<0)
    return false;
  
  return true;     
}

const isPositiveNumberDiscount=(val)=>{
  if(isNaN(val)) 
    return false;
  
  if(val<0)
    return false;
  if(val>100)
  return false;  
  
  return true;     
}


const isFutureDate=(idate)=>{
    let today = new Date().getTime()
      idate = idate.split("/");
  
  idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
  return (today - idate) < 0 ? true : false;
}

const PASSWORD_REGEX="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
const PASSWORD_REGEX2="^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$"
// IFSC regex = "^[A-Z]{4}0[A-Z0-9]{6}$"
// PAN regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}"
// GST regex = "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"

export const validateLoginForm = (values) => {
    const errors = {};
   
     if (!values.email.trim()) {
       errors.email = 'Email id required';
     }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())) {
       errors.email = 'Please enter an email address in the format username@domain.com';
     }
   
     if (!values.password.trim()) {
       errors.password = 'Please enter the password';
     }
   
     return errors;
};

export const validateRegisterationForm = (values) => {
  const errors = {};
  if (!values.entity_name.trim()) {
    errors.entity_name = 'Please enter the Entity name';
  }
  
   if (!values.email.trim()) {
     errors.email = 'Please enter email address';
   }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())) {
     errors.email = 'Please enter an email address in the format username@domain.com';
   }
 
  // commenting the phone number validation as it is not the required field
   if(!values.mobile.trim()){
    errors.mobile="Please enter phone number"
   }else if(!/^[0-9]\d{9}$/i.test(values.mobile.trim())){
    errors.mobile="Please enter a valid phone number"
   }

   if (!values.password.trim()) {
     errors.password = 'Please provide password';
   }else if(!/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(values.password.trim())){
    errors.password = "Password must contain 8 characters with alteast one number, alphabet and special characters"
   }

   if (!values.confirm_password.trim()) {
    errors.confirm_password = 'Please provide confirm password';
  }else{
    if(values.confirm_password.trim()!=values.password.trim()) 
    errors.confirm_password = "Password doesn't match";   
  }

   if(!values.terms){
    errors.terms="Please accept the terms & conditons, privacy policy. "
   }

 
   return errors;
 };
 
 export const validateForgotPasswordForm = (values) => {
  const errors = {};
 
   if (!values.email.trim()) {
     errors.email = 'Email id required';
   }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
     errors.email = 'Invalid email address';
   }
 
   return errors;
};

export const validateResetPasswordForm = (values) => {
  const errors = {};
 
  if (!values.password.trim()) {
    errors.password = 'Please provide password';
  }else if(!/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(values.password.trim())){
    errors.password = "Password must contain 8 characters with alteast one number, alphabet and special characters"
  }else if (!values.confirm_password) {
    errors.confirm_password = 'Please confirm the password';
  }else{
    if(values.password!==values.confirm_password) {
      errors.confirm_password = "Password not matched";
    }
  }

   return errors;
};


export const validateCompanyDetailsForm = (values) => {
  console.log("validateCompanyDetailsForm called values=",values);
  const errors = {};
  if (!values.entity_name.trim()) {
    errors.entity_name = 'Please enter the Entity name';
  }

  if (values.entity_type==undefined || values.entity_type=='') {
    errors.entity_type = 'Please select Entity Type';
  }

  if (!values.company_registration_id.trim()) {
    errors.company_registration_id = 'Please enter Registration/CIN No.';
  }


  
   if (!values.email.trim()) {
     errors.email = 'Please enter email address';
   }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())) {
     errors.email = 'Please enter an email address in the format username@domain.com';
   }
 
  // commenting the phone number validation as it is not the required field
   if(!values.mobile.trim()){
    errors.mobile="Please enter mobile number"
   }else if(!/^[0-9]\d{9}$/i.test(values.mobile.trim())){
    errors.mobile="Please enter a valid mobile number"
   }

   if (!values.gst.trim()) {
     errors.gst = 'Please provide GSTIN number';
    }else if(!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i.test(values.gst.trim()) ){
      errors.gst ="Please provide valid GSTIN number"
    }

   if (!values.date_of_incorporation.trim()) {
    errors.date_of_incorporation = 'Please provide date of incorporation';
  }else if(!/^\d{2}\/\d{2}\/\d{4}$/i.test(values.date_of_incorporation) ){
    errors.date_of_incorporation ="Please provide date in DD/MM/YYYY format "
  }else {
    const date=values.date_of_incorporation.trim().split("/");
    let dateString =date[2]+"-"+date[1]+"-"+date[0];
    let timestamp = Date.parse(dateString);
    if (isNaN(timestamp)) {
      errors.date_of_incorporation ="Please provide valid date in DD/MM/YYYY format "
    }

   
  }

   //dayjs way
    // const date=values.date_of_incorporation.trim();
    // let isValid=dayjs(date.slice(-10).split("-")[2]+"-"+date.slice(-10).split("-")[1]+date.slice(-10).split("-")[0],"YYYY-DD-MM",true).isValid();
    // console.log("isValidDate=",isValid);
    // if(!isValid)
    // errors.date_of_incorporation = 'Please provifr valid date.';


  if (!values.company_pan_card.trim()) {
    errors.company_pan_card = 'Please provide PAN number';
  }else if(!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(values.company_pan_card) ){
    errors.company_pan_card ="Please provide valid PAN number"
  }

   
 
   return errors;
 };



 export const validateBankDetailsForm = (values) => {
  console.log("validateCompanyDetailsForm called values=",values);
  const errors = {};
  if (!values.bank_name.trim()) {
    errors.bank_name = 'Please enter the Settlement Bank Name';
  }

  if (values.bank_account_type==undefined || values.bank_account_type=='') {
    errors.entity_type = 'Please select Account Type';
  }
  
   if (!values.bank_branch_name.trim()) {
     errors.bank_branch_name = 'Please enter Bank Branch ';
   }

 
 
   if (!values.ifsc_code.trim()) {
     errors.ifsc_code = 'Please provide IFSC code';
   }else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/i.test(values.ifsc_code.trim())) {
    errors.ifsc_code = 'Please enter valid IFSC code';
  }


   if (!values.account_name.trim()) {
    errors.account_name = 'Please provide Name as per Bank Account';
  }

  if (!values.bank_account_number.trim()) {
    errors.bank_account_number = 'Please provide Bank Account Number';
  }else if (!values.confirm_bank_account_number) {
    errors.confirm_bank_account_number = 'Please confirm Bank Account Number';
  }else{
    if(values.bank_account_number!==values.confirm_bank_account_number) {
      errors.confirm_bank_account_number = "Bank account number doesn't match";
    }
  }
 
   return errors;
 };

 export const validateAddressDetailsForm = (values) => {
  console.log("validateAddressDetailsForm called values=",values);
  const errors = {};
  

  if (!values.address_1.trim()) {
    errors.address_1 = 'Please provide Address';
  }

  if (!values.pincode_1.trim()) {
    errors.pincode_1 = 'Please provide Pin Code';
  }

  if (!values.city_1.trim()) {
    errors.city_1 = 'Please provide City';
  }

  if (!values.state_1.trim()) {
    errors.state_1 = 'Please provide State';
  }

   if (!values.email_1.trim()) {
     errors.email_1 = 'Please enter email address';
   }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_1.trim())) {
     errors.email_1 = 'Please enter an email address in the format username@domain.com';
   }
 
  // commenting the phone number validation as it is not the required field
   if(!values.mobile_1.trim()){
    errors.mobile_1="Please enter mobile number"
   }else if(!/^[0-9]\d{9}$/i.test(values.mobile_1.trim())){
    errors.mobile_1="Please enter a valid mobile number"
   }

  
   

  if (!values.address_2.trim()) {
    errors.address_2 = 'Please provide Address';
  }

  if (!values.pincode_2.trim()) {
    errors.pincode_2 = 'Please provide Pin Code';
  }

  if (!values.city_2.trim()) {
    errors.city_2 = 'Please provide City';
  }

  if (!values.state_2.trim()) {
    errors.state_2 = 'Please provide State';
  }

   if (!values.email_2.trim()) {
     errors.email_2 = 'Please enter email address';
   }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_2.trim())) {
     errors.email_2 = 'Please enter an email address in the format username@domain.com';
   }
 
  // commenting the phone number validation as it is not the required field
   if(!values.mobile_2.trim()){
    errors.mobile_2="Please enter mobile number"
   }else if(!/^[0-9]\d{9}$/i.test(values.mobile_2.trim())){
    errors.mobile_2="Please enter a valid mobile number"
   }

 
   return errors;
 };


//  "invoice_due_date": "",
// "date_of_invoice": "",
// "supplier_name": "",
// "supplier_gst": "",
// "customer_name": "",
// "customer_gst": "",
// "invoice_number": "",
// "purchase_order": null,
// "discount_type": 1,
// "original_invoice_amount": "",
// "max_discount_percentage": "",
// "invoice_amount_after_discount": "",


export const validateInvoiceRequestForm = (values) => {
  console.log("values:",values);
  // console.log("validateCompanyDetailsForm called values=",values);
  const errors = {};
  // if (!values.supplier_name.trim()) {
  //   errors.supplier_name = 'Please enter Supplier Name';
  // }

  
  // if (!values.supplier_gst.trim()) {
  //   errors.supplier_gst = 'Please provide Supplier GSTIN number';
  //  }else if(!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i.test(values.supplier_gst.trim()) ){
  //    errors.supplier_gst ="Please provide valid Supplier GSTIN number"
  //  }

  if (!values.customer_name.trim()) {
    errors.customer_name = 'Please enter Customer Name';
  }

  // if (!values.customer_gst.trim()) {
  //   errors.customer_gst = 'Please enter Customer GSTIN';
  // }
  if (!values.customer_gst.trim()) {
    errors.customer_gst = 'Please provide Customer GSTIN number';
   }else if(!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i.test(values.customer_gst.trim()) ){
     errors.customer_gst ="Please provide valid Customer GSTIN number"
   }



  if (values.discount_type==undefined || values.discount_type=='') {
    errors.discount_type = 'Please select Discount Type';
  }
  
   
   if (!values.invoice_number.trim()) {
     errors.invoice_number = 'Please provide Invoice Number';
   }

   if (!values.date_of_invoice.trim()) {
    errors.date_of_invoice = 'Please provide Date of Invoice Issue';
    }else if(!/^\d{2}\/\d{2}\/\d{4}$/i.test(values.date_of_invoice) ){
      errors.date_of_invoice ="Please provide date in DD/MM/YYYY format "
    }else {
      const date=values.date_of_invoice.trim().split("/");
      let dateString =date[2]+"-"+date[1]+"-"+date[0];
      let timestamp = Date.parse(dateString);
      if (isNaN(timestamp)) {
        errors.date_of_invoice ="Please provide valid date in DD/MM/YYYY format "
      }    
  }

  if (!values.invoice_due_date.trim()) {
    errors.invoice_due_date = 'Please provide Invoice Due Date';
    }else if(!/^\d{2}\/\d{2}\/\d{4}$/i.test(values.invoice_due_date) ){
      errors.invoice_due_date ="Please provide date in DD/MM/YYYY format "
    }else {
      const date=values.invoice_due_date.trim().split("/");
      let dateString =date[2]+"-"+date[1]+"-"+date[0];
      let timestamp = Date.parse(dateString);
      if (isNaN(timestamp)) {
        errors.invoice_due_date ="Please provide valid date in DD/MM/YYYY format"
      }else{
        console.log("futuredata=",isFutureDate(values.invoice_due_date.trim()));
        if(!isFutureDate(values.invoice_due_date.trim())){
          errors.invoice_due_date ="Please enter the future date for the invoice"
        }
      }    
  }
  

  // if (!values.max_discount_days) {
  //   errors.max_discount_days = 'Please provide no. of discount days';
  // }


  if (!values.dummy_original_invoice_amount) {
    errors.dummy_original_invoice_amount = 'Please provide Invoice Amount';
  }else {
    // if(!isPositiveNumber(values.original_invoice_amount)){
    //   errors.original_invoice_amount = 'Please provide valid Original Invoice Amount';
    // }
  }

  // if (!values.max_discount_percentage) {
  //   errors.max_discount_percentage = 'Please provide Maximum Discount Percentage';
  // }else {
  //   if(!isPositiveNumberDiscount(values.max_discount_percentage)){
  //     errors.max_discount_percentage = 'Please provide valid Maximum Discount Percentage';
  //   }
  // }
   

   return errors;
 };


