import { useEffect, useState,createContext,useMemo } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../common/hooks/useAuth";
import DashboardLayoutContainer from "../../pages/dashboard/components/main_layout";
import { useAxiosWithAuth } from "../../common/api/hooks/useAxiosWithAuth";
import { URL } from "../../common/api/urls";
import { BOOKMARK_CATEGORY_AUTOSAVE,BOOKMARKT_CATEGORY_DATA } from "../../common/utility/constant";
import { CircularProgress } from "@mui/material";

/**
 * Wrapper component to manage navigation based on user authentication status
 * @returns the route if the user is authenticated else it will redirect the user to login page
 */
 export const UserSettingsContext = createContext({
    userSettings: undefined,
    setUserSettings: () => {},
  });

const RequireAuth = ({allowedRoles}) => {
    const { auth } = useAuth();
    const location = useLocation();
    
    const [userSettings, setUserSettings] = useState(undefined);
    const [isLoading, setIsLoading]=useState(true)
    const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
   
      
    const value = useMemo(
    () => ({ userSettings, setUserSettings }), 
    [userSettings]
    );

    const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")) || undefined);
    
    //get user settings at the start
    useEffect(()=>{
      // console.log("Aloowed roles in Require auth and auth object",allowedRoles,auth)
      if(token){
       
        executeAPI(URL.USER_PROFILE,"GET",{});
        // executeAPIBookamrk(URL.DELETE_ALL_AUTOSAVED_BOOKMARK,"DELETE",{});
      }
      else{
          setUserSettings(undefined)
          setIsLoading(false);
      }
    },[]);
    
     //user profile api response handler
     useEffect(()=>{
      if(loaded){
        console.log("userSettings=",responseData);
        if(responseData!=null){
          console.log("userSettings=",responseData);
          setUserSettings(responseData);
          
          setIsLoading(false);
         }else if(error!==null){
          setUserSettings({})
          setIsLoading(false);
        }
      }
  
    },[loaded,responseData])

    return (
      token
          ?   userSettings!=undefined && !isLoading?
              <UserSettingsContext.Provider value={value}>
                  <DashboardLayoutContainer>
                  <Outlet />
                  </DashboardLayoutContainer>
              </UserSettingsContext.Provider>:
              <>
              <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"30%"}}/>
             </div>
              </>
          :
          token?
              <Navigate to="/unauthorized" state={{ from: location }} replace />      
              :   
              <Navigate to="/login" state={{ from: location }} replace />
  ); 
    //    return (
    //     <>
    //     {!isLoading?
    //     <>
    //       {userSettings!=undefined ? 
       
    //               <Outlet/>
    //             :
                   
    //             <Navigate to="/login" state={{ from: location }} replace />
    //       }
    //             </>:
    //             <></>}
    //             </>
    // );
}

export default RequireAuth;