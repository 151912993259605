import React, { useEffect, useState,useContext } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined,LogoutOutlined,UserOutlined,MenuOutlined } from '@ant-design/icons';
import { Menu,ConfigProvider, Tooltip ,Button} from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Typography,Box,Snackbar,Alert,useTheme,useMediaQuery } from '@mui/material';
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from "../../../common/api/hooks/useAxiosWithAuth";
import { URL } from "../../../common/api/urls";
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';
import logo from '../../../assets/images/logo.png';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space,Avatar } from 'antd';



const items_activated = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    style: { fontWeight:600,color:"#fff",fontSize:"16px" },
   
},
  {
      label: 'Discounted Invoices',
      key: 'invoice',
      style: { fontWeight:600,color:"#fff",fontSize:"16px" },
   
     
  },
 
];

const items_not_activated = [
//   {
//     label: 'Dashboard',
//     key: 'dashboard',
//     style: { fontWeight:600,color:"#fff",fontSize:"16px" },
   
// },
  {
    label: 'Entity Registration',
    key: 'activation',
    style: { fontWeight:600,color:"#fff",fontSize:"16px" },
   
},

 
];

const items_profile = [
  //   {
  //     label: 'Dashboard',
  //     key: 'dashboard',
  //     style: { fontWeight:600 },
    
  // },
  {
      label: 'Profile',
      key: 'profile',
      style: { fontWeight:600 },
     
  },
 {
      label: 'Logout',
      key: 'logout',
      style: { fontWeight:600 },
  },
 
];

const items_profile_not_activated = [
  {
      label: 'Logout',
      key: 'logout',
      style: { fontWeight:600 },
  },
 
];

const items_profile_small_device = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    style: { fontWeight:600 },
   
  },
  {
    label: 'Discounted Invoices',
    key: 'invoice',
    style: { fontWeight:600 },
   
  },
  {
      label: 'Profile',
      key: 'profile',
      style: { fontWeight:600 },
     
  },
 
  {
      label: 'Logout',
      key: 'logout',
      style: { fontWeight:600 },
  },
 
];

const items_profile_not_activated_small_device = [
  {
    label: 'Entity Registration',
    key: 'activation',
    style: { fontWeight:600 },
  },
  {
      label: 'Logout',
      key: 'logout',
      style: { fontWeight:600 },
  },
 
];



const Navbar = (props) => {
    // console.log("Path====>",window?.location?.pathname,window?.location);
    console.log("Navbar status data=",props.statusData);
    const theme=useTheme();
    const [current, setCurrent] = useState("dashboard");
    const navigate = useNavigate();
    const location = useLocation();
    const { userSettings, setUserSettings } = useContext(UserSettingsContext);
    const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
     const { open, msg,severity } = msgState;

     const matches = useMediaQuery('(min-width:1024px)');

    
    useEffect(()=>{
     
      const urlPath=window?.location?.pathname;
      if(props.statusData.status==4){
        console.log("Navbar test urlPath 1=",urlPath);
          if(urlPath.includes("/dashboard"))
            setCurrent("dashboard");

            if(urlPath.includes("/invoices"))
            setCurrent("invoice");

         
         if(urlPath.includes("/activation")){
            setCurrent("dashboard");
            navigate("/dashboard", { replace: true });
         }

         if(urlPath.includes("/profile"))
            setCurrent("");
         


        //  navigate("/dashboard", { replace: true });
      }
      else{
        console.log("Navbar test urlPath 2=",urlPath);
        if(urlPath.includes("/activation"))
          setCurrent("activation");
        else if(urlPath.includes("/profile"))
          navigate("/profile", { replace: true });
        else{
          console.log("Navbar test urlPath 3=",urlPath);
          // navigate("/profile", { replace: true });
          navigate("/activation", { replace: true });
          setCurrent("activation");
        }
        
        console.log("Navbar=",props.statusData)
      }

    },[]);

  
  

   
    const handleMenuClick = (e) => {
      // alert('Click on menu item.',e);
      const urlPath=window?.location?.pathname;
      console.log('click', e);
      if(e.key=="logout"){
        logout();
      } else if(e.key=="dashboard"){
        navigate("/dashboard", { replace: true });
      }
      else if(e.key=="invoice"){
        navigate("/invoices", { replace: true });
      }
      else if(e.key=="profile"){
        navigate("/profile", { replace: true });
        setCurrent("");
      }else if(e.key=="activation"){
        if(!urlPath.includes("/activation"))
        navigate("/activation", { replace: true });
      }
    };

    const menuProps = {
      items:items_profile,
      onClick: handleMenuClick,
    };

    const menuProps_2 = {
      items:items_profile_not_activated,
      onClick: handleMenuClick,
    };

    const menuProps_small = {
      items:items_profile_small_device,
      onClick: handleMenuClick,
    };

    const menuProps_small_not_activated = {
      items:items_profile_not_activated_small_device,
      onClick: handleMenuClick,
    };




    const onClick = (e) => {
      console.log('click ', e,e.key);
      setCurrent(e.key);
      const urlPath=window?.location?.pathname;
      if(e.key=="activation"){
        if(!urlPath.includes("/activation"))
        navigate("/activation", { replace: true });
      }
      else if(e.key=="dashboard")
      navigate("/dashboard", { replace: true });
      else if(e.key=="invoice")
      navigate("/invoices", { replace: true });


    };


    const logout=()=>{
      executeAPI(URL.LOGOUT,"POST",{});
    }
  
/**
 * API response handler  
 */  
      useEffect(() => {
        if(loaded){
          if(responseData!=null){
            localStorage.removeItem("token");
            navigate('/login', { replace: true });
          }
          else if(error!==null){
              setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"info"});
            
            reset();
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[loaded,responseData,error,navigate]);
    
        /**
    * autoclosing of the snackbar msg bar 
    */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

  return (
    <Grid display='flex' sx={{alignItems:'center',width:"100%",backgroundColor: theme.palette.secondaryTheme.shade01,height:"64px",position:'fixed',zIndex:119}}>
    
       
      {matches?
      <>
      <Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',pb:0,pl:2,width:"240px",minWidth:"300px",mr:4}}>
        <a href="https://www.gravitasfactor.com/" target="_blank" style={{marginTop:"5px"}}><img  src={logo} alt="Gravitas Factor Logo" width={40} height={40}/></a>
        <Typography variant="titleMedium" sx={{textAlign:'center',fontSize:"24px",pl:1,color:"#fff"}} >Gravitas Factor</Typography>
      </Box>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#fff',
            colorTextSecondary:"#f00",
            
          },
        }}
      >
    <Menu style={{ width: '50vw',minHeight:"48px",borderBottom:'none',backgroundColor:"#173340",paddingTop:"4px",color:"white" }} onClick={onClick} selectedKeys={[current]} mode="horizontal">
      {props.statusData.status==4?
      <>
      {items_activated.map(item => (
        <Menu.Item key={item.key} style={item.style} disabled={item.disabled}>
            {item.icon ? <span style = {{margin: '5px'}} >{item.icon}</span> : <></>}
            {item.label}
        </Menu.Item>
      ))}
      </>:
      <>
      {items_not_activated.map(item => (
        <Menu.Item key={item.key} style={item.style} disabled={item.disabled}>
            {item.icon ? <span style = {{margin: '5px'}} >{item.icon}</span> : <></>}
            {item.label}
        </Menu.Item>
      ))}
      </>
}

    </Menu>
    

    </ConfigProvider>
    
    <Box sx={{display:'flex',justifyContent:'end',mr:5,width:'40%',alignItems:'center'}}>
        <Typography sx={{mr:2,textTransform:'uppercase',color:"#fff"}}>{userSettings.entity_name}</Typography>
      
     
       {props.statusData.status==4?
      <Dropdown menu={menuProps}>
        <Space>
          
          <Avatar size="large" icon={<UserOutlined  style={{cursor:'pointer'}}/>} />
        </Space>
    </Dropdown>
    :
    <Dropdown menu={menuProps_2}>
      {/* <Button> */}
        <Space>
          <Avatar size="large" icon={<UserOutlined  style={{cursor:'pointer'}}/>} />
        </Space>
      {/* </Button> */}
    </Dropdown>
}
    
    
    
    </Box>
    </>:<>
    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',pb:0,pl:2,width:`calc(100vw - 90px)`,minWidth:"250px",mr:4}}>
        <img src={logo} alt="Gravitas Factor Logo" width={40} height={40}/>
        <Typography variant="titleMedium" sx={{textAlign:'center',fontSize:"24px",pl:2,color:"#fff"}} >Gravitas Factor</Typography>
      </Box>
    <Box sx={{display:'flex',justifyContent:'end',mr:3,width:'80px',alignItems:'center'}}>
    {props.statusData.status==4?
      <Dropdown menu={menuProps_small}>
      
        <Space>
          <MenuOutlined style={{fontSize:"30px",color:"#fff",cursor:'pointer'}}></MenuOutlined>
        </Space>
    </Dropdown>
    :
    <Dropdown menu={menuProps_small_not_activated}>
        <Space>
          <MenuOutlined style={{fontSize:"30px",color:"#fff",cursor:'pointer'}}></MenuOutlined>
        </Space>
    </Dropdown>
}
      {/* <MenuOutlined style={{fontSize:"30px",color:"#fff"}}></MenuOutlined> */}
    </Box>
    
    </>}
    
   
    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
         <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
            {msg}
          </Alert>  
        </Snackbar> 
    </Grid>
    

  )
}

export default Navbar