import React,{useEffect,useState} from 'react'
import {Link, Grid } from "@mui/material"
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { CircularProgress,Snackbar,Alert,Box,Typography} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import '../dashboard/styles/styles.scss';
import { Tooltip,Table } from 'antd';

const columnsAvgCustomerPaymentTime = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: '1',
        minWidth: "120px"
        
    },
    {
        title: 'Total Payment',
        dataIndex: 'total_payment',
        key: '2',
        minWidth: "120px"
        
    },
    {
        title: 'Avg. Turnaround Time(hrs)',
        dataIndex: 'avg_time_turnaround',
        key: '3',
        minWidth: "120px"
        
    },
   
]

const columnsPaymentHistory = [
    {
        title: 'Invoice #',
        dataIndex: 'invoice',
        key: '1',
        minWidth: "120px",
        width:"120px"
        
    },
    {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        key: '2',
        minWidth: "120px",
        width:"130px"
        
    },
   
    {
        title: 'Payment Due Date by Customer',
        dataIndex: 'payment_due_date_by_customer',
        key: '3',
        minWidth: "120px",
        width:"140px"
        
    },
    {
        title: 'Invoice Amount',
        dataIndex: 'invoice_amount',
        key: '4',
        minWidth: "120px",
        width:"120px"
        
    },
    {
        title: 'Amount Paid by Customer',
        dataIndex: 'amount_paid_by_customer',
        key: '5',
        minWidth: "120px",
        width:"120px"
        
    },
   
]


const MainPage = () => {
    const [isTabLoading, setIsTabLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [tabData,setTabData]=useState();
    const [dashboardData,setDashboardData]=useState();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTabVal,setSelectedTabVal]=useState("All");

    const [cancelTan, responseDataTab, errorTab, loadedTab, resetTab, executeAPITab] = useAxiosWithAuth();
    const [cancel, responseData, error, loaded, reset, executeAPI] = useAxiosWithAuth();

    const [msgState, setMsgState] = useState({
        open: false,
        msg:"" ,
        severity:"info"
      });
     const { open, msg,severity } = msgState;

    React.useEffect(() => {
        document.title = "Dashboard";
        executeAPITab(URL.DASHBOARD_COMBO_DATA,"GET",null);
        // executeAPI(URL.DASHBOARD_DATA,"GET",null);
    
      }, []);

       /**
     * API response handler  year range data
    */  
    useEffect(() => {
        if(loadedTab){
       
        if(responseDataTab!=null){
            setTabData(responseDataTab);
            // setSelectedTabVal(responseDataTab[0].label);
            setIsTabLoading(false);
            executeAPI(URL.DASHBOARD_DATA,"GET",null);
    
        }
        else if(errorTab!==null){
            // setIsLoading(false);
            console.log("Error data=",errorTab);
            setMsgState({open:true,msg:errorTab?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        
            //if unauthorized then redirec it to login page
            if(errorTab?.response?.status === 401 || errorTab?.response?.status === 403){
                console.log("status received =",errorTab?.response?.status)
                 navigate("/login", { replace: true });
            }
        }
        resetTab();
        }
        },[loadedTab,responseDataTab]);

         /**
     * API response handler  status API 
    */  
    useEffect(() => {
        if(loaded){
       
        if(responseData!=null){
            setDashboardData(responseData);
            setIsLoading(false);
           
    
        }
        else if(error!==null){
            setIsLoading(false);
            console.log("Error data=",error);
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                 navigate("/login", { replace: true });
            }
        }
        reset();
        }
        },[loaded,responseData]);

        const onTabChange = (event) => {

            setSelectedTabVal(event.target.value);
            let index=tabData.findIndex(item=>item.label==event.target.value);
            // console.log("Test ",tabData[index],`${URL.DASHBOARD_COMBO_DATA}?created_date_gte=${tabData[index].start_date}&created_date_lte=${tabData[index].end_date}`);
            if(index!=-1){
                if(index==0)
                    executeAPI(`${URL.DASHBOARD_DATA}`,"GET",null);
                else
                    executeAPI(`${URL.DASHBOARD_DATA}?created_date__gte=${tabData[index].start_date}&created_date__lte=${tabData[index].end_date}`,"GET",null);
            }
        }   

    /**
    * autoclosing of the snackbar msg bar 
    */ 
    const handleClose = (event, reason) => {
        setMsgState({...msgState,open:false});
    };


    return (
        <>

        <Grid component="main" container display="flex" flexDirection="column" sx={{height: `calc(100vh - 64px)`,marginTop:"48px",zIndex:89 }}>
            {/* {!isTabLoading?
            <>
           
            <Box sx={{ width:"300px",mt:4,ml:4}}>
                        <FormControl fullWidth size="small" >
                        <InputLabel  sx={{backgroundColor: '#fff'}} id="demo-select-small-label">Select Financial Year</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                // required
                                value={selectedTabVal}
                                label="Entity Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                // onChange={onEntityChange}
                                onChange={(event) => onTabChange(event)}
                            >
                             {tabData.map((item,index) => (
                                    <MenuItem value={item.label} key={item.label}>{item.label}</MenuItem>
                                ))}    
                            </Select>
                           
       
                        </FormControl>
                        </Box>
                    
                
            </>:
            <>
            </>
            } */}
            {!isLoading && !isTabLoading?
            <>
             <Grid container spacing={2} sx={{mt:1,mb:2}} >
             <Grid item mobile={12}  display="flex" flexDirection='row'>
             <Box sx={{ width:"300px",mt:4,ml:4}}>
                        <FormControl fullWidth size="small" >
                        <InputLabel  sx={{backgroundColor: '#fff'}} id="demo-select-small-label">Select Financial Year</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                // required
                                value={selectedTabVal}
                                label="Entity Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                // onChange={onEntityChange}
                                onChange={(event) => onTabChange(event)}
                            >
                             {tabData.map((item,index) => (
                                    <MenuItem value={item.label} key={item.label}>{item.label}</MenuItem>
                                ))}    
                            </Select>
                           
       
                        </FormControl>
                        </Box>
             </Grid>
                <Grid item mobile={12} tablet={10} laptop={4} display="flex" flexDirection='row'>
                    <Box sx={{height:"160px",width:"100%", backgroundColor:"#338091",mt:2,ml:2,mr:2,borderRadius:6,display:"flex",flexDirection:'column',alignItems:"center"}}>
                    <Box display="flex" sx={{ml:2,mt:2}} >
                        <Typography sx={{color:"#fff",fontSize:"18px"}}>
                            Funding Turnaround Time
                        </Typography>
                        <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>The average time to process and release funds to the supplier after invoice submission.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}} >
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px",marginTop:"2px", cursor: "pointer",color:"#fff"}} />
                        </Tooltip>
                        </Box>
                        <Typography sx={{color:"#fff",fontSize:"40px",ml:2,mt:2}}>
                            {dashboardData.avg_funding_turnaround?dashboardData.avg_funding_turnaround+" hrs":"NA"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item mobile={12} tablet={10} laptop={4} display="flex" flexDirection='row'>
                <Box sx={{height:"160px",width:"100%", backgroundColor:"#338091",mt:2,ml:2,mr:2,borderRadius:6,display:"flex",flexDirection:'column',alignItems:"center"}}>
                    <Box display="flex" sx={{ml:2,mt:2}} >
                        <Typography sx={{color:"#fff",fontSize:"18px"}}>
                            Invoice Discrepancy Rate
                        </Typography>
                        <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>The percentage of invoices that encounter discrepancies or issues during the factoring process.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}} >
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px",marginTop:"2px", cursor: "pointer",color:"#fff"}} />
                        </Tooltip>
                        </Box>
                        <Typography sx={{color:"#fff",fontSize:"40px",ml:2,mt:2}}>
                            {dashboardData.invoice_discrepancy_rate?dashboardData.invoice_discrepancy_rate.toFixed(2)+" %":"NA"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item mobile={12} tablet={10} laptop={4} display="flex" flexDirection='row'>
                <Box sx={{height:"160px",width:"100%", backgroundColor:"#338091",mt:2,ml:2,mr:2,borderRadius:6,display:"flex",flexDirection:'column',alignItems:"center"}}>
                    <Box display="flex" sx={{ml:2,mt:2}} >
                        <Typography sx={{color:"#fff",fontSize:"18px"}}>
                            Days Sales Outstanding (DSO)
                        </Typography>
                        <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>The average number of days it takes for the supplier to receive payment from customers.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}} >
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px",marginTop:"2px", cursor: "pointer",color:"#fff"}} />
                        </Tooltip>
                        </Box>
                        <Typography sx={{color:"#fff",fontSize:"40px",ml:2,mt:2}}>
                            {dashboardData.dso?dashboardData.dso+" days":"NA"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item mobile={12} display="flex" flexDirection='row'>
                <div style ={{ width: "100%",marginLeft:"30px",marginRight:"16px",marginTop:"32px"}} >
                        <Typography sx={{mt:0,fontSize:20,mb:2}}>Average Customer Payment Time:</Typography>
                        <Table 
                            scroll={{ y: 320 }}
                            dataSource={dashboardData.avg_customer_payment_time} 
                            columns={columnsAvgCustomerPaymentTime} 
                            bordered 
                            pagination={false}
                            // style = {{ border: '2px solid black'}}
                            />
                    </div>
                </Grid>
                <Grid item mobile={12} display="flex" flexDirection='row'>
                    <div style ={{ width: "calc(100% - 20px)",marginLeft:"30px",marginRight:"16px",marginTop:"32px",marginBottom:"20px"}} >
                        <Typography sx={{mt:0,fontSize:20,mb:2}}>Customer Payment History:</Typography>
                        <Table 
                            scroll={{ y: 320 }}
                            dataSource={dashboardData.customer_history} 
                            columns={columnsPaymentHistory} 
                            bordered 
                            pagination={false}
                            // style = {{ border: '2px solid black'}}
                            />
                    </div>  
                    </Grid>
             </Grid>
            </>:
            <>
             <div style={{ textAlign: "center", width: "100%" }}>
                    <CircularProgress sx={{ marginTop: "10%" }} />
                </div>
            </>
            }
            
        </Grid>
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>
    )
}

export default MainPage
