import * as React from 'react';
import { useState,useRef,useEffect } from 'react';

import { Col, Row, Timeline, Input, InputNumber,Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { Formik, Form, Field,ErrorMessage} from 'formik';
import {IconButton,Alert, Snackbar,Button,CircularProgress,Accordion,AccordionSummary,AccordionDetails,useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateAddressDetailsForm,validateBankDetailsForm} from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CheckCircleFilled ,UploadOutlined} from '@ant-design/icons';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import { Add } from '@mui/icons-material';
import axios, { post } from 'axios';
import Files from 'react-files';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const initialValues = {
    bank_name:'',
    bank_branch_name:'',
    account_name:'',
    ifsc_code:'',
    bank_account_type:'',
    bank_account_number:'',
    confirm_bank_account_number:''

  };

  const initialValuesAD = {
    address_1:'',
    pincode_1:'',
    city_1:"",
    state_1:"",                            
    email_1: '',
    mobile_1:'',
    address_2:'',
    pincode_2:'',
    city_2:"",
    state_2:"",                            
    email_2: '',
    mobile_2:'',
    
  };
  
  
const Profile = () => {
    
    const [entityType, setEntityType] = useState();
    const [isLoading,setIsLoading]=useState(true);
    const [isLoadingAddress,setIsLoadingAddress]=useState(true);
    // const [form] = Form.useForm();
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelSave,responseDataSave,errorSave,loadedSave,resetSave,executeAPISave] = useAxiosWithAuth();
    const [cancelAddress,responseDataAddress,errorAddress,loadedAddress,resetAddress,executeAPIAddress] = useAxiosWithAuth();
    const [cancelSaveAddress,responseDataSaveAddress,errorSaveAddress,loadedSaveAddress,resetSaveAddress,executeAPISaveAddress] = useAxiosWithAuth();
   
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const formikRef = useRef();
    const formikRefAddress = useRef();
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
   const { open, msg,severity } = msgState;
   const [companyDetails,setCompanyDetails]=useState(initialValues);
   const [statusData,setStatusData]=useState();


   const [expanded, setExpanded] = React.useState(false);
   const [addressDetails,setAddressDetails]=useState(initialValuesAD);
   const [bankDetails,setBankDetails]=useState(initialValues);
   const theme=useTheme();
    const [rememberMe, setRememberMe] = useState(false);
    useEffect(() => {
        document.title = "Profile";
         }, []);


   const handleTabChange = (panel) => (event, isExpanded) => {
     setExpanded(isExpanded ? panel : false);
   };
    
   const onEntityChange = (event) => {
        setEntityType(event.target.value);
        formikRef.current.setFieldValue("bank_account_type",event.target.value);

    }


    useEffect(()=>{
        executeAPI(URL.ACTIVATION_BANK_DETAILS,"GET",null);
        executeAPIAddress(URL.ACTIVATION_ADDRESS_DETAILS,"GET",null);
        
    },[])

   
   

   

 /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

   /**
     * API response handler  
    */  
useEffect(() => {
    if(loaded){
    console.log("loaded=true",responseData,responseData.bank_account_type);
    setIsLoading(false);
    if(responseData!=null && responseData?.bank_account_type){
        let data=responseData;
        data["confirm_bank_account_number"]=responseData.bank_account_number;
        setBankDetails(responseData);
        setEntityType(responseData.bank_account_type);

        
        
    }
    else if(error!==null){
        console.log("Error data=",error);
    
    
        //if unauthorized then redirec it to login page
        if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});

    }
    reset();
    }
    },[loaded,responseData]);
  

 



   /**
    * API caller  
    */  
   const onSubmit = (values, props) => {
    console.log("onSubmit=",JSON.stringify(values));
    // setAPIError(undefined);
    executeAPISave(URL.ACTIVATION_BANK_DETAILS,"POST",JSON.stringify(values)); 
   }

 

   /**
     * API response handler Save detailes
    */  
   useEffect(() => {
    if(loadedSave){
    console.log("Save data response",responseDataSave);
    if(responseDataSave!=null){
        formikRef?.current.setSubmitting(false);
        setMsgState({open:true,msg:"Bank details saved successfully.",severity:"success"});
        setTimeout(()=>{
            // navigate("/activation/address", { replace: true });
        },2000);
       
        
    }
    else if(errorSave!==null){
        formikRef?.current.setSubmitting(false);
        console.log("Error data=",errorSave);
       
    
        //if unauthorized then redirec it to login page
        if(errorSave?.response?.status === 401 || errorSave?.response?.status === 403){
            console.log("status received =",errorSave?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
    
    }
    resetSave();
    }
    },[loadedSave,responseDataSave]);


  /**
   * Address details
   */
   /**
     * API response handler  
    */  
   useEffect(() => {
    if(loadedAddress){
    console.log("loaded=true",responseDataAddress);
    
    if(responseDataAddress!=null){
    if(responseDataAddress && responseDataAddress[0]&& responseDataAddress["1"].company_address){
       
        let tempData={
            address_1:responseDataAddress[0].company_address,
            pincode_1:responseDataAddress[0].pincode,
            city_1:responseDataAddress[0].city,
            state_1:responseDataAddress[0].state,                            
            email_1: responseDataAddress[0].email,
            mobile_1:responseDataAddress[0].mobile,
            address_2:responseDataAddress[1].company_address,
            pincode_2:responseDataAddress[1].pincode,
            city_2:responseDataAddress[1].city,
            state_2:responseDataAddress[1].state,                            
            email_2: responseDataAddress[1].email,
            mobile_2:responseDataAddress[1].mobile,
        }
        setAddressDetails(tempData);
    }

    setIsLoadingAddress(false);  
        
    }
    
    else if(errorAddress!==null){
        setIsLoadingAddress(false);
        console.log("Error data=",errorAddress);
        //if unauthorized then redirec it to login page
        if(errorAddress?.response?.status === 401 || errorAddress?.response?.status === 403){
            console.log("status received =",errorAddress?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorAddress?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
    }
    reset();
    }
    },[loadedAddress,responseDataAddress]);

 /**
* API caller  
*/  
const onSubmitAddress = (values, props) => {
console.log("onSubmit=",JSON.stringify(values));
// setAPIError(undefined);

let data_1={
    "city": values.city_1,
    "pincode": values.pincode_1,
    "state": values.state_1,
    "mobile": values.mobile_1,
    "email": values.email_1,
    "company_address": values.address_1
}
let data_2={
    "city": values.city_2,
    "pincode": values.pincode_2,
    "state": values.state_2,
    "mobile": values.mobile_2,
    "email": values.email_2,
    "company_address": values.address_2
}

let jsonData={
    "1":data_1,
    "2":data_2

}

console.log('jsonData=',jsonData,JSON.stringify(jsonData));
// return;


executeAPISave(URL.ACTIVATION_ADDRESS_DETAILS,"POST",JSON.stringify(jsonData));    
}

/**
 * API response handler Save detailes
*/  
useEffect(() => {
if(loadedSaveAddress){
console.log("Save data response",responseDataSaveAddress);
if(responseDataSaveAddress!=null){
    setMsgState({open:true,msg:"Address details saved successfully.",severity:"success"});
    setTimeout(()=>{
        // navigate("/activation/status", { replace: true });
    },2000);

}
else if(errorSaveAddress!==null){
    formikRefAddress?.current.setSubmitting(false);
    console.log("Error data=",errorSaveAddress);
    //if unauthorized then redirect it to login page
    if(errorSaveAddress?.response?.status === 401 || errorSaveAddress?.response?.status === 403){
        console.log("status received =",errorSaveAddress?.response?.status)
        navigate("/login", { replace: true });
    }
    setMsgState({open:true,msg:errorSaveAddress?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
    ,severity:"error"});
}
resetSave();
}
},[loadedSaveAddress,responseDataSaveAddress]);

const handleCheckChange=(val)=>{
    // console.log("handleCheckChange=",val,formikRef.current.values.address_1,formikRef.current.errors);
    if(val){
            formikRef.current.setFieldValue("address_2",formikRef.current.values.address_1);
            formikRef.current.setFieldValue("pincode_2",formikRef.current.values.pincode_1);
            formikRef.current.setFieldValue("city_2",formikRef.current.values.city_1);
            formikRef.current.setFieldValue("state_2",formikRef.current.values.state_1);
            formikRef.current.setFieldValue("email_2",formikRef.current.values.email_1);
            formikRef.current.setFieldValue("mobile_2",formikRef.current.values.mobile_1);
    }
  }

const handleFormChange = (event) => {
    // console.log("Form::onChange", event);
    if(rememberMe){
        formikRef.current.setFieldValue("address_2",formikRef.current.values.address_1);
        formikRef.current.setFieldValue("pincode_2",formikRef.current.values.pincode_1);
        formikRef.current.setFieldValue("city_2",formikRef.current.values.city_1);
        formikRef.current.setFieldValue("state_2",formikRef.current.values.state_1);
        formikRef.current.setFieldValue("email_2",formikRef.current.values.email_1);
        formikRef.current.setFieldValue("mobile_2",formikRef.current.values.mobile_1);
}
};

    return (
        <div style={{ width: '100vw',display:"flex",justifyContent:"center",backgroundColor:"#fff",marginTop:"64px"  }} >
            {!isLoading && !isLoadingAddress?
            <>
            {/* <Navbar /> */}

           
               
                
               

                    {/* content for column 2 goes here */}
                    <div style={{ height: `calc(100vh - 64px)`, width:"100vw",maxWidth:"900px" ,display: 'flex', direction: 'verticle', alignItems: 'flex-start', justifyContent: 'center',backgroundColor:"#f1f1f1" }}>

                        <div style={{ width: "calc(100% - 20px)",paddingLeft:"20px",paddingRight:"20px" }} >
                            <Box sx={{mt:6,mb:4}}>
                                <Typography  variant="titleMedium" sx={{fontSize:"24px"}}>
                                    Profile Details:
                                </Typography>
                            </Box>

                            <Accordion expanded={expanded === 'panel1'} onChange={handleTabChange('panel1')}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <Typography sx={{ width: '80%', flexShrink: 0,fontSize:"20px" }}>
                                    Bank Details:
                                </Typography>
                                {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                                </AccordionSummary>
                                <AccordionDetails>
                                <Formik enableReinitialize={true} innerRef={formikRef} initialValues={bankDetails} onSubmit={onSubmit} validate={validateBankDetailsForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form>
                 
          
                  <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:2,mb:3}}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="bank_name"
                        name="bank_name"
                        type="text"
                        label="Settlement Bank Name"
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                    <Grid item mobile={12} tablet={6}>
                        <FormControl fullWidth size="small" required disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}>
                        <InputLabel sx={{backgroundColor: '#fff'}} id="demo-select-small-label">Account Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                required
                                value={entityType}
                                label="Account Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                // onChange={onEntityChange}
                                onChange={(event) => onEntityChange(event)}
                                name="bank_account_type"
                            >
                                <MenuItem value={1}>Current</MenuItem>
                                <MenuItem value={2}>Saving</MenuItem>
                                {/* <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                        </FormControl>
                    
                    </Grid>
                    
                </Grid>
                </Box>

                <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="bank_branch_name"
                        id="bank_branch_name"
                        type="text"
                        label="Bank Branch"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="ifsc_code"
                        name="ifsc_code"
                        type="text"
                        label="IFSC Code"
                        // placeholder="dd/mm/YYYY"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                  </Grid> 
                   
              </Grid>
              </Box>

              <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="account_name"
                        id="account_name"
                        type="text"
                        label="Name as per Bank Account"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    
                   
              </Grid>
              </Box>

                
                <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="bank_account_number"
                        id="bank_account_number"
                        type="text"
                        label="Bank Account Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="confirm_bank_account_number"
                        id="confirm_bank_account_number"
                        type="text"
                        label="Confirm Bank Account Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                   
              </Grid>
              </Box>


              <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
             
                <Button type='submit'  sx={{mb:2,mr:1, mt:1,height:"50px",width:"240px"}} variant="contained" disabled={isSubmitting|| (statusData?.status && (statusData?.status==2|| statusData?.status==4))}
                      >{isSubmitting ? "Loading" : "Update"}</Button>
                  
                
              </Grid>
              <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
              {statusData?.status && (statusData?.status==2|| statusData?.status==4)?
                 <Typography  variant="titleMedium" sx={{fontSize:"16px"}}>
                    Account verification is under review.
                    </Typography>:
                    <></>

                 }  
                 </Grid>

          </Form>
          )}
        </Formik>
                                </AccordionDetails>
                            
                            </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleTabChange('panel2')}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    >
                                     <Typography sx={{ width: '80%', flexShrink: 0,fontSize:"20px"}}>Address Details:</Typography>
                                    {/* <Typography sx={{ color: 'text.secondary' }}>You are currently not an owner</Typography> */}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Formik enableReinitialize={true} innerRef={formikRefAddress} initialValues={addressDetails} onSubmit={onSubmitAddress} validate={validateAddressDetailsForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form 
                // onChange={handleFormChange}
                >
                 <Box display="flex"  sx={{fontSize:"16px",mt:2}}>
                 <Typography  variant="titleMedium" sx={{fontSize:"18px",mt:2}}>
                    
                 Company Registeration Address
                </Typography>
                </Box>
                  <Box display="flex" >
                    
                  <Grid container spacing={2} sx={{mt:1,mb:2}}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="address_1"
                        name="address_1"
                        type="text"
                        label="Address"
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                       
                        />
                    </Grid>
                    
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="pincode_1"
                        name="pincode_1"
                        type="text"
                        label="Pin Code"
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                </Grid>
                </Box>

                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="city_1"
                        id="city_1"
                        type="text"
                        label="City"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="state_1"
                        id="state_1"
                        type="text"
                        label="State"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                  </Grid> 
                   
              </Grid>
              </Box>

                
                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="mobile_1"
                        id="mobile_1"
                        type="text"
                        label="Mobile Number"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="email_1"
                        name="email_1"
                        type="text"
                        label="Email Address"
                        required
                        onKeyUp={handleFormChange}
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                  </Grid> 
                   
              </Grid>
              </Box>
              

             
              <Box display="flex" sx={{ mt: 2 }} justifyContent="flex-start" alignItems="center">
              <Typography  variant="titleMedium" sx={{fontSize:"18px"}}>
                    Communicatiion Address
              </Typography>

                <Field as={FormControlLabel}
                    name='remember_me'
                    size='small'
                    disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    control={<Checkbox 
                      checked={rememberMe}
                      onChange={()=>{
                        setRememberMe(!rememberMe);
                        handleCheckChange(!rememberMe);
                    }}
                      sx={{
                        ml:2,
                        color: theme.palette.primaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.primaryTheme.shade03,
                        },
                      }}/>
                    }
                    label={<Typography variant="linkSupportText" >
                        Same as above
                      </Typography>
                    }
                />
                </Box>
                <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:0,mb:2}}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="address_2"
                        name="address_2"
                        type="text"
                        label="Address"
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="pincode_2"
                        name="pincode_2"
                        type="text"
                        label="Pin Code"
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                </Grid>
                </Box>

                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="city_2"
                        id="city_2"
                        type="text"
                        label="City"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                    <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="state_2"
                        id="state_2"
                        type="text"
                        label="State"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                  </Grid> 
                   
              </Grid>
              </Box>

                
                <Box display="flex" >
                <Grid container spacing={2} mb={2}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="mobile_2"
                        id="mobile_2"
                        type="text"
                        label="Mobile Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="email_2"
                        name="email_2"
                        type="text"
                        label="Email Address"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                  </Grid> 
                   
              </Grid>
              </Box>
              

             
              
               <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
                <Button type='submit'  sx={{mb:2,mr:1, mt:4,height:"50px",width:"240px"}} variant="contained" disabled={isSubmitting||(statusData?.status && (statusData?.status==2|| statusData?.status==4))}
                      >{isSubmitting ? "Loading" : "Update"}</Button>
              </Grid>
              <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
              {statusData?.status && (statusData?.status==2|| statusData?.status==4)?
                 <Typography  variant="titleMedium" sx={{fontSize:"16px"}}>
                    Account verification is under review.
                    </Typography>:
                    <></>

                 }  
                 </Grid>

          </Form>
          )}
        </Formik>
                                    </AccordionDetails>
                            </Accordion>
                            

       
                        
                        </div>

                    </div>
               
            <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>:
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/></div>}
        </div>
    )
}

export default Profile