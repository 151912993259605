import {Routes as ReactRoutes, Route, Navigate } from "react-router-dom";
import React,{useEffect} from "react";

import RequireAuth from "./RequireAuth";
import Login from "../../pages/login-register/login";
import Home from "../../pages/dashboard";
import Unauthorized from "../../components/Unauthorized";
import PersistLogin from "../../components/PersistLogin";
import Missing from "../../components/Missing";
import Register from '../../pages/login-register/register';
import FORGOT_PASSWORD from "../../pages/login-register/forgot-password/forgot_password";
import FORGOT_PASSWORD_VERIFICATION from "../../pages/login-register/forgot-password/forgot_password_verfication";
import RESET_PASSWORD from "../../pages/login-register/forgot-password/reset-password"; 
import EMAIL_VERIFICATION from "../../pages/login-register/register/email_verification"; 
import ACTIVATE_USER from "../../pages/login-register/register/activate_user";
import HelpPage from "../../pages/dashboard/help_page";
import { ALLOWED_ROLES_ADMIN,ALLOWED_ROLES_ALL,ALLOWED_ROLES_PRO_1,ALLOWED_ROLES_PRO_2,ALLOWED_ROLES_FAST_DATA } from "../../common/utility/constant";
import UserManualPage from "../../pages/dashboard/user_manual";
import Subscription from "../../pages/dashboard/subscription";

import Pricing from "../../pages/dashboard/pricing";
import FaqPage from "../../pages/dashboard/faq";
import MainPage from "../../pages/dashboard/main";
import Company_Details_Page from "../../pages/dashboard/Company_Details_Page";
import KYC_Form from "../../pages/dashboard/KYC_Form";
import Bank_Details_Page from "../../pages/dashboard/Bank_Details_Page";
import Address_Page from "../../pages/dashboard/Address_Page";
import Status_Page from "../../pages/dashboard/Status_Page";
import ActivationPage from "../../pages/dashboard/activation";
import AddRequest from "../../pages/dashboard/add_request";
import Profile from "../../pages/dashboard/profile";
import INVOICES from "../../pages/dashboard/invoices";

/**
 * 
 * @returns component for the specific route w.r.tp the app base url
 */
const Routes = () => {

  useEffect(() => {
   console.log("pageview",window.location.pathname + window.location.search);
  }, [window.location.pathname,window.location.search]);

  return (
    <>
      <ReactRoutes>
       {/* public routes  */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/email-verification" element={<EMAIL_VERIFICATION/>}/>
      <Route path="/activate-user" element={<ACTIVATE_USER/>}/>
      <Route path="/forgot-password" element={<FORGOT_PASSWORD />} />
      <Route path="/forgot-password-verification" element={<FORGOT_PASSWORD_VERIFICATION />} />
      <Route path="/reset-password" element={<RESET_PASSWORD />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/pricing" element={<Pricing />} />
      {/* <Route path="/help" element={<HelpPage />} /> */}
      <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/discounted-invoices" element={<MainPage />} />
        </Route> 
      
      {/* Protected routes accessible only when accessToken is present else redirect to login page */}
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
        <Route path="/" element={(<Navigate replace to="/dashboard" />)} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/dashboard" element={<MainPage />} />
        </Route> 
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/invoices" element={<INVOICES />} />
        </Route> 
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/activation" element={<ActivationPage />} />
        </Route> 
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/activation/company-details" element={<Company_Details_Page />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/activation/kyc" element={<KYC_Form />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/activation/bank-details" element={<Bank_Details_Page />} />
        </Route>  
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/activation/address" element={<Address_Page />} />
        </Route> 

      <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/activation/status" element={<Status_Page />} />
        </Route> 
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/add-request" element={<AddRequest />} />
        </Route> 

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/profile" element={<Profile />} />
        </Route> 
        
      </Route>
      
      {/* incase of url not matched redirect to page not found */}
      <Route path="*" element={<Missing />} />
      
      
      </ReactRoutes>
    </>
  );
};

export default Routes;

