import * as React from 'react';
import { useState,useRef,useEffect } from 'react';

import { Col, Row, Timeline, Input, InputNumber,Tooltip,Table } from 'antd';
// import { Link } from 'react-router-dom';

import { Formik, Form, Field,ErrorMessage} from 'formik';
import {IconButton,Alert, Snackbar,Button,CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateInvoiceRequestForm } from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CheckCircleFilled ,UploadOutlined} from '@ant-design/icons';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import { Add } from '@mui/icons-material';
import axios, { post } from 'axios';
import Files from 'react-files';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';




const { Option } = Select;
let initialValues = {
"invoice_due_date": "",
"date_of_invoice": "",
"supplier_name": "",
"supplier_gst": "",
"customer_name": "",
"customer_gst": "",
"invoice_number": "",
"purchase_order": "",
"discount_type": "",
"original_invoice_amount": "",
"max_discount_percentage": "",
"invoice_amount_after_discount": "",
}

const style = {
    position: 'absolute',
    top: '10%',
    left: '10%',
    height: "80%",
    //   transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius:4,
    p: 4,
    overflow: 'scroll',
  };

  const styleSmall = {
    position: 'absolute',
    top: '20%',
    left: '10%',
    height: "40%",
    //   transform: 'translate(-50%, -50%)',
    width: "80%",
    borderRadius:4,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
  };
  
  
const InvoiceInfo = (props) => {

    const [isLoading,setIsLoading]=useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
   const { open, msg,severity } = msgState;
   const [openModal, setOpenModal] = useState(true);
   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => {
     props.closeModal();
     setOpenModal(false)
   };
   const columns = [
    // {
    //     title: 'Created Date',
    //     dataIndex: 'created_date',
    //     key: '1',
        
    // },
    {
        title: 'Payment Amount(INR)',
        dataIndex: 'payment_amount',
        key: '2',
        render: (text) => `${text.toLocaleString()}`,
        
    },
    {
        title: 'Schedule Date of Payment',
        dataIndex: 'date_of_payment',
        key: '3',
        
    },
    {
        title: 'Is Paid',
        dataIndex: 'payment_done',
        key: '4',
        render: (text) => `${text == true ? "Yes" : text == false ? "No": "NA"}`,
       
        
    }
    ]
 

  
    /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };


  useEffect(()=>{
    console.log("Invoice record=",props.record);
  },[])
  
  const getStatusText=(val)=>{
    if(val==1){
        return "Pending"
    }else if(val==2){
        return "Ineligible"
    }else if(val==3){
        return "Accepted"
    }else if(val==4){
        return "Declined"
    }else{
        return "NA"
    }
}

const getMsgText=(val)=>{
    if(val==1){
        return "Thank you for submitting your invoice for Invoice Factoring. Our team is diligently assessing your invoice and will update the status of the invoice soon."
    }else if(val==2){
        return props.record.error_message

    }else if(val==3){
        return ""
        // return "Your request has beed accepted."
    }else if(val==4){
        return props.record.error_message
    }else{
        return "NA"
    }
}

const getRemainingAmount=()=>{
    let total =0;
    for(let i=0;i<props.record.payment_schedule.length;i++){
        if(props.record.payment_schedule[i].payment_done)
        total=total+props.record.payment_schedule[i].payment_amount;
    }
    return props.record.invoice_amount_after_discount-total;

}
  
const editRequest=()=>{
    props.editRequest(props.record);
}

    return (
        <div style={{ width: '100vw',display:"flex",justifyContent:"center",backgroundColor:"#fafafa" }} >
            {!isLoading?
            <>
            
            <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <CancelOutlinedIcon
            onClick={() => handleCloseModal()}
            sx={{
              ml: 0, mt: 0, top: props.record.status==3?'10%':"20%",
              left: '10%', height: 34, width: 36, cursor: 'pointer', position: 'fixed', zIndex: 9999
            }}
          />
                        <Box sx={props.record.status==3? style:styleSmall} >
                            <Box sx={{mt:2}}>
                                <Box sx={{display:'flex',flexDirection:"row"}}>
                                    <Typography  variant="titleMedium" sx={{fontSize:"28px",mb:2}}>
                                    Status:
                                    </Typography>
                                    <Typography  variant="titleMedium" sx={{fontSize:"28px",mb:2,ml:1,color:"error"}}>
                                        {getStatusText(props.record.status)}
                                    </Typography>
                                </Box>
                                
                                <Box sx={{mt:2,display:'flex',flexDirection:"column"}}>
                                    <Typography variant="titleMedium" sx={{fontSize:"18px",mb:4}}>
                                        {getMsgText(props.record.status)}
                                    </Typography>
                                    {props.record.status==4?
                                    <Link  onClick={editRequest} sx={{cursor:"pointer",fontSize:"18px"}} variant="link">Update Request</Link>
                                    :<></>}
                                </Box>

                                 {props.record.status==3 ?   
                                 <Box sx={{mt:2,display:'flex',flexDirection:"column"}}>
                                    <>
                                    <Box sx={{mt:2,display:'flex',flexDirection:"column",background:"#f1f1f1"}}>
                                        <Box sx={{display:'flex',flexDirection:'row'}}>
                                            <Typography variant="titleMedium" sx={{fontSize:"18px",mb:2,ml:2,mt:2,width:"210px",fontWeight:600}}>
                                                Total Payment  
                                            </Typography> 
                                            <Typography variant="titleMedium" sx={{fontSize:"18px",mb:2,ml:2,mt:2}}>
                                                    INR&nbsp;{props.record.original_invoice_amount.toLocaleString()}
                                            </Typography>
                                        </Box>
                                        {/* <Box sx={{display:'flex',flexDirection:'row'}}>
                                            <Typography variant="titleMedium" sx={{fontSize:"18px",mb:2,ml:2,mt:0,width:"210px",fontWeight:600}}>
                                                Remaining Amount 
                                            </Typography> 
                                            <Typography variant="titleMedium" sx={{fontSize:"18px",mb:2,ml:2,mt:0}}>
                                                    INR&nbsp;{getRemainingAmount().toLocaleString()}
                                            </Typography>
                                        </Box> */}

                                    </Box>
                                    {props.record.payment_schedule && props.record.payment_schedule.length>0?
                                     <Box sx={{mt:2,display:'flex',flexDirection:"column"}}>
                                         <Table 
                            scroll={{ y: 320 }}
                            dataSource={props.record.payment_schedule} 
                            columns={columns} 
                            bordered 
                            pagination={false}
                            // style = {{ border: '2px solid black'}}
                            />
                           

                                    </Box>:
                                    <>
                                    {/* <Typography variant="titleMedium" sx={{fontSize:"18px",mb:4}}>
                                        We are working on your request. We will update the payment schedule soon.
                                    </Typography> */}
                                    </>
                                    }  
                                     </>   
                                    </Box>:
                                    <>
                                    </>
                                    }  
                                   

                            
                            </Box>
                    
                    </Box>
                    </>
                    </Modal>
               
            <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>:
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/></div>}
        </div>
    )
}

export default InvoiceInfo