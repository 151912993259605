import * as React from 'react';
import { useState,useRef,useEffect } from 'react';
import Button from '@mui/material/Button';
import {IconButton,Alert, Snackbar } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { Formik, Form, Field,ErrorMessage} from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiTextFieldFormik } from '../../../components/CustomTextField/TextField';
import { validateRegisterationForm } from '../../../common/utility/validators';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import WelcomeLayout from '../components/welcome-layout';
import FormContainer from '../components/form-container';
import { useTheme } from '@mui/material';
import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';
import TermsOfUseModal from '../../dashboard/components/terms_of_use';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../../../assets/images/logo.png';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';


const initialValues = {
  entity_name:'',
  email: '',
  password: '',
  confirm_password:'',
  mobile:'',
  terms: false
};

export default function RegisterUser() {
  const theme=useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const formikRef = useRef();
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
 const { open, msg,severity } = msgState;

 const handleClickShowPassword = () => setShowPassword(!showPassword);
 const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
 
 const [apiError,setAPIError]=useState(undefined);
 const matches = useMediaQuery('(min-width:640px)');
 useEffect(() => {
  document.title = "Register";
   }, []);

  
/**
 * API response handler  
 */  
  useEffect(() => {
  
    if(loaded){
      if(responseData!=null){
        // navigate(`/email-verification?emailId=${formikRef.current.values.email_id}`, { replace: true });
        setMsgState({open:true,msg:`${TEXT_MSGS.REGISTERATION_SUCCESS}`,severity:"success"}); 
        formikRef?.current.setSubmitting(false);
        formikRef?.current.resetForm();
        setShowConfirmPassword(false);
        setShowPassword(false);
        document.getElementById("termsChk").checked = false;
      }
      else if(error!==null){
        formikRef?.current.setSubmitting(false);
        if(error?.response?.data?.error_code===1006){
          // setAPIError(error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`);
          formikRef?.current.setErrors({email:"Email address already exist. Please provide different email address for signup."});
        }else{
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
          ,severity:"info"}); 
        }
      }
      reset();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate,from]);

  
 /**
 * API caller  
 */  
  const onSubmit = (values, props) => {
    setAPIError(undefined);
    executeAPI(URL.REGISTER,"POST",JSON.stringify(values));    
   }

 /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

  const [openModal,setOpenModal]=useState(false)
  const openTermModal=()=>{
    setOpenModal(true);
  }

  const closeModal=()=>{
    setOpenModal(false);
  }

  return (
        <MainLayoutContainer>

        {/* Header component */}
        {/* <Header showLogin={true} showIntro={true}/> */}
       
        {/*Welcome banner left component*/}
        {/* <WelcomeLayout/> */}

        {openModal?
            <TermsOfUseModal closeModal={closeModal}></TermsOfUseModal>
              : 
        <></>}

        {/* Form container for right layut */}
        <FormContainer>
          
       
        {/* <Box sx={{mt:{mobile:0,tablet:0}, width:{mobile:"95%", tablet:"85%", laptop:"75%"} }}> */}
        <Box sx={{ justifyContent:"center",alignItems:"center",width:'340px',backgroundColor:"#fff",mt:6,ml:2 }}>
        {/* <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',pb:6}}>

<img src={logo} alt="Gravitas Factor Logo" width={60} height={60}/>
     <Typography variant="titleMedium" sx={{textAlign:'center',fontSize:"24px"}} >Gravitas Factor</Typography>
</Box> */}
        <Typography  variant="titleMedium" sx={{fontSize:"20px"}}>
          Register with Gravitas Factor
        </Typography>
        <Formik innerRef={formikRef} autocomplete="off" initialValues={initialValues} onSubmit={onSubmit} validate={validateRegisterationForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form autocomplete="off">
                 
          
                  <Box display="flex">
                  <Grid container spacing={2} sx={{mt:1,mb:2}}>
                  <Grid item mobile={12} tablet={12}>
                  <Box display="flex" >
                     <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      required
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      id="entity_name"
                      name="entity_name"
                      type="text"
                      label="Entity Name"
                      // helperText="(Name of the Company)"
                    />
                     <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Name of the Company.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                    </Grid>
                    
                    </Grid>
              </Box>

              <Grid container spacing={2} mb={2}>
              <Grid item mobile={12} tablet={12}>
              <Box display="flex" >
                          <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      autoComplete="new-country-area"
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      id="email"
                      name="email"
                      type="text"
                      label="Email Address"
                      required
                      // helperText="(Registration Email Address)"
                    />
                     <Tooltip title={ <div style={{ whiteSpace: 'pre-line' }}>Registration Email Address.</div>} zIndex={99999} overlayStyle={{backgroundColor:"#173340"}} overlayInnerStyle={{backgroundColor:"#173340"}}>
                            <InfoCircleOutlined style={{fontSize:"20px",marginLeft:"6px", cursor: "pointer"}} />
                        </Tooltip>
                        </Box>
                  </Grid> 

                 
              </Grid>

              <Grid container spacing={2} mb={2}>
              <Grid item mobile={12} tablet={12}>
              <Box display="flex" sx={{mr:3}} >
               <Field
                 component={MuiTextFieldFormik}
                 fullWidth
                 variant="outlined" 
                 size="small" 
                 color="inputFieldColor"
                 name="mobile"
                 id="mobile"
                 type="text"
                 label="Phone Number"
                 required
               />
               </Box>
               </Grid>
               </Grid>

              <Grid item mobile={12} tablet={12} mb={2}>
              <Box display="flex" sx={{mr:3}} >
                <Field
                  component={MuiTextFieldFormik}
                  fullWidth
                  variant="outlined" 
                  size="small" 
                  color="inputFieldColor"
                  sx={{ pr:{tablet:0}}}
                  type={showPassword ? "text" : "password"} 
                  label="Password"
                  name="password"
                  required
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                </Box>
              </Grid>

              <Grid item mobile={12} tablet={12} mb={1}>
              <Box display="flex" sx={{mr:3}} >
              <Field
                component={MuiTextFieldFormik}
                fullWidth
                variant="outlined" 
                size="small" 
                color="inputFieldColor"
                sx={{ pr:{tablet:0}}}
                type={showConfirmPassword ? "text" : "password"} 
                label="Confirm Password"
                name="confirm_password"
                required
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              </Box>
            </Grid>

              <Grid item mobile={12} tablet={12} mb={0} alignItems="center">
                <Field as={FormControlLabel}
                    name='terms'
                    control={<Checkbox 
                      id="termsChk"
                      sx={{
                        color: theme.palette.primaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.primaryTheme.shade03,
                        },
                      }}/>
                    }
                    label={<Typography variant="linkSupportText" >
                        I agree to all
                        <Link href='https://www.gravitasfactor.com/termsandconditions' target="_blank" rel="noopener noreferrer" sx={{ml:1,cursor:"pointer"}} variant="link">Terms & Conditions</Link>
                        <Link href='https://www.gravitasfactor.com/privacypolicy' target="_blank" rel="noopener noreferrer" sx={{ml:1,cursor:"pointer"}} variant="link">Privacy Policy</Link>
                      </Typography>
                    }
                   />
              </Grid>
              
               {/* Error msg incase terms and conditions checkbox is not checked */}
              <ErrorMessage name="terms" render={msg =>  <Typography variant="warningText">{msg}</Typography> } /> 
              
              {apiError!=undefined?
                <Typography variant="errorText">{apiError}</Typography>:
                <></>
              }

              <Grid item mobile={12} tablet={12}> 
              <Box display="flex" sx={{mr:2}} >
                <Button type='submit'  sx={{mb:2,mr:1, mt:1,height:"50px"}} variant="contained" disabled={isSubmitting}
                      fullWidth>{isSubmitting ? "Loading" : "Sign me up !"}</Button>
                      </Box>
              </Grid>

          </Form>
          )}
        </Formik>
                
          <Grid item>
            <Typography variant="link">{"Already have an account? "} 
              <Link href="/login" variant="link" sx={{pb:6}}>
              Log in
              </Link>
            </Typography>
          </Grid>
          
        </Box>
      
      </FormContainer>
      
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
        <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
          {msg}
        </Alert>  
      </Snackbar>     
    
   </MainLayoutContainer>
  );
}