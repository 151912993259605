import React, { useState } from 'react'
import { Typography, Container,Link} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';
import { ToolbarFixed } from '../component.js';


const FooterToolbar=styled(ToolbarFixed)(
  ({theme})=> `
  bottom:0px;
  justify-content:center;
  text-align:center;
  `
)


function Copyright(props) {
  return (
   
    <Typography  variant="copyRight" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear() } Gravitas Factor. All Rights Reserved. 
      </Typography>
  );
}

/**
 * 
 * @returns fixed toolbar for copyright text
 */
function Footer(props) {
  const theme=useTheme();
 
  return (
    <>
      <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
        <Container >
          <Copyright/>
        </Container>
      </FooterToolbar>
     
    </>
  )
}

export default Footer