import * as React from 'react';
import { useState,useRef,useEffect } from 'react';

import { Col, Row, Timeline, Input, InputNumber } from 'antd';
import { Link } from 'react-router-dom';

import { Formik, Form, Field,ErrorMessage} from 'formik';
import {IconButton,Alert, Snackbar,Button,CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MuiTextFieldFormik } from '../../components/CustomTextField/TextField';
import { validateBankDetailsForm } from '../../common/utility/validators';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CheckCircleFilled } from '@ant-design/icons';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import ACTIVATION_LEFT_PANEL from './components/activation_left_panel';




const { Option } = Select;
const initialValues = {
    bank_name:'',
    bank_branch_name:'',
    account_name:'',
    ifsc_code:'',
    bank_account_type:'',
    bank_account_number:'',
    confirm_bank_account_number:''

  };
  
const Company_Details_Page = () => {

    const [entityType, setEntityType] = useState();
    const [isLoading,setIsLoading]=useState(true);
    // const [form] = Form.useForm();
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelSave,responseDataSave,errorSave,loadedSave,resetSave,executeAPISave] = useAxiosWithAuth();
    const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const formikRef = useRef();
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
   const { open, msg,severity } = msgState;
   const [bankDetails,setBankDetails]=useState(initialValues);
   const [statusData,setStatusData]=useState();
   
   useEffect(() => {
    document.title = "Bank Details";
     }, []);

    const onEntityChange = (event) => {
        setEntityType(event.target.value);
        formikRef.current.setFieldValue("bank_account_type",event.target.value);
    }


    useEffect(()=>{
        executeAPIStatus(URL.ACTIVATION_STATUS,"GET",null);
        executeAPI(URL.ACTIVATION_BANK_DETAILS,"GET",null);
        
    },[])

     /**
     * API response handler  status API 
    */  
     useEffect(() => {
        if(loadedStatus){
        console.log("activation status response",responseDataStatus);
        if(responseDataStatus!=null){
            setStatusData(responseDataStatus);
            // setIsLoading(false);
        }
        else if(errorStatus!==null){
            // setIsLoading(false);
            console.log("Error data=",errorStatus);
            
        
            //if unauthorized then redirec it to login page
            if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
                console.log("status received =",errorStatus?.response?.status)
                 navigate("/login", { replace: true });
            }
            setMsgState({open:true,msg:errorStatus?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
            ,severity:"error"});
        }
        resetStatus();
        }
        },[loadedStatus,responseDataStatus]);

    /**
     * API response handler  
    */  
useEffect(() => {
    if(loaded){
    console.log("loaded=true",responseData,responseData.bank_account_type);
    setIsLoading(false);
    if(responseData!=null && responseData?.bank_account_type){
        let data=responseData;
        data["confirm_bank_account_number"]=responseData.bank_account_number;
        setBankDetails(responseData);
        setEntityType(responseData.bank_account_type);

        
        
    }
    else if(error!==null){
        console.log("Error data=",error);
    
    
        //if unauthorized then redirec it to login page
        if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});

    }
    reset();
    }
    },[loaded,responseData]);

    /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
        setMsgState({...msgState,open:false});
    };
    
    /**
    * API caller  
    */  
  const onSubmit = (values, props) => {
    console.log("onSubmit=",JSON.stringify(values));
    // setAPIError(undefined);
    executeAPISave(URL.ACTIVATION_BANK_DETAILS,"POST",JSON.stringify(values)); 
   }

 

   /**
     * API response handler Save detailes
    */  
   useEffect(() => {
    if(loadedSave){
    console.log("Save data response",responseDataSave);
    if(responseDataSave!=null){
        formikRef?.current.setSubmitting(false);
        setMsgState({open:true,msg:"Bank details saved successfully.",severity:"success"});
        setTimeout(()=>{
            navigate("/activation/address", { replace: true });
        },2000);
       
        
    }
    else if(errorSave!==null){
        formikRef?.current.setSubmitting(false);
        console.log("Error data=",errorSave);
       
    
        //if unauthorized then redirec it to login page
        if(errorSave?.response?.status === 401 || errorSave?.response?.status === 403){
            console.log("status received =",errorSave?.response?.status)
            navigate("/login", { replace: true });
        }
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"error"});
    
    }
    resetSave();
    }
    },[loadedSave,responseDataSave]);

    const goBack=()=>{
        navigate("/activation/kyc", { replace: true });
    }
    const goNext=()=>{
        navigate("/activation/address", { replace: true });
    }
    return (
        <div style={{ width: '100vw',marginTop:"64px"  }} >
             {!isLoading?
            <>
            {/* <Navbar /> */}

            <Row>
            <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={5}>
                    <ACTIVATION_LEFT_PANEL statusData={statusData} index={2}></ACTIVATION_LEFT_PANEL>    
                </Col>
                
                <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={19}>

                    {/* content for column 2 goes here */}
                    <div style={{ height: `calc(100vh - 64px)`, display: 'flex', direction: 'verticle', alignItems: 'flex-start', justifyContent: 'center',backgroundColor:"#fff" }}>

                        {/* <div style={{ width: "calc(100% - 20px)",marginTop:"40px" }} >
                        <Typography  variant="titleMedium" sx={{fontSize:"24px"}}>
          Bank Details:
        </Typography> */}
        <div style={{ width: "calc(100% - 20px)",paddingLeft:"20px",paddingRight:"20px" }} >
                            <Box sx={{mt:6}}>
                                <Typography  variant="titleMedium" sx={{fontSize:"24px"}}>
                                Bank Details:
                                </Typography>
                            </Box>
                        <Formik enableReinitialize={true} innerRef={formikRef} initialValues={bankDetails} onSubmit={onSubmit} validate={validateBankDetailsForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form>
                 
          
                  <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:2,mb:3}}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        required
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="bank_name"
                        name="bank_name"
                        type="text"
                        label="Settlement Bank Name"
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                    </Grid>
                    
                    <Grid item mobile={12} tablet={6}>
                        <FormControl fullWidth size="small" required disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}>
                        <InputLabel sx={{backgroundColor: '#fff'}} id="demo-select-small-label">Account Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                required
                                value={entityType}
                                label="Account Type"
                                sx={{height:"40px",fontSize:"14px"}}
                                // onChange={onEntityChange}
                                onChange={(event) => onEntityChange(event)}
                                name="bank_account_type"
                            >
                                <MenuItem value={1}>Current</MenuItem>
                                <MenuItem value={2}>Saving</MenuItem>
                                {/* <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                        </FormControl>
                    
                    </Grid>
                    
                </Grid>
                </Box>

                <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="bank_branch_name"
                        id="bank_branch_name"
                        type="text"
                        label="Bank Branch"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        id="ifsc_code"
                        name="ifsc_code"
                        type="text"
                        label="IFSC Code"
                        // placeholder="dd/mm/YYYY"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                        />
                  </Grid> 
                   
              </Grid>
              </Box>

              <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="account_name"
                        id="account_name"
                        type="text"
                        label="Name as per Bank Account"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    
                   
              </Grid>
              </Box>

                
                <Box display="flex" >
                <Grid container spacing={2} mb={3}>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="bank_account_number"
                        id="bank_account_number"
                        type="text"
                        label="Bank Account Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                    <Grid item mobile={12} tablet={6}>
                        <Field
                        component={MuiTextFieldFormik}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="inputFieldColor"
                        name="confirm_bank_account_number"
                        id="confirm_bank_account_number"
                        type="text"
                        label="Confirm Bank Account Number"
                        required
                        disabled={statusData?.status && (statusData?.status==2|| statusData?.status==4)}
                    />
                    </Grid>
                   
              </Grid>
              </Box>


              <Grid item mobile={12} tablet={12} sx={{display:"flex",  justifyContent:'space-between',alignItems:"center"}}> 
              <Button onClick={()=>goBack()}  sx={{mb:2, mt:4,height:"50px",width:"160px",mr:2}} variant="contained" disabled={(statusData?.status && (statusData?.status==2 || statusData?.status==4))}
                      >{"BACK"}</Button>
                <Button type='submit'  sx={{mb:2,mr:0, mt:4,height:"50px",width:"160px"}} variant="contained" disabled={isSubmitting|| (statusData?.status && (statusData?.status==2|| statusData?.status==4))}
                      >{isSubmitting ? "Loading" : "SAVE & NEXT"}</Button>

              
                  
                
              </Grid>
              <Grid item mobile={12} tablet={12} sx={{display:"flex", justifyContent:'center',alignItems:"center"}}> 
              {statusData?.status && (statusData?.status==2|| statusData?.status==4)?
                 <Typography  variant="titleMedium" sx={{fontSize:"16px"}}>
                    Account verification is under review.
                    </Typography>:
                    <></>

                 }  
                 </Grid>

          </Form>
          )}
        </Formik>
                        
                        </div>

                    </div>
                </Col>
            </Row>
            <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
                <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"70%" }}}>
                {msg}
          </Alert>  
        </Snackbar> 
        </>:
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/></div>}
        </div>
    )
}

export default Company_Details_Page